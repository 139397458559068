import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import moment from 'moment';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { tzDateTime } from '@helpers/dates'
import { renderAddress } from '@helpers/functions';
// components
import Icon from '@components/Icon'
import AvatarList from '@components/AvatarList';
import CanView from '@components/perms/CanView'
import LabelsList from '@components/lists/LabelsList'
import BreadcrumbNav from '@components/BreadcrumbNav'
import { Label, Popup } from 'semantic-ui-react'
import Attachments from '@components/Attachments';
import GenerateDocuments from '@components/GenerateDocuments';
import ListView from '@components/ListView'
import VisaAndResidenceForm from './components/VisaAndResidenceForm';
import VisaAndResidencessFilters from './components/VisaAndResidencessFilters';
import QuickNotes from '../../sm/leads/components/QuickNotes';
import LabelsBulk from './components/LabelsBulk';
import AccommodationBulk from './components/AccommodationBulk';
import SubmissionBulk from './components/SubmissionBulk';
import PromiseBulk from './components/PromiseBulk';
import WorkplaceBulk from './components/WorkplaceBulk';
import RegistersBulk from './components/RegistersBulk';

const VisasAndResidences = () => {
    const { t } = useTranslation()
    const [attachmentsOpen, setAttachmentsOpen] = useState(0);
    const dateFormat = useSelector((state) => state.date_format);
    const countries = useSelector((state) => state.choices.countries);

    return (
        <CanView redirect permissions={['foreigners.c_view_all_foreigners_processes']}>
            <BreadcrumbNav items={[{ name: t('visas_and_stays'), icon: '', href: '' }]} mBottom="0rem" />

            <ListView
                as="table"
                allowSearch
                allowSelection
                //showStatistic
                endpoint={API.FOREIGNERS + "processes/"}
                actionsCellWidth="2"
                bulkActions={(selected, setData, setTotal, filters, queryParams, setSelected) => [
                    {
                        as: 'modal',
                        name: t('generate_documents'),
                        icon: 'document-text-outline',
                        modal: <GenerateDocuments source="foreigners.ForeignerProcess" selected={selected} />,
                    },
                    {
                        as: 'modal',
                        name: t('add_labels'),
                        icon: 'pricetag-outline',
                        modal: <LabelsBulk selected={selected} setSelected={setSelected} setData={setData} />,
                        permissions: ['foreigners.c_manage_all_foreigners_processes']
                    },
                    {
                        as: 'modal',
                        name: t('add_accommodation'),
                        icon: 'home-outline',
                        modal: <AccommodationBulk selected={selected} setSelected={setSelected} setData={setData} />,
                        permissions: ['foreigners.c_manage_all_foreigners_processes']
                    },
                    {
                        as: 'modal',
                        name: t('add_submission'),
                        icon: 'business-outline',
                        modal: <SubmissionBulk selected={selected} setSelected={setSelected} setData={setData} />,
                        permissions: ['foreigners.c_manage_all_foreigners_processes']
                    },
                    {
                        as: 'modal',
                        name: t('add_employment_details'),
                        modalSize: 'small',
                        icon: 'briefcase-outline',
                        modal: <PromiseBulk selected={selected} setSelected={setSelected} setData={setData} />,
                        permissions: ['foreigners.c_manage_all_foreigners_processes']
                    },
                    {
                        as: 'modal',
                        name: t('add_workplace'),
                        modalSize: 'small',
                        icon: 'briefcase-outline',
                        modal: <WorkplaceBulk selected={selected} setSelected={setSelected} setData={setData} />,
                        permissions: ['foreigners.c_manage_all_foreigners_processes']
                    },
                    {
                        as: 'modal',
                        name: t('add_register'),
                        icon: 'document-outline',
                        modalSize: 'small',
                        modal: <RegistersBulk selected={selected} setSelected={setSelected} setData={setData} />,
                        permissions: ['foreigners.c_manage_all_foreigners_processes']
                    }
                ]}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        name: t('add_foreigner_process'),
                        modal: <VisaAndResidenceForm />,
                        permissions: ['foreigners.c_manage_all_foreigners_processes']
                    },
                    {
                        name: t('edit'),
                        type: 'edit',
                        as: 'modal',
                        icon: 'pencil-outline',
                        customIconColor: 'var(--dark)',
                        modal: (record, setData, setTotal) => (
                            <VisaAndResidenceForm singleItem={record} setData={setData} setTotal={setTotal} />
                        ),
                        permissions: ['foreigners.c_manage_all_foreigners_processes']
                    },
                    {
                        name: t('delete'),
                        type: 'delete',
                        as: 'delete',
                        text: t('are_you_sure_that_you_want_to_delete_this_process'),
                        permissions: ['foreigners.c_manage_all_foreigners_processes']
                    },
                ]}
                tableHeaders={[
                    { title: t('fullname'), orderBy: 'fullname' },
                    { title: t('citizenship') },
                    { title: t('purpose_of_stay') },
                    { title: t('type_of_residence') },
                    { title: t('accommodation') },
                    { title: t('employment_details') },
                    { title: t('registers') },
                    { title: t('process_valid_from_to') },
                    { title: t('labels') },
                    { title: t('responsible_person') },
                    { title: t('notes') },
                    { title: t('attachments') },
                    { title: t('created') },
                ]}
                initialFilters={{
                    accommodation: '',
                    date_of_submission: '',
                    hiring_room: '',
                    is_approved: '',
                    profile: '',
                    purpose_of_stay: '',
                    register_of_penalties: '',
                    short_position: '',
                    significant_investor: '',
                    type_of_residence: '',
                    valid_from_after: '',
                    valid_to_before: '',
                    citizenship: '',
                    have_valid_pass: '',
                    labels: [],
                    ordering: ['-created_on']
                }}
                renderFilterFields={(filters, setFilters) => <VisaAndResidencessFilters filters={filters} setFilters={setFilters} />}
                settings={[
                    {
                        permissions: ['foreigners.c_manage_all_foreigners_processes'],
                        as: 'link',
                        // target: "_blank",
                        linkTo: routes.SETTINGS + 'types/purposes-of-stay',
                        name: t('purpose_of_stay_management'),
                        icon: 'briefcase-outline',
                    },
                    {
                        permissions: ['foreigners.c_manage_all_foreigners_processes'],
                        as: 'link',
                        // target: "_blank",
                        linkTo: routes.SETTINGS + 'types/types-of-residence',
                        name: t('type_of_residence_management'),
                        icon: 'home-outline',
                    },
                    {
                        permissions: ['foreigners.c_manage_all_foreigners_processes'],
                        as: 'link',
                        // target: "_blank",
                        linkTo: routes.SETTINGS + 'submission-offices',
                        name: t('submission_offices_management'),
                        icon: 'business-outline',
                    },
                ]}
                renderCells={(item, setData) => [
                    {
                        content: (
                            <Link to={routes.EMPLYOEES_DETAIL + item?.profile?.id} target="_blank">
                                <strong>{item?.profile?.fullname_with_titles}</strong>
                            </Link>
                        ),
                    },
                    { content: item?.profile?.citizenship_display },
                    {
                        content: (
                            <>
                                <strong>{item?.purpose_of_stay?.title}</strong>
                                <br />
                                {item?.purpose_of_stay?.country}
                            </>
                        ),
                    },
                    { content: item?.type_of_residence?.title },
                    { content: 
                        <div>
                            <b>{item?.accommodation_valid_from ? moment(item?.accommodation_valid_from).format(dateFormat) : "--"}</b><span> / </span><b>{item?.accommodation_valid_to ? moment(item?.accommodation_valid_to).format(dateFormat) : "--"}</b>
                            <br/>
                            {item?.accommodation?.name || ''}
                            <br />
                            {renderAddress(item?.accommodation?.address, true)}
                        </div>
                    },
                    { content: 
                        <>
                            { item?.employer && 
                                <div>
                                    {t("employer")}: <strong>{item?.employer?.name}</strong>
                                </div>
                            }
                            { item?.user_employer && 
                                <div>
                                    {t("user_employer_short")}: <strong>{item?.user_employer?.name}</strong>
                                </div>
                            }
                            { item?.position && 
                                <div>
                                    {t("position")}: <strong>{item?.position?.title}</strong>
                                </div>
                            }
                            { item?.id_vpm && 
                                <div>
                                    {t("id_vpm")}: <strong>{item?.id_vpm}</strong>
                                </div>
                            }
                        </>  
                    },
                    { content: 
                        <>
                            {item?.register_of_penalties && <div>
                                <b>{item.register_of_penalties_valid_from ? moment(item.register_of_penalties_valid_from).format(dateFormat) : "--"}</b><span> / </span><b>{item?.register_of_penalties_valid_to ? moment(item?.register_of_penalties_valid_to).format(dateFormat) : "--"}</b>
                                <br/>
                                {countries?.['SK']}
                            </div>}
                            {item?.other_country_register?.length > 0 && item?.other_country_register?.map((register, idx) => (
                                <div>
                                    <b>{register.valid_from ? moment(register.valid_from).format(dateFormat) : "--"}</b><span> / </span><b>{register?.valid_to ? moment(register?.valid_to).format(dateFormat) : "--"}</b>
                                    <br/>
                                    {countries?.[register.country]}
                                </div>
                            )) }
                        </>
                    },
                    
                    { content: (item?.valid_from ? moment(item?.valid_from).format(dateFormat) : "--") + " / " + (item?.valid_to ? moment(item?.valid_to).format(dateFormat) : "--")},
                    { content: (
                        <LabelsList
                            key={item.id}
                            rowID={item.id}
                            labels={item.labels}
                            endpoint={API.FOREIGNERS + "processes/"}
                            endpointLabels={API.FOREIGNERS_LABELS}
                            label_key="labels"
                            managePerms={[
                                'foreigners.c_manage_all_foreigners_processes',
                            ]}
                        />
                    )},
                    { content: item?.responsible_person && 
                        <AvatarList
                            size="25"
                            alignDirection="start"
                            persons={[{
                                    id: item?.responsible_person.id,
                                    name: item?.responsible_person.fullname,
                                    avatar: item?.responsible_person.profile_picture,
                                    displayName: item?.responsible_person.fullname_with_titles || item.responsible_person.fullname,
                            }]}
                        /> 
                    },
                    { content: <QuickNotes lead={item} setData={setData} endpointData={API.FOREIGNERS + "processes/"} endpointNotes={API.FOREIGNERS_NOTES} /> },
                    { content: 
                        <Label basic>
                            <Popup
                                basic
                                style={{ zIndex: '1000', minWidth: '450px' }}
                                open={attachmentsOpen === item.id}
                                onOpen={() => setAttachmentsOpen(item.id)}
                                hoverable
                                position="left center"
                                trigger={
                                    <div style={{ cursor: 'pointer' }}>
                                        <Icon name="reader-outline" style={{ marginRight: '0.5rem' }} />
                                        <span style={{ position: 'relative', top: '-0.1rem' }}>
                                            {item?.files?.length}
                                        </span>
                                    </div>
                                }
                                content={
                                    <Attachments
                                        // viewOnly={!canManage}
                                        attribute="files"
                                        allowFolders
                                        record={item}
                                        moduleName={'foreigners'}
                                        setData={setData}
                                        sourceEndpoint={API.FOREIGNERS + "processes/"}
                                        closeView={() => setAttachmentsOpen(0)}
                                    />
                                }
                            />
                        </Label>
                    },
                    {
                        content: (
                            <div>
                                {tzDateTime(item.created_on).format(dateFormat + ' HH:mm')} <br />
                                <span>
                                    {t('created_by')} <strong>{item.created_by?.name || '--'}</strong>
                                </span>
                            </div>
                        ),
                    },
                ]}
            />
        </CanView>
    )
}

export default VisasAndResidences