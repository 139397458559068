import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useHistory } from 'react-router-dom'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { getRequest } from '@services/ServiceCommon'
import { useFetchData, useQueryPage } from '@helpers/hooks'
import { fileDownload } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import Paginator from '@components/Paginator'
import CanView from '@components/perms/CanView'
import EmptyRow from '@components/tables/EmptyRow'
import SuperField from '@components/forms/SuperField'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Container, Divider, Form, Button, Grid, Segment, Popup } from 'semantic-ui-react'
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable'
// module specific components
import CardForm from '../components/cards/forms/CardForm'

const AssignedItemsView = ({ employees }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()
    let page = useQueryPage()

    const [limit, setLimit] = useState(10)
    const [result, setResult] = useFetchData(
        API.ASSET_ISSUED_ITEMS_OVERVIEW +
            '?paginate=true&exclude_profiles_without_stored_items=true&page=' +
            page +
            '&limit=' +
            limit
    )
    const [filters, setFilters] = useState({ employee: '' })
    const [filtering, setFiltering] = useState(false)

    async function onFilter(params, isFiltered) {
        setResult((prev) => ({
            ...prev,
            isLoading: true,
        }))
        setFiltering(true)

        if (isFiltered) {
            page = 1
        } else {
            let searchParams = new URLSearchParams(location.search)
            const param = searchParams.get('page')
            page = param !== null ? param : 1
        }

        if (!params.includes('page')) {
            params += '&page=' + page
        }

        if (!params.includes('limit')) {
            params += '&limit=' + limit
        }

        if (filters.employee !== '') params += '&id=' + filters.employee

        const request = await getRequest(
            API.ASSET_ISSUED_ITEMS_OVERVIEW + '?paginate=true&exclude_profiles_without_stored_items=true' + params
        )
        if (request.status === 200) {
            setResult({
                isLoading: false,
                data: request.response,
            })
        }
        setFiltering(false)
        setResult((prev) => ({ ...prev, isLoading: false }))
    }

    const showAssignedItems = async (id) => {
        await fileDownload(
            'GET',
            API.API_URL + `/exports/not_returned_items/employee/${id}/?is_blob=true`,
            'application/pdf',
            `assigned_items_${id}.pdf`
        )
    }

    const FlexRowWithToggle = ({ rowContent, content, loading, background }) => {
        const [open, setOpen] = useState(false)

        return (
            <Segment style={{ padding: 0, marginBottom: '0rem', background: background || 'var(--white)' }}>
                <FlexRow background="transparent" borders>
                    <FlexItem textAlign="center" basis="5%">
                        <Icon
                            style={{ cursor: 'pointer' }}
                            name={open ? 'chevron-up-outline' : 'chevron-down-outline'}
                            onClick={() => setOpen(!open)}
                        />
                    </FlexItem>
                    {rowContent}
                </FlexRow>
                {open && (
                    <Segment loading={loading} style={{ padding: 0, marginTop: 0, border: 'none' }}>
                        {content}
                    </Segment>
                )}
            </Segment>
        )
    }

    return (
        <CanView permissions={['assets.c_view_all_issued_items', 'assets.c_view_user_issued_items']} redirect>
            <Container fluid>
                <Grid>
                    <Grid.Row style={{ padding: 0 }}>
                        <Grid.Column computer={4} tables={8} mobile={16}>
                            <Form
                                onSubmit={async () => {
                                    history.replace({ pathname: location.pathname, search: `?page=${1}` })
                                    await onFilter('', true)
                                }}
                            >
                                <Form.Group widths="equal">
                                    <SuperField
                                        as="choice"
                                        search
                                        size="small"
                                        label={t('employee')}
                                        customOptions={employees}
                                        value={filters.employee}
                                        onChange={(e, { value }) => setFilters({ ...filters, employee: value })}
                                    />
                                    <Form.Field width={8} style={{ marginTop: '1.5rem' }}>
                                        <Button
                                            fluid
                                            loading={filtering}
                                            disabled={filtering}
                                            primary
                                            content={t('confirm')}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider />

                <Segment
                    loading={result.isLoading}
                    style={{
                        boxShadow: 'none',
                        border: 'none',
                        padding: 0,
                        background: 'transparent',
                    }}
                >
                    {result.isLoading && (
                        <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '6rem' }}>
                            {' '}
                            {t('message_loading_assigned_items')}{' '}
                        </p>
                    )}
                    {!result.isLoading && (
                        <>
                            <FlexTable hoverable={false} stripped={false}>
                                <FlexRow>
                                    <FlexHeader basis="5%" content="" />
                                    <FlexHeader basis="80%" content={t('employee')} />
                                    <FlexHeader basis="40%" content={t('total_items')} />
                                    <FlexHeader textAlign="right" basis="40%" content={t('actions')} />
                                </FlexRow>
                                <EmptyRow length={result?.data?.count || 0} />

                                {result.data.results.map((employee) => (
                                    <FlexRowWithToggle
                                        background={'var(--white)'}
                                        key={employee.id}
                                        loading={false}
                                        rowContent={
                                            <>
                                                <FlexItem basis="80%" bold>
                                                    <Link to={routes.EMPLYOEES_DETAIL + employee.id}>
                                                        {employee.fullname}
                                                    </Link>
                                                </FlexItem>
                                                <FlexItem basis="40%">
                                                    {
                                                        employee.stored_items.filter(
                                                            (item) => item.current_quantity > 0
                                                        ).length
                                                    }
                                                </FlexItem>
                                                <CanView permissions={['assets.c_view_all_issued_items']}>
                                                    <FlexItem basis="40%" textAlign="right">
                                                        <Popup
                                                            position="top center"
                                                            trigger={
                                                                <Icon
                                                                    name="download-outline"
                                                                    style={{
                                                                        color: 'var(--dark)',
                                                                        marginLeft: '1rem',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() => showAssignedItems(employee.id)}
                                                                />
                                                            }
                                                            content={t('download')}
                                                        />
                                                    </FlexItem>
                                                </CanView>
                                            </>
                                        }
                                        content={
                                            <Container fluid style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                                                <FlexTable>
                                                    <FlexRow borders>
                                                        <FlexHeader basis="40%" content={t('code')} />
                                                        <FlexHeader content={t('item')} />
                                                        <FlexHeader content={t('serial_number')} />
                                                        <FlexHeader content={t('quantity')} />
                                                        <FlexHeader content={t('category')} />
                                                        <FlexHeader content={t('note')} />
                                                        <CanView permissions={['assets.c_view_all_issued_items']}>
                                                            <FlexHeader textAlign="right" content={t('actions')} />
                                                        </CanView>
                                                    </FlexRow>
                                                    <EmptyRow length={employee.stored_items.length} />
                                                    <>
                                                        {employee.stored_items
                                                            .sort((a, b) => (a.code > b.code ? 1 : -1))
                                                            .map((storedItem) => (
                                                                <>
                                                                    {storedItem.current_quantity > 0 && (
                                                                        <FlexRow
                                                                            fontSize="0.9rem"
                                                                            background="transparent"
                                                                            key={storedItem.id}
                                                                            borders
                                                                        >
                                                                            <FlexItem basis="40%">
                                                                                {storedItem?.code || ''}
                                                                            </FlexItem>
                                                                            <FlexItem>
                                                                                <strong>
                                                                                    {storedItem?.item?.title}
                                                                                </strong>{' '}
                                                                                <br />
                                                                            </FlexItem>
                                                                            <FlexItem>{storedItem?.sn || ''}</FlexItem>
                                                                            <FlexItem>
                                                                                {storedItem.current_quantity}
                                                                            </FlexItem>
                                                                            <FlexItem>
                                                                                {storedItem.item?.category?.title ||
                                                                                    '--'}
                                                                            </FlexItem>
                                                                            <FlexItem>
                                                                                {storedItem?.note || ''}
                                                                            </FlexItem>
                                                                            <FlexItem>
                                                                                <CanView
                                                                                    permissions={[
                                                                                        'assets.c_issue_items',
                                                                                    ]}
                                                                                >
                                                                                    <FlexRow
                                                                                        padding="0"
                                                                                        background="transparent"
                                                                                    >
                                                                                        <FlexItem textAlign="right">
                                                                                            <SuperDuperModal
                                                                                                size="large"
                                                                                                header={
                                                                                                    t('return_card') +
                                                                                                    ': ' +
                                                                                                    employee.fullname
                                                                                                }
                                                                                                trigger={
                                                                                                    <Icon
                                                                                                        name="arrow-undo-circle-outline"
                                                                                                        style={{
                                                                                                            color: 'var(--dark)',
                                                                                                            cursor: 'pointer',
                                                                                                        }}
                                                                                                    />
                                                                                                }
                                                                                                content={
                                                                                                    <CardForm
                                                                                                        type={2}
                                                                                                        employees={
                                                                                                            employees
                                                                                                        }
                                                                                                        employee={
                                                                                                            employee.id
                                                                                                        }
                                                                                                        items={[
                                                                                                            {
                                                                                                                id: 0,
                                                                                                                quantity:
                                                                                                                    storedItem.current_quantity,
                                                                                                                valid_until:
                                                                                                                    '',
                                                                                                                note: '',
                                                                                                                isProcessing: false,
                                                                                                                storedItem:
                                                                                                                    {
                                                                                                                        id: storedItem.id,
                                                                                                                        code: storedItem.code,
                                                                                                                        sn: storedItem.sn,
                                                                                                                        name: storedItem
                                                                                                                            ?.item
                                                                                                                            ?.title,
                                                                                                                        stock: storedItem.current_quantity,
                                                                                                                    },
                                                                                                            },
                                                                                                        ]}
                                                                                                    />
                                                                                                }
                                                                                            />
                                                                                        </FlexItem>
                                                                                    </FlexRow>
                                                                                </CanView>
                                                                            </FlexItem>
                                                                        </FlexRow>
                                                                    )}
                                                                </>
                                                            ))}
                                                    </>
                                                </FlexTable>
                                            </Container>
                                        }
                                    />
                                ))}
                            </FlexTable>
                            <Paginator
                                forcePage={page || 1}
                                limit={limit}
                                setLimit={setLimit}
                                length={result?.data?.count || 0}
                                onChange={(params) => onFilter(params)}
                            />
                        </>
                    )}
                </Segment>
            </Container>
        </CanView>
    )
}

export default AssignedItemsView
