import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
// store
import { requests } from '@helpers/requests'
import { tzDateTime } from '@helpers/dates'
import { routes } from '@routes'
import { API } from '@store/config'
// components
import Icon from '@components/Icon'
import { Button, Grid, Divider, Label, Form, Header, Message } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import LabelsForm from '@components/forms/LabelsForm'
import AvatarIcon from '@components/AvatarIcon'
import CustomFormDivider from '@components/general/CustomFormDivider'
import FilesTab from '@components/documents/FilesTab'
import {
    BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    // BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnRedo,
    // BtnStrikeThrough,
    BtnUnderline,
    BtnUndo,
    HtmlButton,
    Separator,
    Toolbar,
    EditorProvider,
    Editor,
} from 'react-simple-wysiwyg'

import AssignMemberToIssue from '../forms/AssignMemberToIssue'

const IssueDetail = ({ issue, setIssues, project }) => {
    const dateFormat = useSelector((state) => state.date_format)

    const { t } = useTranslation()
    const [errors, setErrors] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)

    const [readOnly, setReadOnly] = useState(true)

    const [form, setForm] = useState({
        name: issue?.name || null,
        description: issue?.description || null,
        milestone: issue?.milestone?.id || null,
        due_date: issue?.due_date || null,
        state: issue?.state || null,
        estimated_time: issue?.estimated_time || null,
        time_spent: issue?.time_spent || null,
        // group: issue?.group || null,
        // stage: issue?.stage || null,
        milestone_name: issue?.milestone?.name || null,
    })

    const handleSubmit = async () => {
        setErrors(null)
        setIsProcessing(true)

        const result = await requests.patch(API.PROJECT_ISSUES + issue?.id + '/', form)
        if (result.status === 200) {
            setIssues((prev) =>
                prev.map((item) => {
                    if (item.id === issue.id) {
                        item = result?.response
                    }
                    return item
                })
            )

            setReadOnly(!readOnly)
        } else if (result.status === 400) {
            setErrors(result.response)
        }
        setIsProcessing(false)
    }

    const onAssigneeAdd = (id, assigned) => {
        setIssues((prev) =>
            prev.map((item) => {
                if (item.id === id) {
                    item.assigned = assigned
                }
                return item
            })
        )
    }
    const onAssigneeRemove = async (id, assignedID) => {
        const request = await requests.patch(API.PROJECT_ISSUES + id + '/?query={id, assigned}', {
            assigned: {
                remove: [assignedID],
            },
        })

        if (request.status === 200) {
            setIssues((prev) =>
                prev.map((item) => {
                    if (issue?.id === id) {
                        issue.assigned = request.response.assigned
                    }
                    return item
                })
            )
        }
    }
    const onLabelsAdd = async (id, labels) => {
        const labelsData = labels.map((item) => item.id)
        const request = await requests.patch(API.PROJECTS + project + '/?query={id, labels}', {
            labels: {
                remove: labels.map((item) => item.id),
                add: labelsData,
            },
        })
        if (request.status === 200) {
            setIssues((prev) =>
                prev.map((item) => {
                    if (item?.id === id) {
                        item.labels = labels
                    }
                    return item
                })
            )
        }
    }
    const onLabelRemove = async (id, labelID) => {
        const request = await requests.patch(API.PROJECT_ISSUES + id + '/?query={id, labels}', {
            labels: {
                remove: [labelID],
            },
        })

        if (request.status === 200) {
            setIssues((prev) =>
                prev.map((item) => {
                    if (item?.id === id) {
                        item.labels = request.response.labels
                    }
                    return item
                })
            )
        }
    }
    const PutAvatar = ({ user }) => {
        const [iconDisplay, setIconDisplay] = useState(false)

        return (
            <Grid style={{ padding: 0 }}>
                <Grid.Row
                    style={{ padding: 0 }}
                    onMouseEnter={() => setIconDisplay(true)}
                    onMouseLeave={() => setIconDisplay(false)}
                >
                    <Grid.Column style={{ padding: 0 }} computer={4} table={4} mobile={6}>
                        {iconDisplay === false ? (
                            <AvatarIcon size={34} name={user?.fullname} src={user?.profile_picture} />
                        ) : (
                            <Button
                                circular
                                size="small"
                                icon={<Icon name="close-outline" style={{ color: 'var(--danger)' }} />}
                                style={{ marginLeft: '4px' }}
                                onClick={() => onAssigneeRemove(issue.id, user.id)}
                            />
                        )}
                    </Grid.Column>
                    <Grid.Column style={{ padding: 0 }} computer={12} table={12} mobile={10}>
                        <span style={{ display: 'flex', flexDirection: 'column', paddingTop: '0.5rem' }}>
                            {user?.id ? (
                                <Link to={routes.EMPLYOEES_DETAIL + user?.id} target="_blank">
                                    <strong>{user?.fullname}</strong>
                                </Link>
                            ) : (
                                <strong>{user?.fullname}</strong>
                            )}
                        </span>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

    return (
        <>
            <Form onSubmit={(e) => e.preventDefault()}>
                <Message
                    error
                    visible={errors?.non_field_errors?.length > 0}
                    header={t('error_submission_message')}
                    list={errors?.non_field_errors}
                />
                <Grid>
                    <Grid.Row>
                        <Grid.Column mobile={9} tablet={9} computer={9}>
                            <span>
                                <Icon
                                    name="ellipse"
                                    style={{
                                        color: issue.state === 1 ? 'var(--success)' : 'var(--danger)',
                                        marginRight: '0.5rem',
                                    }}
                                />
                                <strong style={{ position: 'relative', top: '-0.1rem' }}>
                                    {' '}
                                    {issue.state === 1 ? t('open') : t('closed')}{' '}
                                </strong>
                                {readOnly ? (
                                    <h2 style={{ marginTop: '0px' }}>
                                        {' '}
                                        #{issue.number} {form.name}
                                    </h2>
                                ) : (
                                    <SuperField
                                        as="input"
                                        style={{ display: 'flex', marginBottom: '0.5rem', marginTop: '0.5rem' }}
                                        value={form.name}
                                        readOnly={readOnly}
                                        error={errors === null ? false : errors?.name}
                                        onChange={(e, { value }) => setForm({ ...form, name: value })}
                                    />
                                )}
                            </span>
                            <p>
                                {t('created_by')}
                                <Link to={routes.EMPLYOEES_DETAIL + issue?.created_by?.profile_id} target="_blank">
                                    <strong> {issue.created_by?.name}</strong>
                                </Link>{' '}
                                {t('at')} <strong>{tzDateTime(issue.created_on).format(dateFormat + ' HH:mm')}</strong>
                            </p>
                        </Grid.Column>
                        <Grid.Column mobile={5} tablet={5} computer={5}>
                            {readOnly ? (
                                <>
                                    <div style={{ margin: '3px', padding: '3px', marginTop: '1rem' }}>
                                        {t('due_date')}: <br />
                                        <strong style={{ fontSize: '1.2rem' }}>
                                            {form.due_date ? moment(form.due_date).format(dateFormat) : '--'}
                                        </strong>
                                    </div>
                                </>
                            ) : (
                                <Form.Group widths="equal">
                                    <SuperField
                                        as="datepicker"
                                        label={t('due_date')}
                                        value={form.due_date}
                                        readOnly={readOnly}
                                        error={errors === null ? false : errors?.due_date}
                                        onChange={(e, { value }) => {
                                            value === '' || value === null
                                                ? setForm({ ...form, due_date: null })
                                                : setForm({ ...form, due_date: value })
                                        }}
                                    />
                                </Form.Group>
                            )}
                        </Grid.Column>
                        <Grid.Column mobile={2} tablet={2} computer={2}>
                            <div style={{ marginTop: '1.8rem' }}>
                                {readOnly ? (
                                    <Button
                                        secondary
                                        size="small"
                                        content={t('edit')}
                                        disabled={isProcessing}
                                        onClick={() => setReadOnly(!readOnly)}
                                    />
                                ) : (
                                    <Button
                                        primary
                                        size="small"
                                        content={t('save')}
                                        disabled={isProcessing}
                                        loading={isProcessing}
                                        onClick={() => handleSubmit()}
                                    />
                                )}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Divider />
                    <Grid.Row>
                        <Grid.Column mobile={13} tablet={13} computer={13}>
                            <Grid.Row></Grid.Row>
                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={16} computer={16}>
                                    {readOnly ? (
                                        <>
                                            <label style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
                                                {t('description')}
                                            </label>
                                            <p
                                                style={{
                                                    padding: '0.5rem',
                                                    marginTop: '0.5rem',
                                                    border: '1px solid var(--light)',
                                                    minHeight: '250px',
                                                }}
                                                dangerouslySetInnerHTML={{ __html: form?.description || '' }}
                                            ></p>
                                        </>
                                    ) : (
                                        <Form.Field>
                                            <label style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
                                                {t('description')}
                                            </label>
                                            <EditorProvider>
                                                <Editor
                                                    containerProps={{
                                                        style: {
                                                            resize: 'vertical',
                                                            width: '100%',
                                                            background: 'white',
                                                            minHeight: '250px',
                                                        },
                                                    }}
                                                    value={form.description}
                                                    onChange={(e) =>
                                                        setForm((prev) => ({ ...prev, description: e.target.value }))
                                                    }
                                                >
                                                    <Toolbar>
                                                        <BtnUndo />
                                                        <BtnRedo />
                                                        <Separator />
                                                        <BtnBold />
                                                        {/* <BtnItalic /> */}
                                                        <BtnUnderline />
                                                        {/* <BtnStrikeThrough /> */}
                                                        <Separator />
                                                        <BtnNumberedList />
                                                        <BtnBulletList />
                                                        <Separator />
                                                        <BtnLink />
                                                        <Separator />
                                                        <BtnClearFormatting />
                                                        <HtmlButton />
                                                    </Toolbar>
                                                </Editor>
                                            </EditorProvider>
                                        </Form.Field>
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={16} computer={16}>
                                    <CustomFormDivider
                                        header={t('analytic_information')}
                                        divider={true}
                                        content={
                                            <>
                                                <Grid.Row>
                                                    <Form.Group widths="3">
                                                        <SuperField
                                                            as="input"
                                                            label={
                                                                <h4>
                                                                    {t('estimated_time')} (
                                                                    {t('hours').toLocaleLowerCase()})
                                                                </h4>
                                                            }
                                                            value={form.estimated_time}
                                                            error={errors === null ? false : errors?.estimated_time}
                                                            readOnly={readOnly}
                                                            onChange={(e, { value }) => {
                                                                value === '' || value === null
                                                                    ? setForm({ ...form, estimated_time: null })
                                                                    : setForm({ ...form, estimated_time: value })
                                                            }}
                                                        />
                                                        <SuperField
                                                            as="input"
                                                            label={
                                                                <h4>
                                                                    {t('time_spent')} ({t('hours').toLocaleLowerCase()})
                                                                </h4>
                                                            }
                                                            value={form.time_spent}
                                                            readOnly={readOnly}
                                                            error={errors === null ? false : errors?.time_spent}
                                                            onChange={(e, { value }) => {
                                                                value === '' || value === null
                                                                    ? setForm({ ...form, time_spent: null })
                                                                    : setForm({ ...form, time_spent: value })
                                                            }}
                                                        />

                                                        <SuperField
                                                            as="choice"
                                                            search={!readOnly}
                                                            disabled={readOnly}
                                                            closeable={!readOnly}
                                                            label={
                                                                <h4 style={{ marginBottom: '1rem' }}>
                                                                    {t('milestone')}
                                                                </h4>
                                                            }
                                                            text="name"
                                                            className={readOnly && 'milestone-choice'}
                                                            value={form?.milestone}
                                                            onChange={(e, { value }) => {
                                                                setForm({ ...form, milestone: value })
                                                            }}
                                                            error={errors === null ? false : errors?.milestone}
                                                            endpoint={
                                                                API.PROJECT_MILESTONES +
                                                                `?project=${issue?.project?.id}`
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Grid.Row>
                                            </>
                                        }
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={16} computer={16}>
                                    <FilesTab
                                        id={issue.id}
                                        module={'project'}
                                        updateEndpoint={API.PROJECT_ISSUES}
                                        getEndpoint={API.PROJECT_ISSUES}
                                        hideCreate={false}
                                        canManage={true}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column mobile={3} tablet={3} computer={3}>
                            <CustomFormDivider
                                header={
                                    <SuperDuperModal
                                        trigger={
                                            <Header as="h3">
                                                <Header.Content>
                                                    <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                        {t('assigned_users')}
                                                    </span>
                                                    <Icon name="add-outline" style={{ marginLeft: '0.5rem' }} />
                                                </Header.Content>
                                            </Header>
                                        }
                                        content={
                                            <AssignMemberToIssue
                                                id={issue?.id}
                                                data={issue?.assigned}
                                                projectID={issue?.project?.id}
                                                onConfirm={onAssigneeAdd}
                                                fieldName={'assigned'}
                                                patchEndpoint={API.PROJECT_ISSUES}
                                            />
                                        }
                                    />
                                }
                                content={
                                    <>
                                        {issue?.assigned?.map((assigned, index) => (
                                            <>
                                                <PutAvatar user={assigned} />
                                            </>
                                        ))}
                                    </>
                                }
                            />
                            <CustomFormDivider
                                header={
                                    <SuperDuperModal
                                        trigger={
                                            <Header as="h3">
                                                <Header.Content>
                                                    <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                        {t('labels')}
                                                    </span>
                                                    <Icon name="add-outline" style={{ marginLeft: '0.5rem' }} />
                                                </Header.Content>
                                            </Header>
                                        }
                                        content={
                                            <LabelsForm
                                                id={issue?.id}
                                                data={issue?.labels}
                                                onConfirm={onLabelsAdd}
                                                patchEndpoint={API.PROJECT_ISSUES}
                                                labelEndpoint={API.PROJECT_LABELS + `?project=${issue?.project?.id}`}
                                                fieldName={'labels'}
                                            />
                                        }
                                    />
                                }
                                content={
                                    <>
                                        {issue?.labels?.map((label, index) => (
                                            <>
                                                <Label style={{ margin: '3px' }} key={label.id}>
                                                    <Icon
                                                        name="pricetag-outline"
                                                        style={{
                                                            marginRight: '0.5rem',
                                                            color: label.color === '' ? '' : label.color,
                                                        }}
                                                    />
                                                    <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                        {label.name}
                                                    </span>
                                                    <Icon
                                                        name="close-outline"
                                                        style={{ marginLeft: '0.5rem', color: 'var(--danger)' }}
                                                        onClick={() => onLabelRemove(issue.id, label.id)}
                                                    />
                                                </Label>
                                            </>
                                        ))}
                                    </>
                                }
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </>
    )
}

export default IssueDetail
