import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// store
import { getRequest } from '@services/ServiceCommon';
import { API } from '@store/config';
// components
import { Icon, List, Popup } from 'semantic-ui-react';
import { CopyToClipboard } from "react-copy-to-clipboard"

const Placeholders = ({ model }) => {
    const { t } = useTranslation();
    const [nestedLevels, setNestedLevels] = useState([]);
    const [referenceFrom, setReferenceFrom] = useState("");
    const [optionsToDisplay, setOptionsToDisplay] = useState([]);
    // eslint-disable-next-line
    const [isCoppied, setIsCoppied] = useState(false)

    const addPlaceholder = () => {
        //setOpen(!open)
        setIsCoppied(true)
    };


    useEffect(() => {
        // fetch main categories
        async function fetchCategories() {
            const parsedModel = model.split(".")
            fetchFields(model, true, parsedModel[1])
        }

        fetchCategories()
        //eslint-disable-next-line
    }, []);

    async function fetchFields(app_model, append, addToReferences) {
        append = append || false
        if (addToReferences) {
            setReferenceFrom(referenceFrom + addToReferences + ".")
        }
        const request = await getRequest(API.API_URL + "/templating/fields/" + app_model + "/?include_ids=false")

        if (request.status === 200) {
            setOptionsToDisplay(request.response[0])
            if (append) {
                setNestedLevels([...nestedLevels, app_model])
            }
        }
    }

    async function stepBack() {
        let nestedArray = nestedLevels
        nestedArray.pop()
        setNestedLevels(nestedArray)

        // update references when stepping back
        const references = referenceFrom.split('.')
        let newReferences = ""
        references.pop()
        for (let i = 0; i < references.length; i++) {
            if (i !== references.length - 1) { // if current index doesn't match total length - 1 which is last item of an array
                newReferences += references[i] + ".";
            }
        }

        setReferenceFrom(newReferences)

        if (nestedArray.length !== 0) {
            await fetchFields(nestedArray[nestedArray.length - 1])
        } else {
            setOptionsToDisplay([])
        }
    }

    return (
        <List style={{ width: 175, cursor: "pointer", overflowY: "auto" }}>
            {
                <>
                    {nestedLevels.length !== 0 &&
                        <>
                            <List.Item
                                onClick={async () => stepBack()}
                                className="rdw-dropdownoption-default placeholder-li"
                                style={{ padding: "0.5rem", opacity: nestedLevels.length === 1 ? 0 : 1 }}
                            >
                                <Icon size="small" name="angle left" /> {t('back')}
                            </List.Item>
                            {optionsToDisplay.nested_related_fields.map(item => (
                                <CopyToClipboard text={!item.app_model && "{{ " + referenceFrom.toLowerCase() + item.name + " }}"}
                                    onCopy={item.app_model ? () => fetchFields(item.app_model, true, item.name) : () => addPlaceholder(true)}
                                >
                                    <List.Item
                                        key={item.name}
                                        className="rdw-dropdownoption-default placeholder-li"
                                        style={{ padding: "0.5rem" }}
                                    >
                                        {item.app_model
                                            ? <strong> {item.display} <Icon size="small" name="angle right" /> </strong>
                                            :
                                            <Popup
                                                content={t('tag_coppied')}
                                                on='click'
                                                trigger={
                                                    <strong> <Icon size="small" name="tag" /> {item.display} </strong>
                                                }
                                            />
                                        }
                                    </List.Item>
                                </CopyToClipboard>
                            ))}
                            {optionsToDisplay.direct_fields.map(item => (
                                <CopyToClipboard text={"{{ " + referenceFrom.toLowerCase() + item.name + " }}"}>
                                    <List.Item
                                        key={item.name}
                                        className="rdw-dropdownoption-default placeholder-li"
                                        style={{ padding: "0.5rem" }}
                                    >
                                        <Popup
                                            content={t('tag_coppied')}
                                            on='click'
                                            trigger={
                                                <strong>  <Icon size="small" name="tag" /> {item.display} </strong>
                                            }
                                        />
                                    </List.Item>
                                </CopyToClipboard>
                            ))}
                            
                        </>
                    }
                </>
            }
        </List>
    );
};

export default Placeholders;
