import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import { FlexTable, FlexHeader, FlexRow, FlexItem } from '@components/tables/FlexTable'
import { Button, Segment } from 'semantic-ui-react'
import ConfirmModal from '@components/modals/ConfrimModal'
import TemplateManage from "../components/forms/TemplateManage";


const Templates = () => {
    const { t } = useTranslation();
    const [view, setView] = useState(1) // onboarding by default
    const [loading, setLoading] = useState(true) // onboarding by default
    const [data, setData] = useState([])
    const [templates, setTemplates] = useState([])
    const [mode, setMode] = useState({
        isManage: false,
        template: null
    })

    useEffect(() => {
        async function fetchTemplates(){
            const request = await requests.get(API.ONBOARDING_CHECKLIST_CONFIG)

            if( request.status === 200 ){
                setTemplates(request.response)
                setData(request.response.filter(template => template.type === view))
            }
            setLoading(false)
        }

        fetchTemplates()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if( !loading ){
            setData(templates.filter(template => template.type === view))
        }
        // eslint-disable-next-line
    }, [view, templates])

    const onDelete = async (id) => {
        const request = await requests.del(API.ONBOARDING_CHECKLIST_CONFIG + id + "/")

        if( request.status === 204 ){
            setTemplates(prevState => prevState.filter(item => item.id !== id))
        }
    }

    return (
        <Segment loading={loading} style={{ border: "none", boxShadow: "none", marginTop: 0, marginBottom: 0 }}>
            { mode.isManage
                ? <TemplateManage template={mode.template} setTemplates={setTemplates} setMode={setMode}/>
                :
                <>
                    <FlexRow padding="0">
                        <FlexItem>
                            <Button.Group basic size='small' style={{ borderRadius: 0 }}>
                                <Button active={view === 1} onClick={() => setView(1)}>On-boarding</Button>
                                <Button active={view === 2} onClick={() => setView(2)}>Off-boarding </Button>
                            </Button.Group>
                        </FlexItem>
                        <FlexItem textAlign="right">
                            <Button onClick={ () => setMode({...mode, isManage: true}) } primary size="small" content={ t('create_template') }/>
                        </FlexItem>
                    </FlexRow>
                    <div style={{ marginTop: "0.5rem" }}/>

                    <FlexTable>
                        <FlexRow fontSize="1rem">
                            <FlexHeader basis="20%" content="#"/>
                            <FlexHeader basis="60%" content={ t('title') }/>
                            <FlexHeader content={ t('description') }/>
                            <FlexHeader basis="20%" content={ t('actions') }/>
                        </FlexRow>
                        { data.length === 0 && <FlexRow fontSize="0.9rem"> <FlexItem bold content={ t('no_template_available') } textAlign="center"/></FlexRow> }
                        {   data.map((template, index) => (
                                <FlexRow fontSize="0.9rem" key={template.id}>
                                    <FlexItem bold basis="20%" content={ (index + 1) + "." }/>
                                    <FlexItem bold basis="60%" content={ template.title }/>
                                    <FlexItem content={ template.description.length === 0 ? "--" : template.description }/>
                                    <FlexItem basis="20%">
                                        <FlexRow padding="0" background="transparent">
                                            <FlexItem>
                                                <Icon
                                                    name="pencil-outline"
                                                    style={{ color: "var(--dark)", cursor: "pointer" }}
                                                    onClick={ () => setMode({isManage: true, template: template}) }
                                                />
                                            </FlexItem>
                                            <FlexItem>
                                                <ConfirmModal
                                                    description={ t('are_you_sure_onboarding_template') }
                                                    onConfirm={() => onDelete(template.id)}
                                                    button={
                                                        <Icon name="close-outline" style={{ color: "var(--danger)", cursor: "pointer" }}/>
                                                    }
                                                />
                                            </FlexItem>
                                        </FlexRow>
                                    </FlexItem>
                                </FlexRow>
                            ))
                        }
                    </FlexTable>
                </>
            }
        </Segment>
    );
};

export default Templates;