import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// components
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Grid, Header, Divider, Label } from 'semantic-ui-react';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const PDFFilePreview = ( { file, title } ) => {
    const { t } = useTranslation();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <Grid centered>
            <Grid.Row>
                <Header as="h2" content={title}/>
            </Grid.Row>
            <Divider/>
            <Grid.Row>
                <Document
                    file={file}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page renderTextLayer renderAnnotationLayer={true} pageNumber={pageNumber}/>
                </Document>
            </Grid.Row>
            <Divider/>
            <Grid.Row>
                { pageNumber === 1 ? "" :
                    <Button
                        primary
                        size="small"
                        style={{ fontWeight: "bold", cursor: "pointer" }}
                        onClick={ () => {
                            if( pageNumber === 1 ) {
                                setPageNumber(1)
                            } else {
                                setPageNumber(pageNumber-1)
                            }
                        }}>
                        { t('previous') }
                    </Button>
                }
                    <Label style={{ marginLeft: "2rem", marginRight: "2rem", paddingTop: "0.8rem" }}>
                        { t('page') } {pageNumber} { t('of') } {numPages}
                    </Label>

                { pageNumber === numPages ? "" :
                    <Button
                        primary
                        size="small"
                        style={{ fontWeight: "bold", cursor: "pointer" }}
                        onClick={ () => {
                            if( pageNumber === numPages ) {
                                setPageNumber(numPages)
                            } else {
                                setPageNumber(pageNumber+1)
                            }
                        }}>
                        { t('next') }
                    </Button>
                }
            </Grid.Row>
        </Grid>
    );
}

export default PDFFilePreview;