import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// stored
import { API } from '@store/config';
import { isEmpty } from '@helpers/validation';
import { requests } from '@helpers/requests';
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import NonFieldErrors from '@components/NonFieldErrors';

const ForecastRecordForm = ({ forecast, record, setRecords, onClose }) => {
    const { t } = useTranslation();
    const [form, setForm] = useState({
        date_from: record !== undefined ? record.date_from : "",
        date_to: record !== undefined ? record.date_to : "",
        plan_value: record !== undefined ? record.plan_value : 0,
        reality_value: record !== undefined ? record.reality_value === null ? 0 : record.reality_value : 0,
        forecast: forecast.id
    })
    const [errors, setErrors] = useState({})
    const [isProcessing, setIsProcessing] = useState(false)

    const handleSubmit = async () => {
        setIsProcessing(true)
        setErrors({})

        let request = undefined;
        if( record === undefined ){ // create
            request = await requests.post(API.FORECASTS + "records/", form)
        } else { // update
            request = await requests.put(API.FORECASTS + "records/" + record.id + "/", form)
        }

        if(request !== undefined){
            if( request.status === 201 ){
                setRecords(prevState => [request.response, ...prevState])
                onClose()
            } 
            
            if(request.status === 200){
                setRecords( prevState => prevState.filter(item => {
                    if (item.id === request.response.id) {
                        item.date_from = request.response.date_from
                        item.date_to = request.response.date_to
                        item.plan_value = request.response.plan_value
                        item.reality_value = request.response.reality_value
                    }

                    return item;
                }))

                onClose()
            }

            if( request.status === 400 ){
                setErrors(request.response)
            }
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors} />

            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    required
                    label={t('date_from')}
                    value={form.date_from}
                    error={errors?.date_from || false}
                    onChange={(e, { value }) => setForm({ ...form, date_from: value })}
                />
                <SuperField as="datepicker"
                    required
                    label={t('date_to')}
                    value={form.date_to}
                    error={errors?.date_to || false}
                    onChange={(e, { value }) => setForm({ ...form, date_to: value })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="input"
                    required
                    label={t('plan')}
                    value={form.plan_value}
                    error={errors?.plan_value || false}
                    onChange={(e, { value }) => setForm({ ...form, plan_value: value })}
                />
                <SuperField as="input"
                    label={t('reality')}
                    value={form.reality_value}
                    error={errors?.reality_value || false}
                    onChange={(e, { value }) => setForm({ ...form, reality_value: value })}
                />
            </Form.Group>
            
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit 
                    loading={isProcessing}
                    disabled={
                        isProcessing ||
                        isEmpty(form.date_from) ||
                        isEmpty(form.date_to) ||
                        isEmpty(form.plan_value)
                    }
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default ForecastRecordForm;