import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// store
import { API } from '@store/config';
import { routes } from '@store/routes';
import { requests } from '@helpers/requests';
import { useSelectOptions } from '@helpers/hooks';
import { isEmpty, isPhoneNumberValid, isEmailValid } from '@helpers/validation';
// components
import Icon from '@components/Icon';
import Address from '@components/forms/Address';
import PDFFilePreview from '@components/PDFFilePreview';
import SuperField from '@components/forms/SuperField';
import ModalSubmit from '@components/buttons/ModalSubmit';
import ModalCancel from '@components/buttons/ModalCancel';
import PhoneField from '@components/forms/common/PhoneField';
import EmailField from '@components/forms/common/EmailField';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import SuperLabelField from '@components/forms/SuperLabelField';
import { Divider, Form, Message, Header, Button, Segment, Grid, Label } from 'semantic-ui-react';


const LabelCandidateForm = ({ onClose, labels, setLabels }) => {
    const { t } = useTranslation()
    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <SuperLabelField
                value={labels}
                onChange={(labels, selectedLabels) => setLabels(selectedLabels)}
                endpoint={API.CONTACT_LABELS}
            />
            <Form.Field style={{ textAlign: "right" }}>
                <Button type="button" primary content={t('confirm')} onClick={onClose} />
            </Form.Field>
        </Form>
    )
}

const CandidateForm = ({ onClose, setSize }) => {
    const { t } = useTranslation()
    const history = useHistory()

    const hiddenFileInput = useRef(null)

    // fetch model language choices
    let [languageNames] = useSelectOptions(API.COMMON + "language_names/", "title")
    let language_choices = languageNames?.options || []

    // eslint-disable-next-line
    const [form, setForm] = useState({
        firstname: "",
        lastname: "",
        is_foreigner: false,
        is_self_employed: false,
        phone: "",
        email: "",
        gender: 3,
        marital_status: "",
        date_of_birth: "",
        place_of_birth: "",
        personal_number: "",
        id_card_number: "",
        permanent_same_as_temporary: false,
        emergency_name: "",
        emergency_email: "",
        emergency_phone: "",


        // business details
        name: "",
        id_number: "",          // IČO (identification_number)
        dit_number: "",         // DIČ (DIT Number)
        vat_number: "",         // IČ DPH (VAT Number) - optional
        nace_code: "",
        folder_details: "",     // business folder identifier
        tax_mode: "",
    })

    const [permanentAddress, setPermanentAddress] = useState({
        street: "",
        city: "",
        postcode: "",
        country: "",
        number: "",
        orientation_number: "",
        state: "",
    })

    const [temporaryAddress, setTemporaryAddress] = useState({
        street: "",
        city: "",
        postcode: "",
        country: "",
        number: "",
        orientation_number: "",
        state: "",
    })


    const initialSkill = { name: "", seniority_level: "" }
    const [skills, setSkills] = useState([initialSkill])

    const initialLanguages = {
        language_name: "",
        level: "",
        is_primary: false,
        is_native: false
    }
    const [languages, setLanguages] = useState([initialLanguages])
    const initialExperience = {
        job_title: "",
        employer: "",
        date_from: "",
        date_to: "",
        description: ""
    }
    const [experiences, setExperiences] = useState([initialExperience])
    const initialEducation = {
        name: "",
        education_type: "",
        department: "",
        field: "",
        date_from: "",
        date_to: ""
    }
    const [educations, setEducations] = useState([initialEducation])

    const initialInterest = { name: "" }
    const [interests, setInterests] = useState([initialInterest])
    const [labels, setLabels] = useState([])
    // eslint-disable-next-line
    const [cv, setCV] = useState(null)
    const [parsing, setParsing] = useState(false)
    // eslint-disable-next-line
    const [parsedResponse, setParsedResponse] = useState(null)

    const [errors, setErrors] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)
    const [additionalPersonInfoVisible, setAdditionalPersonInfoVisible] = useState(false)
    const [permanentAddressVisible, setPermanentAddressVisible] = useState(false)
    const [temporaryAddressVisible, setTemporaryAddressVisible] = useState(false)
    const [businessDetailsVisible, setBusinessDetailsVisible] = useState(false)
    const [skillsVisibility, setSkillsVisibility] = useState(false)
    const [languagesVisibility, setLanguagesVisibility] = useState(false)
    const [experiencesVisibility, setExperiencesVisibility] = useState(false)
    const [educationsVisibility, setEducationsVisibility] = useState(false)
    const [interestsVisibility, setInterestsVisibility] = useState(false)

    useEffect(() => {
        if (parsedResponse !== null) {
            setSize("fullscreen")
        }
        // eslint-disable-next-line
    }, [parsedResponse])

    // used to manage hidden file input when user choose to use our parsing feature
    const handleClick = event => {
        hiddenFileInput.current.click()
    }

    const handleChange = async (event) => {
        if (event.target.files.length > 0) {
            setParsing(true)
            setCV(event.target.files[0])

            const formData = new FormData()
            formData.append('file', event.target.files[0])
            const request = await requests.post(API.CANDIDATES + "parse_cv/", formData)
            if (request.status === 201) {
                setParsedResponse(request.response)
                let name = request.response.name
                let firstname = request.response.name
                let lastname = ""
                const splittedName = name.split(" ")
                if (splittedName.length === 2) {
                    firstname = splittedName[0]
                    lastname = splittedName[1]
                }
                setForm(prev => ({ ...prev, firstname: firstname, lastname: lastname, email: request.response.email, phone: request.response.phone.replaceAll(" ", "") }))
                setSkills(request.response.skills.map(skill => ({ name: skill, seniority_level: "" })))
            }

            setParsing(false)
        }
    }

    const onLabelRemove = (id) => {
        setLabels(prev => prev.filter(label => label.id !== id))
    }

    const handleSubmit = async () => {
        // define logic to be executed when the form is submitted
        setIsProcessing(true)
        setErrors(null)

        let experiences_data = []
        for (let i = 0; i < experiences.length; i++) {
            if (!isEmpty(experiences[i].job_title) && isDateFromValid(experiences[i].date_from) && isDateToValid(experiences[i].date_to)) {
                experiences_data.push(experiences[i])
            }
        }

        let educations_data = []
        for (let i = 0; i < educations.length; i++) {
            if (!isEmpty(educations[i].education_type) && isDateFromValid(educations[i].date_from) && isDateToValid(educations[i].date_to) && !isFutureDate(educations[i].date_from) && !isFutureDate(educations[i].date_to)) {
                educations_data.push(educations[i])
            }
        }

        let skills_data = []
        for (let i = 0; i < skills.length; i++) {
            // tracking if skills is properly filled and if name is unique compare to other already added skills
            if (!isEmpty(skills[i].name) && skills_data.filter(item => item.name === skills[i].name).length === 0) {
                skills_data.push(skills[i])
            }
        }

        let interests_data = []
        for (let i = 0; i < interests.length; i++) {
            if (!isEmpty(interests[i].name)) {
                interests_data.push(interests[i])
            }
        }

        let languages_data = []
        for (let i = 0; i < languages.length; i++) {
            if (!isEmpty(languages[i].language_name) && !isEmpty(languages[i].level)) {
                languages_data.push(languages[i])
            }
        }

        let data = {
            emergency_name: form.emergency_name,
            emergency_email: form.emergency_email,
            emergency_phone: form.emergency_phone,
            is_foreigner: form.is_foreigner,
            id_card_number: form.id_card_number !== "" ? form.id_card_number : null,
            personal_number: form.personal_number !== "" ? form.personal_number : null,
            marital_status: form.marital_status !== "" ? form.marital_status : null,
            firstname: form.firstname,
            lastname: form.lastname,
            gender: isEmpty(form.gender) ? 3 : form.gender,
            date_of_birth: isEmpty(form.date_of_birth) ? null : form.date_of_birth,
            place_of_birth: form.place_of_birth,
            permanent_address: permanentAddress,
            temporary_address: temporaryAddress,
            business_detail: !form.is_self_employed ? null : {
                name: form.name,
                identification_number: form.id_number,
                dit_number: form.dit_number,
                vat_number: form.vat_number,
                business_register_folder: form.folder_details,
                nace_code: form.nace_code !== "" ? form.nace_code : null,
                tax_mode: form.tax_mode !== "" ? form.tax_mode : null,
            }
        }

        if ( !isEmpty(form.phone) || !isEmpty(form.email) ) {
            data['contacts'] =  {
                create: [{ phone: form?.phone || "", email: form?.email || "", is_default: true }]
            }
        }

        if (experiences_data.length > 0) data['experiences'] = {
            create: experiences_data.map(item => {
                if (item.date_from === "") item.date_from = null
                if (item.date_to === "") item.date_to = null

                return item
            })
        }
        if (skills_data.length > 0) data['skills'] = {
            create: skills_data.map(item => {
                if (item.seniority_level === "") item.seniority_level = null

                return item
            })
        }
        if (educations_data.length > 0) data['educations'] = {
            create: educations_data.map(item => {
                if (item.date_from === "") item.date_from = null
                if (item.date_to === "") item.date_to = null

                return item
            })
        }
        if (languages_data.length > 0) data['languages'] = { create: languages_data }
        if (interests_data.length > 0) data['interests'] = { create: interests_data }
        if (labels.length > 0) data['contact_labels'] = { add: labels.map(item => item.id) }

        const request = await requests.post(API.CANDIDATES, data)
        if (request.status === 400) setErrors(request.response)
        if (request.status === 201) {
            if (cv !== null) {
                const formData = new FormData()
                formData.append('cv', cv)
                const uploadImage = await requests.post(API.CANDIDATES + request.response.id + "/cv/", formData)
                if (uploadImage.status === 200) {
                    let candidate = request.response
                    candidate.cv = uploadImage.response.cv
                    history.push(routes.CANDIDATES_CARD + request.response.id)
                } else {
                    const deleteCandidate = await requests.del(API.CANDIDATES + request.response.id + "/")
                    if (deleteCandidate.status === 204) {
                        console.log(uploadImage.response)
                    }
                }
            } else {
                history.push(routes.CANDIDATES_CARD + request.response.id)
            }
        }

        setIsProcessing(false)
    }

    // eslint-disable-next-line
    const isFormValid = () => {
        // define rules to be checked against form
        if (isEmpty(form.firstname)) return false
        if (isEmpty(form.lastname)) return false
        if (!isEmpty(form.phone) && !isPhoneNumberValid(form.phone)) return false
        if (!isEmpty(form.email) && !isEmailValid(form.email)) return false
        if (isFutureDate(form.date_of_birth)) return false
        if (form.is_self_employed && isEmpty(form.name) && isEmpty(form.id_number)) return false

        return true
    }

    const isDateFromValid = (from, to) => {
        if (isEmpty(from) || isEmpty(to)) return true
        return (!isEmpty(from) && !isEmpty(to)) && moment(from).isBefore(moment(to)) ? true : false
    }

    const isDateToValid = (from, to) => {
        if (isEmpty(from) || isEmpty(to)) return true
        return (!isEmpty(from) && !isEmpty(to)) && moment(to).isAfter(moment(from)) ? true : false
    }

    const isFutureDate = (date) => {
        if (isEmpty(date)) return false
        const currentDay = moment()
        if (currentDay.isBefore(date, 'day')) {
            return true
        }

        return false
    }

    return (
        <>
            { parsing
                ?
                <Segment loading={parsing} style={{ padding: 0, margin: 0, backgroundColor: "transparent", minHeight: "10rem", border: "none", boxShadow: "none" }}>
                    {parsing && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "8rem" }}> {t('processing_file')} </p>}
                </Segment>
                :
                <Grid>
                    <Grid.Row columns={parsedResponse === null ? 1 : 2} style={{ padding: 0 }}>
                        {parsedResponse !== null &&
                            <Grid.Column style={{ padding: "2rem", border: "2px solid var(--light)" }}>
                                <PDFFilePreview title={t('preview')} file={parsedResponse.attachment_file_url} />
                            </Grid.Column>
                        }
                        <Grid.Column style={{ padding: 0 }}>
                            <Message
                                attached
                                header={t("add_candidate")}
                                content={<span style={{ display: parsedResponse !== null ? "none" : "block" }}> {t('try_our_new_feature_cv_parsing')}! {t('click_here_to')} <strong onClick={handleClick} style={{ cursor: "pointer", color: "var(--primary)" }}>{t('create_profile_from_cv')}</strong>.</span>}
                            />
                            <Form onSubmit={handleSubmit} className="attached fluid segment">
                                {errors !== null &&
                                    <p style={{ color: "var(--danger)", textAlign: "center", fontWeight: "bold", fontSize: "1.4rem" }}>
                                        {t('form_containing_errors')}
                                    </p>
                                }
                                <input
                                    type="file"
                                    id="cv_parsing"
                                    ref={hiddenFileInput}
                                    onChange={handleChange}
                                    style={{ display: "none" }}
                                />
                                <Header as="h3" content={t('personal_information')} />
                                <Divider />
                                <Form.Group widths="equal">
                                    <SuperField as="input"
                                        required
                                        autoFocus
                                        label={t('lastname')}
                                        placeholder={t('lastname')}
                                        value={form.lastname}
                                        onChange={(e, { value }) => setForm(prev => ({ ...prev, lastname: value }))}
                                        error={errors?.lastname || false}
                                    />
                                    <SuperField as="input"
                                        required
                                        label={t('firstname')}
                                        placeholder={t('firstname')}
                                        value={form.firstname}
                                        onChange={(e, { value }) => setForm(prev => ({ ...prev, firstname: value }))}
                                        error={errors?.firstname || false}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <SuperField as="checkbox"
                                        label={t("is_foreigner")}
                                        checked={form.is_foreigner}
                                        onChange={() => setForm(prev => ({ ...prev, is_foreigner: !form.is_foreigner }))}
                                    />
                                    <SuperField as="checkbox"
                                        label={t("self_employed_singular")}
                                        checked={form.is_self_employed}
                                        onChange={() => setForm(prev => ({ ...prev, is_self_employed: !form.is_self_employed }))}
                                    />
                                </Form.Group>

                                <Divider />
                                <div style={{ marginBottom: "0.5rem", cursor: "pointer" }} onClick={() => setAdditionalPersonInfoVisible(!additionalPersonInfoVisible)}>
                                    <Icon name={!additionalPersonInfoVisible ? "chevron-forward-outline" : "chevron-down-outline"} style={{ marginRight: "0.5rem", cursor: "pointer" }} />
                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                        {t('additional_personal_information')}
                                    </span>
                                </div>
                                <Divider />
                                {additionalPersonInfoVisible &&
                                    <div>
                                        <Form.Group widths="equal">
                                            <SuperField as="choice-select"
                                                type="genders"
                                                label={t('gender')}
                                                value={form.gender?.toString() || ""}
                                                onChange={(e, { value }) => setForm(prev => ({ ...prev, gender: value }))}
                                            />
                                            <SuperField as="choice-select"
                                                type="marital_statuses"
                                                label={t('marital_status')}
                                                value={form.marital_status?.toString() || ""}
                                                onChange={(e, { value }) => setForm(prev => ({ ...prev, marital_status: value }))}
                                            />
                                        </Form.Group>
                                        <Form.Group widths="equal">
                                            <SuperField as="datepicker"
                                                label={t('date_of_birth')}
                                                startMode="year"
                                                help={t('must_be_at_least_15_years_old')}
                                                error={isFutureDate(form.date_of_birth) ? t('date_cant_be_future') : errors?.date_of_birth?.[0] || false}
                                                value={form.date_of_birth}
                                                onChange={(e, { value }) => setForm(prev => ({ ...prev, date_of_birth: value }))}
                                            />
                                            <SuperField as="input"
                                                label={t('place_of_birth')}
                                                value={form.place_of_birth}
                                                onChange={(e, { value }) => setForm(prev => ({ ...prev, place_of_birth: value }))}
                                            />
                                        </Form.Group>
                                        <Form.Group widths="equal">
                                            <SuperField as="input"
                                                label={t('personal_number')}
                                                value={form.personal_number}
                                                error={errors?.personal_number?.[0] || false}
                                                onChange={(e, { value }) => setForm(prev => ({ ...prev, personal_number: value }))}
                                            />
                                            <SuperField as="input"
                                                label={t('identity_card_number')}
                                                help={t('identity_card_number_hint')}
                                                value={form.id_card_number}
                                                error={errors?.id_card_number?.[0] || false}
                                                onChange={(e, { value }) => setForm(prev => ({ ...prev, id_card_number: value }))}
                                            />
                                        </Form.Group>

                                        <Header as="h4" content={t("emergency_contact")} />
                                        <SuperField as="input"
                                            label={t('emergency_name')}
                                            value={form.emergency_name}
                                            error={errors?.emergency_name?.[0] || false}
                                            onChange={(e, { value }) => setForm(prev => ({ ...prev, emergency_name: value }))}
                                        />
                                        <Form.Group widths="equal">
                                            <SuperField as="input"
                                                label={t('emergency_phone')}
                                                value={form.emergency_phone}
                                                error={errors?.emergency_phone?.[0] || false}
                                                onChange={(e, { value }) => setForm(prev => ({ ...prev, emergency_phone: value }))}
                                            />
                                            <SuperField as="input"
                                                label={t('emergency_email')}
                                                value={form.emergency_email}
                                                error={errors?.emergency_email?.[0] || false}
                                                onChange={(e, { value }) => setForm(prev => ({ ...prev, emergency_email: value }))}
                                            />
                                        </Form.Group>
                                        <Divider />
                                    </div>
                                }

                                <div style={{ marginBottom: "0.5rem", cursor: "pointer" }} onClick={() => setPermanentAddressVisible(!permanentAddressVisible)}>
                                    <Icon name={!permanentAddressVisible ? "chevron-forward-outline" : "chevron-down-outline"} style={{ marginRight: "0.5rem", cursor: "pointer" }} />
                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                        {t('permanent_address')}
                                    </span>
                                </div>
                                <Divider />
                                {permanentAddressVisible &&
                                    <div>
                                        <Address isForm address={permanentAddress} setAddress={setPermanentAddress} />
                                        <SuperField as="checkbox"
                                            label={t('same_as_temporary_address')}
                                            checked={form.permanent_same_as_temporary}
                                            onChange={() => {
                                                if (!form.permanent_same_as_temporary === true) {
                                                    setTemporaryAddress(prev => ({
                                                        ...prev,
                                                        street: permanentAddress.street,
                                                        city: permanentAddress.city,
                                                        postcode: permanentAddress.postcode,
                                                        country: permanentAddress.country,
                                                        state: permanentAddress.state,
                                                        number: permanentAddress.number,
                                                        orientation_number: permanentAddress.orientation_number,
                                                    }))
                                                    setForm(prev => ({ ...prev, permanent_same_as_temporary: !form.permanent_same_as_temporary }))
                                                } else {
                                                    setForm(prev => ({ ...prev, permanent_same_as_temporary: !form.permanent_same_as_temporary }))
                                                }
                                            }}
                                        />
                                        <Divider />
                                    </div>
                                }

                                {form.permanent_same_as_temporary !== true &&
                                    <>
                                        <div style={{ marginBottom: "0.5rem", cursor: "pointer" }} onClick={() => setTemporaryAddressVisible(!temporaryAddressVisible)}>
                                            <Icon name={!temporaryAddressVisible ? "chevron-forward-outline" : "chevron-down-outline"} style={{ marginRight: "0.5rem", cursor: "pointer" }} />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                                {t('temporary_address')}
                                            </span>
                                        </div>
                                        <Divider />
                                        {temporaryAddressVisible &&
                                            <div>
                                                <Address isForm address={temporaryAddress} setAddress={setTemporaryAddress} />
                                                <Divider />
                                            </div>
                                        }
                                    </>
                                }

                                {form.is_self_employed &&
                                    <>
                                        <div style={{ marginBottom: "0.5rem", cursor: "pointer" }} onClick={() => setBusinessDetailsVisible(!businessDetailsVisible)}>
                                            <Icon name={!businessDetailsVisible ? "chevron-forward-outline" : "chevron-down-outline"} style={{ marginRight: "0.5rem", cursor: "pointer" }} />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                                {t('business_detail')}
                                                <span style={{ color: "var(--danger)" }}> - {t('fill_business_name_id_number_to_procced')}</span>
                                            </span>
                                        </div>
                                        <Divider />
                                        {businessDetailsVisible &&
                                            <div>
                                                <Form.Group widths="equal">
                                                    <SuperField as="input"
                                                        required
                                                        label={t('name')}
                                                        placeholder={t('name')}
                                                        value={form.name}
                                                        onChange={(e, { value }) => setForm({ ...form, name: value })}
                                                    />
                                                    <SuperField as="choice-select"
                                                        label={t('tax_mode')}
                                                        type="tax_mode"
                                                        value={form.tax_mode}
                                                        onChange={(e, { value }) => setForm({ ...form, tax_mode: value })}
                                                    />
                                                </Form.Group>
                                                <Form.Group widths="equal">
                                                    <SuperField as="input"
                                                        required
                                                        label={t('id_number')}
                                                        placeholder={t('id_number')}
                                                        value={form.id_number}
                                                        onChange={(e, { value }) => setForm({ ...form, id_number: value })}
                                                    />
                                                    <SuperField as="input"
                                                        label={t('dit_number')}
                                                        placeholder={t('dit_number')}
                                                        value={form.dit_number}
                                                        onChange={(e, { value }) => setForm({ ...form, dit_number: value })}
                                                    />
                                                    <SuperField as="input"
                                                        label={t('vat_number')}
                                                        placeholder={t('vat_number')}
                                                        value={form.vat_number}
                                                        onChange={(e, { value }) => setForm({ ...form, vat_number: value })}
                                                    />
                                                </Form.Group>

                                                <Form.Group widths="equal">
                                                    <SuperField as="input"
                                                        label={t('register_folder')}
                                                        placeholder={t('register_folder')}
                                                        value={form.folder_details}
                                                        onChange={(e, { value }) => setForm({ ...form, folder_details: value })}
                                                    />
                                                    <SuperField as="choice-select"
                                                        search
                                                        label={t('nace_code')}
                                                        endpoint={API.NACE_CODE}
                                                        text="name"
                                                        value={form.nace_code}
                                                        onChange={(e, { value }) => setForm({ ...form, nace_code: value })}
                                                    />
                                                </Form.Group>
                                                <Divider />
                                            </div>
                                        }
                                    </>
                                }

                                <Header as="h3" content={t('contact_info')} />
                                <Divider />

                                <Form.Group widths="equal">
                                    <PhoneField
                                        hideType
                                        placeholder={t('enter_number')}
                                        value={form.phone}
                                        setValue={(e, { value }) => setForm({
                                            ...form,
                                            phone: value.replaceAll(" ", "")
                                        })}
                                    />
                                    <EmailField
                                        label={t('email')}
                                        placeholder={t('enter_email')}
                                        value={form.email}
                                        setValue={(e, { value }) => setForm({
                                            ...form,
                                            email: value
                                        })}
                                    />
                                </Form.Group>

                                <div style={{ marginBottom: "0.5rem", cursor: "pointer", fontWeight: "bold", fontSize: "1.3rem" }} onClick={() => setSkillsVisibility(!skillsVisibility)}>
                                    <Icon name={!skillsVisibility ? "chevron-forward-outline" : "chevron-down-outline"} style={{ marginRight: "0.5rem", cursor: "pointer" }} />
                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                        {t('skills')}
                                        <span style={{ float: "right", fontSize: "0.9rem", paddingTop: "0.6rem" }}>
                                            <Icon name="bookmark-outline" style={{ marginRight: "0.5rem" }} />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                                {skills.filter(item => !isEmpty(item.name)).length}
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <Divider />
                                {skillsVisibility &&
                                    <div>
                                        {skills.map((skill, index) => (
                                            <div key={index}>
                                                <Form.Group widths="equal">
                                                    <SuperField as="input"
                                                        required
                                                        placeholder={t('title')}
                                                        label={t('title')}
                                                        error={skills.filter((item, idx) => (idx !== index)).map(item => item.name).includes(skill.name) ? t('skill_with_this_name_is_already_assigned') : false}
                                                        value={skill.name}
                                                        onChange={(e, { value }) => setSkills(prev => prev.filter((item, idx) => {
                                                            if (idx === index) {
                                                                item.name = value
                                                            }
                                                            return item
                                                        }))}
                                                    />
                                                    <SuperField as="choice-select"
                                                        type="seniority_level_types"
                                                        label={t('seniority_level')}
                                                        value={skill.seniority_level}
                                                        onChange={(e, { value }) => setSkills(prev => prev.filter((item, idx) => {
                                                            if (idx === index) {
                                                                item.seniority_level = value
                                                            }
                                                            return item
                                                        }))}
                                                    />
                                                    <Form.Field width="1" style={{ textAlign: "center", marginTop: "2.5rem" }}>
                                                        <Icon name="close-outline" style={{ cursor: "pointer", color: "var(--danger)" }} onClick={() => setSkills(prev => prev.filter((item, idx) => idx !== index))} />
                                                    </Form.Field>
                                                </Form.Group>
                                                <Divider />
                                            </div>
                                        ))}
                                        <Button type="button" primary size="small" content={t('add_skill')} onClick={() => setSkills(prev => [...prev, initialSkill])} />
                                        <Divider />
                                    </div>
                                }

                                <div style={{ marginBottom: "0.5rem", cursor: "pointer", fontWeight: "bold", fontSize: "1.3rem" }} onClick={() => setLanguagesVisibility(!languagesVisibility)}>
                                    <Icon name={!languagesVisibility ? "chevron-forward-outline" : "chevron-down-outline"} style={{ marginRight: "0.5rem", cursor: "pointer" }} />
                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                        {t('languages')}
                                        <span style={{ float: "right", fontSize: "0.9rem", paddingTop: "0.6rem" }}>
                                            <Icon name="bookmark-outline" style={{ marginRight: "0.5rem" }} />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                                {languages.filter(item => !isEmpty(item.language_name) && !isEmpty(item.level)).length}
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <Divider />
                                {languagesVisibility &&
                                    <div>
                                        {languages.map((language, index) => (
                                            <div key={index}>
                                                <Form.Group widths="equal">
                                                    <SuperField search as="choice-select"
                                                        required
                                                        customOptions={language_choices.filter(item => !languages.filter((lang, idx) => idx !== index).map(lng => lng.language_name).includes(item.value))}
                                                        label={t('language')}
                                                        value={language.language_name}
                                                        //  && languages.findIndex(item => item === language) !== index
                                                        onChange={(e, { value }) => setLanguages(prev => prev.filter((item, idx) => {
                                                            if (idx === index) {
                                                                item.language_name = value
                                                            }
                                                            return item
                                                        }))}
                                                    />
                                                    <SuperField search as="choice-select"
                                                        required
                                                        type="language_level_choices"
                                                        label={t('level')}
                                                        value={language.level}
                                                        onChange={(e, { value }) => setLanguages(prev => prev.filter((item, idx) => {
                                                            if (idx === index) {
                                                                item.level = value
                                                            }
                                                            return item
                                                        }))}
                                                    />
                                                    <Form.Field width="1" style={{ textAlign: "center", marginTop: "2rem" }}>
                                                        <Icon name="close" style={{ cursor: "pointer" }} onClick={() => setLanguages(prev => prev.filter((item, idx) => idx !== index))} />
                                                    </Form.Field>
                                                </Form.Group>
                                                <Form.Group>
                                                    <SuperField as="checkbox"
                                                        label={t('primary_language')}
                                                        checked={language.is_primary}
                                                        disabled={languages.filter((item, idx) => (idx !== index) && item.is_primary).length > 0}
                                                        onChange={() => setLanguages(prev => prev.filter((item, idx) => {
                                                            if (idx === index) {
                                                                item.is_primary = !language.is_primary
                                                            }
                                                            return item
                                                        }))}
                                                    />
                                                    <SuperField as="checkbox"
                                                        label={t('native_language')}
                                                        checked={language.is_native}
                                                        disabled={languages.filter((item, idx) => (idx !== index) && item.is_native).length > 0}
                                                        onChange={() => setLanguages(prev => prev.filter((item, idx) => {
                                                            if (idx === index) {
                                                                item.is_native = !language.is_native
                                                            }
                                                            return item
                                                        }))}
                                                    />
                                                </Form.Group>
                                                <Divider />
                                            </div>
                                        ))}
                                        <Button type="button" primary size="small" content={t('add_language')} onClick={() => setLanguages(prev => [...prev, initialLanguages])} />
                                        <Divider />
                                    </div>
                                }

                                <div style={{ marginBottom: "0.5rem", cursor: "pointer", fontWeight: "bold", fontSize: "1.3rem" }} onClick={() => setExperiencesVisibility(!experiencesVisibility)}>
                                    <Icon name={!experiencesVisibility ? "chevron-forward-outline" : "chevron-down-outline"} style={{ marginRight: "0.5rem", cursor: "pointer" }} />
                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                        {t('experiences')}
                                        <span style={{ float: "right", fontSize: "0.9rem", paddingTop: "0.6rem" }}>
                                            <Icon name="bookmark-outline" style={{ marginRight: "0.5rem" }} />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                                {experiences.filter(item => !isEmpty(item.job_title)).length}
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <Divider />
                                {experiencesVisibility &&
                                    <div>
                                        {experiences.map((experience, index) => (
                                            <div key={index}>
                                                <Form.Group widths="equal">
                                                    <SuperField as="input"
                                                        required
                                                        placeholder={t('job_title')}
                                                        label={t('job_title')}
                                                        value={experience.job_title}
                                                        onChange={(e, { value }) => setExperiences(prev => prev.filter((item, idx) => {
                                                            if (idx === index) {
                                                                item.job_title = value
                                                            }
                                                            return item
                                                        }))}
                                                    />
                                                    <SuperField as="input"
                                                        placeholder={t('employer')}
                                                        label={t('employer')}
                                                        value={experience.employer}
                                                        onChange={(e, { value }) => setExperiences(prev => prev.filter((item, idx) => {
                                                            if (idx === index) {
                                                                item.employer = value
                                                            }
                                                            return item
                                                        }))}
                                                    />
                                                </Form.Group>
                                                <Form.Group widths="equal">
                                                    <SuperField as="datepicker"
                                                        placeholder={t('date_from')}
                                                        label={t('date_from')}
                                                        value={experience.date_from}
                                                        error={isFutureDate(experience.date_from) ? t('date_cant_be_future') : isDateFromValid(experience.date_from, experience.date_to) ? false : t("invalid_date_range")}
                                                        onChange={(e, { value }) => setExperiences(prev => prev.filter((item, idx) => {
                                                            if (idx === index) {
                                                                item.date_from = value
                                                            }
                                                            return item
                                                        }))}
                                                    />
                                                    <SuperField as="datepicker"
                                                        placeholder={t('date_to')}
                                                        label={t('date_to')}
                                                        value={experience.date_to}
                                                        error={isFutureDate(experience.date_to) ? t('date_cant_be_future') : isDateToValid(experience.date_from, experience.date_to) ? false : t("invalid_date_range")}
                                                        onChange={(e, { value }) => setExperiences(prev => prev.filter((item, idx) => {
                                                            if (idx === index) {
                                                                item.date_to = value
                                                            }
                                                            return item
                                                        }))}
                                                    />
                                                </Form.Group>
                                                <Form.Group widths="equal">
                                                    <SuperField as="textarea"
                                                        label={t('job_description')}
                                                        placeholder={t('job_description')}
                                                        value={experience.description}
                                                        onChange={(e, { value }) => setExperiences(prev => prev.filter((item, idx) => {
                                                            if (idx === index) {
                                                                item.description = value
                                                            }
                                                            return item
                                                        }))}
                                                    />
                                                </Form.Group>
                                                <Form.Field style={{ textAlign: 'right' }}>
                                                    <Button type="button" content={t('remove')} style={{ cursor: "pointer", background: "var(--danger)", color: "var(--light)" }} onClick={() => setExperiences(prev => prev.filter((item, idx) => idx !== index))} />
                                                </Form.Field>
                                                <Divider />
                                            </div>
                                        ))}
                                        <Button type="button" primary size="small" content={t('add_experience')} onClick={() => setExperiences(prev => [...prev, initialExperience])} />
                                        <Divider />
                                    </div>
                                }

                                <div style={{ marginBottom: "0.5rem", cursor: "pointer", fontWeight: "bold", fontSize: "1.3rem" }} onClick={() => setEducationsVisibility(!educationsVisibility)}>
                                    <Icon name={!educationsVisibility ? "chevron-forward-outline" : "chevron-down-outline"} style={{ marginRight: "0.5rem", cursor: "pointer" }} />
                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                        {t('educations')}
                                        <span style={{ float: "right", fontSize: "0.9rem", paddingTop: "0.6rem" }}>
                                            <Icon name="bookmark-outline" style={{ marginRight: "0.5rem" }} />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                                {educations.filter(item => !isEmpty(item.education_type)).length}
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <Divider />
                                {educationsVisibility &&
                                    <div>
                                        {educations.map((education, index) => (
                                            <div key={index}>
                                                <Form.Group widths="equal">
                                                    <SuperField as="choice-select"
                                                        required
                                                        search
                                                        placeholder={t('education_type')}
                                                        type="education_type_choices"
                                                        label={t('education_type')}
                                                        value={education.education_type}
                                                        onChange={(e, { value }) => setEducations(prev => prev.filter((item, idx) => {
                                                            if (idx === index) {
                                                                item.education_type = value
                                                            }
                                                            return item
                                                        }))}
                                                        initialOptions={{
                                                            attribute: "education_type",
                                                            as_display: true,
                                                            source: education,
                                                        }} 
                                                    />
                                                    <SuperField as="input"
                                                        placeholder={t('name')}
                                                        label={t('name')}
                                                        value={education.name}
                                                        onChange={(e, { value }) => setEducations(prev => prev.filter((item, idx) => {
                                                            if (idx === index) {
                                                                item.name = value
                                                            }
                                                            return item
                                                        }))}
                                                    />
                                                </Form.Group>
                                                <Form.Group widths="equal">
                                                    <SuperField as="input"
                                                        placeholder={t('department')}
                                                        label={t('department')}
                                                        value={education.department}
                                                        onChange={(e, { value }) => setEducations(prev => prev.filter((item, idx) => {
                                                            if (idx === index) {
                                                                item.department = value
                                                            }
                                                            return item
                                                        }))}
                                                    />
                                                    <SuperField as="input"
                                                        placeholder={t('field')}
                                                        label={t('field')}
                                                        value={education.field}
                                                        onChange={(e, { value }) => setEducations(prev => prev.filter((item, idx) => {
                                                            if (idx === index) {
                                                                item.field = value
                                                            }
                                                            return item
                                                        }))}
                                                    />
                                                </Form.Group>
                                                <Form.Group widths="equal">
                                                    <SuperField as="datepicker"
                                                        placeholder={t('date_from')}
                                                        label={t('date_from')}
                                                        value={education.date_from}
                                                        error={isFutureDate(education.date_from) ? t('date_cant_be_future') : isDateFromValid(education.date_from, education.date_to) ? false : t("invalid_date_range")}
                                                        onChange={(e, { value }) => setEducations(prev => prev.filter((item, idx) => {
                                                            if (idx === index) {
                                                                item.date_from = value
                                                            }
                                                            return item
                                                        }))}
                                                    />
                                                    <SuperField as="datepicker"
                                                        placeholder={t('date_to')}
                                                        label={t('date_to')}
                                                        value={education.date_to}
                                                        error={isFutureDate(education.date_to) ? t('date_cant_be_future') : isDateToValid(education.date_from, education.date_to) ? false : t("invalid_date_range")}
                                                        onChange={(e, { value }) => setEducations(prev => prev.filter((item, idx) => {
                                                            if (idx === index) {
                                                                item.date_to = value
                                                            }
                                                            return item
                                                        }))}
                                                    />
                                                </Form.Group>

                                                <Form.Field style={{ textAlign: 'right' }}>
                                                    <Button type="button" content={t('remove')} style={{ cursor: "pointer", background: "var(--danger)", color: "var(--light)" }} onClick={() => setEducations(prev => prev.filter((item, idx) => idx !== index))} />
                                                </Form.Field>
                                                <Divider />
                                            </div>
                                        ))}
                                        <Button type="button" primary size="small" content={t('add_education')} onClick={() => setEducations(prev => [...prev, initialEducation])} />
                                        <Divider />
                                    </div>
                                }

                                <div style={{ marginBottom: "0.5rem", cursor: "pointer", fontWeight: "bold", fontSize: "1.3rem" }} onClick={() => setInterestsVisibility(!interestsVisibility)}>
                                    <Icon name={!interestsVisibility ? "chevron-forward-outline" : "chevron-down-outline"} style={{ marginRight: "0.5rem", cursor: "pointer" }} />
                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                        {t('interests')}
                                        <span style={{ float: "right", fontSize: "0.9rem", paddingTop: "0.6rem" }}>
                                            <Icon name="bookmark-outline" style={{ marginRight: "0.5rem" }} />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                                {interests.filter(item => !isEmpty(item.name)).length}
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <Divider />
                                {interestsVisibility &&
                                    <div>
                                        {interests.map((interest, index) => (
                                            <div key={index}>
                                                <Form.Group widths="equal">
                                                    <SuperField as="input"
                                                        required
                                                        placeholder={t('name')}
                                                        label={t('name')}
                                                        value={interest.name}
                                                        onChange={(e, { value }) => setInterests(prev => prev.filter((item, idx) => {
                                                            if (idx === index) {
                                                                item.name = value
                                                            }
                                                            return item
                                                        }))}
                                                    />
                                                    <Form.Field width="1" style={{ textAlign: "center", marginTop: "2.5rem" }}>
                                                        <Icon name="close-outline" style={{ cursor: "pointer", color: "var(--danger)" }} onClick={() => setInterests(prev => prev.filter((item, idx) => idx !== index))} />
                                                    </Form.Field>
                                                </Form.Group>
                                                <Divider />
                                            </div>
                                        ))}
                                        <Button type="button" primary size="small" content={t('add_interest')} onClick={() => setInterests(prev => [...prev, initialInterest])} />
                                        <Divider />
                                    </div>
                                }

                                {parsedResponse === null &&
                                    <>
                                        <Header as="h3" content={t('attach_resume')} />
                                        <Divider />
                                        <SuperField as="input" type="file" onChange={(event) => {
                                            if (event.target.files.length > 0) {
                                                setCV(event.target.files[0])
                                            }
                                        }} />
                                    </>
                                }

                                <Header as="h3" content={t('tags')} />
                                {labels.map(label => (
                                    <Label style={{ marginTop: '3px', color: "white", backgroundColor: label.color === "" ? "" : label.color }} key={label.id}>
                                        {label.name}
                                        <Icon name="close-outline" style={{ marginLeft: "1rem", cursor: "pointer" }} onClick={() => onLabelRemove(label.id)} />
                                    </Label>

                                ))}
                                <SuperDuperModal
                                    trigger={
                                        <Label basic size="tiny" style={{ cursor: "pointer" }}>
                                            <Icon name="add-outline" />
                                        </Label>
                                    }
                                    content={
                                        <LabelCandidateForm labels={labels} setLabels={setLabels} />
                                    }
                                />



                                <Divider />
                                <Form.Field style={{ textAlign: "right" }}>
                                    <ModalCancel onClose={onClose} />
                                    <ModalSubmit
                                        disabled={isProcessing || !isFormValid()}
                                        loading={isProcessing}
                                        content={t('confirm')}
                                    />
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        </>
    );
};

export default CandidateForm;