import React from 'react'
import { useSelector } from 'react-redux'
// import moment from 'moment'
import { useTranslation } from 'react-i18next'
// store
import '@store/languages'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { tzDateTime } from '@helpers/dates'
// components
// import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import { Card, Divider } from 'semantic-ui-react'
import Action from '@components/general/Action'
// custom
import MilestoneForm from '../../forms/MilestoneForm'

const ProjectMilestoneCard = ({ milestone, setData, milestones, project }) => {
    const { t } = useTranslation()

    const dateFormat = useSelector((state) => state.date_format)
    // const language = useSelector((state) => state.language)
    // const current_date = moment().format('YYYY-MM-DD')
    // const in_progress = moment(milestone.due_date).diff(current_date, 'days')
    // const due_date = moment(milestone.due_date, 'YYYYMMDD').locale(language).fromNow()
    // const from_date = moment(milestone.from_date, 'YYYYMMDD').locale(language).fromNow()

    const onDelete = async (id) => {
        const request = await requests.del(API.PROJECT_MILESTONES + id + '/')
        if (request.status === 200) {
            setData((prev) => prev.filter((item) => item.id !== id))
        }
    }

    return (
        <Card>
            <Card.Content style={{ padding: '1rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <strong>{milestone.name}</strong>
                    </div>
                    <CanView permissions={['projects.c_manage_milestones']}>
                        <div>
                            <Action
                                as="modal"
                                type="icon"
                                icon="pencil-outline"
                                size="large"
                                iconColor="var(--dark)"
                                popupText={t('edit')}
                                tooltip={t('edit')}
                                modal={<MilestoneForm project={project} record={milestone} setData={setData} />}
                            />
                            <Action
                                as="delete"
                                type="icon"
                                size="large"
                                tooltip={t('delete')}
                                text={t('delete_milestone')}
                                onClick={() => onDelete(milestone.id)}
                            />
                        </div>
                    </CanView>
                </div>
                <Divider style={{ marginTop: 0 }} />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        {milestone.from_date ? tzDateTime(milestone.from_date).format(dateFormat) : '--'} -{' '}
                        {milestone.due_date ? tzDateTime(milestone.due_date).format(dateFormat) : '--'}
                    </div>
                    <div>
                        {milestone.stats.closed_issues_count} / {milestone.stats.issues_count} (
                        {milestone.stats.progress}%)
                    </div>
                </div>
            </Card.Content>
        </Card>
    )
}

export default ProjectMilestoneCard
