import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { getQueryParam } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import Paginator from '@components/Paginator';
import EmptyRow from '@components/tables/EmptyRow';
import SuperField from '@components/forms/SuperField';
import SpinnerSegment from '@components/SpinnerSegment';
import { Header, Divider, Icon as SemanticIcon, Form, Segment, Button, Popup } from 'semantic-ui-react';

const TransferItems = ({ warehouseFrom, handleSelectedItems }) => {
    const { t } = useTranslation()

    const [search, setSearch] = useState("")
    const [selected, setSelected] = useState([])
    const [loading, setLoading] = useState(false)
    const [isFocused, setIsFocused] = useState(true)
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [storedItems, setStoredItems] = useState([])

    async function fetchStoredItems(params, isFiltered) {
        params = params || ""
        
        if (warehouseFrom !== null && warehouseFrom !== "") {
            setLoading(true)

            if (isFiltered) {
                setPage(1)
                params = `&page=${1}&limit=${limit}`
            }

            if (!params.includes('limit')) {
                params += '&limit=' + limit
            }
            if (!params.includes('page')) {
                params += '&page=' + page
            } else {
                const pageNumber = getQueryParam("page", params)
                params += '&page=' + pageNumber
                setPage(pageNumber)
            }

            let queryParam = "&paginate=true" + params
            if (search !== "" ) queryParam += "&search=" + search
            const request = await requests.get(API.ASSETS + `stored_items/?warehouse=${warehouseFrom}&show_only_stored_items=true&exclude_catalogue_item=true&is_active=true${queryParam}`)
            if (request.status === 200) {
                setTotal(request.response.count)
                setStoredItems(request.response.results)
            }
            setIsFocused(true)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (warehouseFrom !== null && warehouseFrom !== "") {
            fetchStoredItems()
        } else {
            setStoredItems([])
            setSelected([])
            setTotal(0)
            setPage(1)
        }
        // eslint-disable-next-line
    }, [warehouseFrom])

    useEffect(() => {
        if (selected.length > 0) {
            handleSelectedItems(selected)
        }
        // eslint-disable-next-line
    }, [selected])

    const onSearch = async () => {
        await fetchStoredItems("", true)
    }

    const handleSelection = (storedItem, quantity) => {
        if (selected.filter(item => item.stored_item === storedItem.id)?.length === 0) {
            setSelected(prev => ([...prev, { stored_item: storedItem.id, quantity: parseInt(quantity) }]))
        } else {
            setSelected(prev => prev.filter(item => item.stored_item !== storedItem.id))
        }
    }

    const isSelected = (storedItem) => {
        return selected.filter(item => item.stored_item === storedItem.id)?.length > 0
    }

    return (
        <>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "2rem" }}>
                <Header style={{ marginBottom: 0 }} as="h3" content={t('stored_items') + ` ( ${total} )`}/>
                <div> 
                    {t('selected_items')}: <strong>{ selected.length }</strong> 
                </div>
            </div>
            <Divider/>
            <SpinnerSegment loading={loading}>
                <Form.Field style={{ width: 'auto', marginTop: "0.5rem" }}>
                    <SuperField as="input"
                        fluid
                        autoFocus={isFocused}
                        disabled={loading || warehouseFrom === ""}
                        icon={
                            <SemanticIcon
                                link
                                name="search"
                                onClick={() => onSearch()}
                            />
                        }
                        value={search}
                        placeholder={t('search')}
                        onChange={(e, { value }) => setSearch(value)}
                        onKeyPress={(event) => {
                            if (event.key === "Enter"){
                                onSearch()
                            }
                        }}
                    />
                </Form.Field>
                <Divider/>
                <EmptyRow length={storedItems.length}/>
                {storedItems.map(storedItem => (
                    <StoredItem
                        key={storedItem.id}
                        storedItem={storedItem}
                        isSelected={isSelected}
                        handleSelection={(storedItem, quantity) => handleSelection(storedItem, quantity)}
                    />
                ))}

                {total > 0 && (
                    <Paginator
                        forcePage={page}
                        forceReload={false}
                        limit={limit}
                        setLimit={setLimit}
                        length={total}
                        // selected={selected}
                        onChange={(params) => fetchStoredItems(params)}
                    />
                )}
            </SpinnerSegment>
        </>
    );
};

const StoredItem = ({ storedItem, isSelected, handleSelection }) => {
    const { t } = useTranslation()

    const [quantity, setQuantity] = useState(storedItem?.quantity || 1)

    const isValidNumber = (value) => {
        if (isNaN(value)) return false
        if (quantity > storedItem.quantity || quantity <= 0) return false

        return true
    }

    const isGroupedItemValid = () => {
        if (storedItem.all_children_count > 0) {
            let items = storedItem.all_children.filter(item => item.quantity === 0)
            let issuedItems = storedItem.all_children.filter(item => item.owners.length > 0)

            if (items.length > 0 || issuedItems.length > 0) return false
        }

        return true
    }

    return (
        <Segment
            style={{ 
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "0.5rem",
                // cursor: "pointer",
            }}
        >
            <div style={{ flexBasis: "250px" }}>
                <strong>{ storedItem.code &&  storedItem.code + " - "  } { storedItem.item.title }</strong> { storedItem.sn &&  "(SN: "+ storedItem.sn + ")"  } 
                { storedItem.all_children_count > 0 && 
                    <Popup
                        hoverable
                        style={{ width: "auto" }}
                        position="center right"
                        trigger={<Icon name="information-circle" style={{ marginLeft: "0.5rem", fontSize: "1.2rem" }}/>}
                        content={
                            <div>
                                { t('grouped_item') + " (" + storedItem.all_children_count + ")" } <br/>
                                <ul style={{ paddingLeft: 0, listStyle: "none" }}>
                                    { storedItem.all_children.map((item, idx) => (
                                        <li style={{ color: item.quantity > 0 ? "var(--dark)" : "var(--danger)" }}> 
                                            <strong>{idx + 1}. { item.code &&  item.code + " - "  } { item.item.title }</strong> { item.sn &&  "(SN: "+ item.sn + ")"  } ({ item.quantity }) 
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        }
                    />
                }
                { !isGroupedItemValid() && 
                    <Popup
                        position="right center"
                        trigger={<Icon name="warning" style={{ color: "var(--danger)", marginLeft: "0.5rem", fontSize: "1.2rem" }}/>}
                        content={t('not_all_items_are_returned_this_grouped_item_can_not_be_transfered')}
                    />
                }
            </div>
            <div>
                {t('current_quantity')}: { storedItem.quantity }
            </div>
            <div style={{ display: "flex" }}>
                <Form.Field style={{ marginBottom: 0 }}>
                    <SuperField as="input"
                        size="small"
                        style={{ width: "60px" }}
                        value={quantity}
                        placeholder={t('quantity')}
                        disabled={isSelected(storedItem) || !isGroupedItemValid() || storedItem.all_children_count > 0 || (storedItem.sn !== null && storedItem.sn !== "")}
                        error={!isValidNumber(quantity) ? true : false}
                        onChange={(e, { value }) => setQuantity(value)}
                    />
                </Form.Field>
                <Form.Field style={{ marginLeft: "0.5rem", marginBottom: 0, marginTop: "0.1rem" }}>
                    <Button
                        size="small"
                        type="button"
                        disabled={(quantity > storedItem.quantity || quantity <= 0) || !isGroupedItemValid()}
                        content={isSelected(storedItem) ? t('remove') : t('add')}
                        onClick={() => handleSelection(storedItem, quantity)}
                        style={{ 
                            background: isSelected(storedItem) ? "var(--dark)" : "var(--primary)",
                            color: "white"
                        }}
                    /> 
                </Form.Field>
            </div>
        </Segment>
    )
}

export default TransferItems;