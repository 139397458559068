import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { useFetchData, useQueryPage, useIsMount } from '@helpers/hooks'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import { Grid, Segment } from 'semantic-ui-react'
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import ConfirmModal from '@components/modals/ConfrimModal'
import CanView from '@components/perms/CanView'
import Paginator from '@components/Paginator'
// module specific
import WarehouseForm from './forms/WarehouseForm'

const Warehouses = ({ response, setResponse }) => {
    const { t } = useTranslation()
    const isMount = useIsMount()
    let page = useQueryPage()
    page = isMount ? 1 : page

    const [limit, setLimit] = useState(10)
    const [warehouses, setWarehouses] = useFetchData(
        API.ASSET_WAREHOUSES + '?paginate=true&page=' + page + '&limit=' + limit
    )

    useEffect(() => {
        if (response !== null) {
            if (response.type === 'warehouse_create') {
                setWarehouses((prev) => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        count: prev.data.count + 1,
                        results: [response.result, ...prev.data.results],
                    },
                }))
            }

            setResponse(null)
        }
        // eslint-disable-next-line
    }, [response])

    const onPaginate = async (params) => {
        setWarehouses((prev) => ({ ...prev, isLoading: true }))
        const request = await requests.get(API.ASSET_WAREHOUSES + '?paginate=true' + params)

        if (request.status === 200) {
            setWarehouses((prev) => ({ ...prev, data: request.response }))
        }

        setWarehouses((prev) => ({ ...prev, isLoading: false }))
    }

    const onDelete = async (id) => {
        let request = await requests.post(API.ASSET_WAREHOUSES + id + '/delete/')

        if (request.status === 200) {
            setWarehouses((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    count: prev.data.count - 1,
                    results: prev.data.results.filter((item) => item.id !== id),
                },
            }))
        }
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column computer={16} tablet={16} mobile={16}>
                    <Segment
                        loading={warehouses.isLoading}
                        style={{
                            padding: 0,
                            background: 'transparent',
                            boxShadow: 'none',
                            border: 'none',
                        }}
                    >
                        <FlexTable stripped={false}>
                            <FlexRow>
                                <FlexHeader basis="150%" content={t('title')} />
                                <FlexHeader content={t('responsible_person')} />
                                {/* <FlexHeader content={t('address')}/> */}
                                <FlexHeader content={t('unit')} />
                                <CanView permissions={['assets.c_manage_warehouses']}>
                                    <FlexHeader content={t('actions')} />
                                </CanView>
                            </FlexRow>
                            {(warehouses?.data?.count === 0 || warehouses === undefined) && (
                                <FlexRow fontSize="1rem" borders>
                                    <FlexItem bold textAlign="center" content={t('no_data')} />
                                </FlexRow>
                            )}

                            {warehouses?.data?.results?.map((warehouse) => (
                                <FlexRow background="var(--white)" fontSize="1rem" borders key={warehouse.id}>
                                    <FlexItem bold basis="150%" content={warehouse.title} />
                                    <FlexItem>
                                        {warehouse.responsible_person?.fullname ? (
                                            <Link
                                                className="ref-link"
                                                to={routes.EMPLYOEES_DETAIL + warehouse?.responsible_person?.id}
                                                target="_blank"
                                            >
                                                {warehouse?.responsible_person?.fullname}
                                            </Link>
                                        ) : (
                                            '--'
                                        )}
                                    </FlexItem>
                                    {/* <FlexItem content={warehouse?.address ? "--" : "--"}/> TODO function to build full text address based on address data object */}
                                    <FlexItem content={warehouse?.unit ? warehouse?.unit?.name : '--'} />
                                    <CanView permissions={['assets.c_manage_warehouses']}>
                                        <FlexItem>
                                            <FlexRow padding="0" background="transparent">
                                                <FlexItem>
                                                    <SuperDuperModal
                                                        trigger={
                                                            <Icon
                                                                name="pencil-outline"
                                                                style={{ color: 'var(--dark)', cursor: 'pointer' }}
                                                            />
                                                        }
                                                        size="small"
                                                        content={
                                                            <WarehouseForm
                                                                warehouse={warehouse}
                                                                setWarehouses={setWarehouses}
                                                            />
                                                        }
                                                    />
                                                </FlexItem>
                                                <FlexItem>
                                                    <ConfirmModal
                                                        description={t('are_you_sure')}
                                                        onConfirm={() => onDelete(warehouse.id)}
                                                        button={
                                                            <Icon
                                                                name="close-outline"
                                                                style={{ color: 'var(--danger)', cursor: 'pointer' }}
                                                            />
                                                        }
                                                    />
                                                </FlexItem>
                                            </FlexRow>
                                        </FlexItem>
                                    </CanView>
                                </FlexRow>
                            ))}
                        </FlexTable>
                    </Segment>
                    <Paginator
                        forcePage={page || 1}
                        limit={limit}
                        setLimit={setLimit}
                        length={warehouses?.data?.count || 0}
                        onChange={(params) => onPaginate(params)}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default Warehouses
