import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import '@store/languages'
// store
import { API } from '@store/config'
import { useHasPermission } from '@helpers/hooks'
import { requests, fileDownload } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Table, Header, Form, Divider, Message, Segment, Button, Grid, Popup, Modal } from 'semantic-ui-react'

const AllowedAbsenceTypes = ({ setFirstLoad, excludedAbsenceTypes, setExcludedAbsenceTypes, onClose }) => {
    const { t } = useTranslation()

    const [selected, setSelected] = useState(excludedAbsenceTypes || [])

    const handleSubmit = () => {
        setExcludedAbsenceTypes(selected)
        setFirstLoad(true)
        onClose()
    }

    return (
        <Form>
            <Message info visible>
                <strong>{t('select_all_types_that_you_want_to_exclude_from_report')}.</strong> <br />
                {t('make_sure_to_generate_report_again_when_you_change_this_setting')}.
            </Message>

            <Divider />

            <SuperField
                as="choice"
                search
                multiple
                value={selected}
                text={'title'}
                endpoint={API.ATTENDANCE_BASE + 'absence_types/?is_active=true'}
                label={t('select_absence_type_to_exclude')}
                onChange={(e, { value }) => setSelected(value)}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit type="button" onClick={() => handleSubmit()} />
            </Form.Field>
        </Form>
    )
}

const AbsenceAttendanceReport = () => {
    const { t } = useTranslation()
    // eslint-disable-next-line
    const canManageOnlyAssignedEmployees = useHasPermission(
        'attendance.c_can_manage_only_employees_as_responsible_person'
    )

    const excludeSelf = useHasPermission('attendance.c_can_not_manage_self_attendance')

    const profile = useSelector((state) => state.user.profile)

    const dateFormat = useSelector((state) => state.date_format)
    const [firstLoad, setFirstLoad] = useState(true)
    const [loading, setLoading] = useState(false)
    const [employee, setEmployee] = useState('')
    const [unit, setUnit] = useState('')
    const [excludedAbsenceTypes, setExcludedAbsenceTypes] = useState([])

    const [filters, setFilters] = useState({
        month: parseInt(moment().format('M')),
        year: parseInt(moment().format('YYYY')),
        unit: '',
        contract: '',
    })
    const [errors, setErrors] = useState(null)
    const [data, setData] = useState([])

    const months = [
        { key: 1, value: 1, text: t('january') },
        { key: 2, value: 2, text: t('february') },
        { key: 3, value: 3, text: t('march') },
        { key: 4, value: 4, text: t('april') },
        { key: 5, value: 5, text: t('may') },
        { key: 6, value: 6, text: t('june') },
        { key: 7, value: 7, text: t('july') },
        { key: 8, value: 8, text: t('august') },
        { key: 9, value: 9, text: t('september') },
        { key: 10, value: 10, text: t('october') },
        { key: 11, value: 11, text: t('november') },
        { key: 12, value: 12, text: t('december') },
    ]


    async function fetchMonthlyAbsences(e) {
        e.preventDefault()
        setFirstLoad(false)
        setData([])
        setErrors(null)
        setLoading(true)

        // eslint-disable-next-line
        const firstDay = moment(filters.year + '-' + filters.month + '-01')
            .startOf('form.')
            .format('YYYY-MM-DD')
        const lastDay = moment(filters.year + '-' + filters.month + '-01')
            .endOf('month')
            .format('YYYY-MM-DD')

        let queryParam = ''
        let excludeParam = ''
        if (unit !== '') queryParam += `&unit=${unit}`
        if (employee !== '') queryParam += `&contracts=${employee}`
        if (excludedAbsenceTypes.length > 0) {
            let ids = ''
            for (let i = 0; i < excludedAbsenceTypes.length; i++) {
                if (excludedAbsenceTypes.length - 1 === i) {
                    ids += excludedAbsenceTypes[i]
                } else {
                    ids += excludedAbsenceTypes[i] + ','
                }

                excludeParam = '&excluded_absence_types=' + ids
            }
        }
        const request = await requests.get(
            API.EXPORTS + `payrolls/absences/json/?date_from=${firstDay}&date_to=${lastDay}${queryParam}${excludeParam}`
        )

        if (request.status === 400) setErrors(request.response)
        if (request.status === 200) {
            setData(request.response)
        }

        setLoading(false)
        if (request.status === 200) {
            setFirstLoad(false)
        } else {
            setFirstLoad(true)
        }
    }

    useEffect(() => {
        setFirstLoad(true)
    }, [filters, unit, employee])

    const generateXLSXReport = async (id) => {
        const firstDay = moment(filters.year + '-' + filters.month + '-01')
            .startOf('form.')
            .format('YYYY-MM-DD')
        const lastDay = moment(filters.year + '-' + filters.month + '-01')
            .endOf('month')
            .format('YYYY-MM-DD')

        let queryParam = ''
        let excludeParam = ''
        if (unit !== '') queryParam += `&unit=${unit}`
        if (employee !== '') queryParam += `&contract=${employee}`
        if (excludedAbsenceTypes.length > 0) {
            let ids = ''
            for (let i = 0; i < excludedAbsenceTypes.length; i++) {
                if (excludedAbsenceTypes.length - 1 === i) {
                    ids += excludedAbsenceTypes[i]
                } else {
                    ids += excludedAbsenceTypes[i] + ','
                }

                excludeParam = '&excluded_absence_types=' + ids
            }
        }

        await fileDownload(
            'GET',
            API.EXPORTS +
                `payrolls/absences/excel/?date_from=${firstDay}&date_to=${lastDay}${queryParam}${excludeParam}`,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            `attendance_absences_report_${firstDay}__${lastDay}.xlsx`
        )
    }

    const getContractAdditionalFilters = () => {
        const query = '{id, fond, fond_per_unit, fond_per_unit_display, employee, work_position, available_vacation_fond}'
        let baseQueryParams = `&query=${query}&only_basic_info=true`
        baseQueryParams += (canManageOnlyAssignedEmployees && profile?.id ? "&responsible_persons=" + profile.id : "")
        baseQueryParams += (unit ? '&unit=' + unit : "")

        // eslint-disable-next-line
        const firstDay = moment(filters.year + '-' + filters.month + '-01')
            .startOf('month')
            .format('YYYY-MM-DD')
        const lastDay = moment(filters.year + '-' + filters.month + '-01')
            .endOf('month')
            .format('YYYY-MM-DD')

        // get me current day and first day in month from previous month
        let currentDay = moment()
        if (currentDay.isAfter(moment(lastDay))) {
            currentDay = moment(lastDay)
        }

        const previousMonth = currentDay.clone().subtract(1, 'months')
        const contract_date_to = currentDay.format('YYYY-MM-DD')
        const contract_date_from = previousMonth.clone().startOf('month').format('YYYY-MM-DD')

        baseQueryParams += `&valid_contract_based_on_date_from=${contract_date_from}&valid_contract_based_on_date_to=${contract_date_to}`

        if (profile && excludeSelf) {
            baseQueryParams += `&exclude_profiles=${profile.id}`
        }

        return baseQueryParams

    }

    return (
        <>
            <Grid stackable>
                <Grid.Row verticalAlign="middle" columns={1}>
                    <Grid.Column>
                        <Form onSubmit={fetchMonthlyAbsences}>
                            <Form.Group widths={6}>
                                <SuperField
                                    as="yearpicker"
                                    width="2"
                                    value={filters.year}
                                    label={t('year')}
                                    dateFormat="YYYY"
                                    onChange={(e, { value }) => setFilters({ ...filters, year: value })}
                                />
                                <SuperField
                                    as="choice"
                                    width="2"
                                    search
                                    clearable={false}
                                    label={t('month')}
                                    value={filters.month}
                                    customOptions={months}
                                    onChange={(e, { value }) => setFilters({ ...filters, month: value })}
                                />
                                
                                <SuperField
                                    as="choice-select"
                                    search
                                    value={unit}
                                    label={t('department')}
                                    onChange={async (e, { value }) => setUnit(value)}
                                    text="name"
                                    endpoint={API.UNITS}
                                    additionalFilters={'?query={id, name}&only_basic_info=true&only_from_active_company=true' + (canManageOnlyAssignedEmployees && profile?.id ? '&responsible_persons=' + profile.id : "")}
                                    help={t('select_a_department_to_retrieve_a_list_of_employees_in_that_department')}
                                />

                                <SuperField
                                    as="choice-select"
                                    key={getContractAdditionalFilters()}
                                    search
                                    label={t('employee')}
                                    value={employee}
                                    text={(item) => item.employee.fullname_with_titles}
                                    endpoint={API.CONTRACTS}
                                    additionalFilters={getContractAdditionalFilters()}
                                    onChange={(e, { value }) => {
                                        // if (value === '') setIsGenerated(false)
                                        setEmployee(value)
                                    }}
                                />
                                

                                <Form.Field style={{ marginTop: '1.7rem' }}>
                                    <Button
                                        primary
                                        content={t('confirm')}
                                        disabled={filters.month === '' || filters.year === ''}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Field>
                                <SuperDuperModal
                                    size="tiny"
                                    trigger={
                                        <span className="ref-link" style={{ color: 'var(--primary)' }}>
                                            {t('change_allowed_absence_types')} (
                                            {excludedAbsenceTypes.length === 0
                                                ? t('all_allowed').toLocaleLowerCase()
                                                : t('excluded').toLocaleLowerCase() +
                                                  ': ' +
                                                  excludedAbsenceTypes.length}
                                            )
                                        </span>
                                    }
                                    content={
                                        <AllowedAbsenceTypes
                                            setFirstLoad={setFirstLoad}
                                            excludedAbsenceTypes={excludedAbsenceTypes}
                                            setExcludedAbsenceTypes={setExcludedAbsenceTypes}
                                        />
                                    }
                                />
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />

            {firstLoad && (
                <Header as="h3" textAlign="center">
                    {t('choose_options_to_generate_report')}
                </Header>
            )}
            {!firstLoad && errors === null && (
                <div style={{ padding: 0, overflow: 'auto', width: '100%' }}>
                    <Segment
                        loading={loading}
                        style={{
                            padding: 0,
                            margin: 0,
                            backgroundColor: 'transparent',
                            minHeight: '10rem',
                            border: 'none',
                            boxShadow: 'none',
                        }}
                    >
                        {loading && (
                            <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '8rem' }}>
                                {' '}
                                {t('loading_monthly_absences')}{' '}
                            </p>
                        )}
                        {!loading && (
                            <>
                                <p style={{ textAlign: 'right' }}>
                                    <Popup
                                        position="left center"
                                        trigger={
                                            <Icon
                                                onClick={() => generateXLSXReport()}
                                                style={{ fontSize: '1.5rem', marginRight: '1rem', cursor: 'pointer' }}
                                                name="download-outline"
                                            />
                                        }
                                        content={
                                            t('download') +
                                            ' - ' +
                                            t('absences_report') +
                                            ' - ' +
                                            filters.month +
                                            '/' +
                                            filters.year
                                        }
                                    />
                                </p>
                                <Table unstackable className="table responsive" style={{ fontSize: '0.9rem' }}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>{t('employee')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('personal_number')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('count')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('measure_unit')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('reason_of_absence')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('date_from')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('date_to')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('work_days')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('total_days')}</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {data?.length === 0 && (
                                            <Table.Row>
                                                <Table.Cell
                                                    colSpan="9"
                                                    style={{ textAlign: 'center', fontWeight: 'bold' }}
                                                >
                                                    {t('no_data')}
                                                </Table.Cell>
                                            </Table.Row>
                                        )}
                                        {data?.map((absence) => (
                                            <Table.Row>
                                                <Table.Cell>{absence.employee}</Table.Cell>
                                                <Table.Cell>{absence.personal_number}</Table.Cell>
                                                <Table.Cell>{absence.absence}</Table.Cell>
                                                <Table.Cell>
                                                    {absence.absence_d_or_h === 'd' ? t('days') : t('hours')}
                                                </Table.Cell>
                                                <Table.Cell>{absence.reason_of_absence}</Table.Cell>
                                                <Table.Cell>
                                                    {absence.date_from
                                                        ? moment(absence.date_from).format(dateFormat)
                                                        : ''}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {absence.date_to ? moment(absence.date_to).format(dateFormat) : ''}
                                                </Table.Cell>
                                                <Table.Cell>{absence.days}</Table.Cell>
                                                <Table.Cell>{absence.days_total}</Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </>
                        )}
                    </Segment>
                </div>
            )}

            <Modal size="tiny" open={errors !== null} onClose={() => setErrors(null)} closeIcon>
                <Modal.Content>
                    <Message visible error style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                        {t('errors_has_been_found_in_your_monthly_attendance_evidence')}. <br />
                        {t('reasons_mentioned_down_bellow')}.
                    </Message>
                    <Divider />
                    {errors?.map((error) => (
                        <>
                            <div>
                                {t('day')}:{' '}
                                <strong> {error?.date ? moment(error?.date).format(dateFormat) : '--'} </strong> <br />
                                {t('employee')}:{' '}
                                <strong> {error?.employee?.fullname || error?.fullname || '--'} </strong> <br />
                                {t('reason')}: <strong> {error?.reason || '--'} </strong> <br />
                            </div>
                            <Divider />
                        </>
                    ))}
                </Modal.Content>
            </Modal>
        </>
    )
}

export default AbsenceAttendanceReport
