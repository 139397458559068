import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { createRequest, getRequest } from '@services/ServiceCommon';
import { isEmailValid, isPhoneNumberValid } from '@services/helpers/validation';
// components
import SpinnerSegment from '@components/SpinnerSegment';
import { Segment, Form, Grid, Header, Button } from 'semantic-ui-react';
// module specific components
import SuperFieldSet from '@components/forms/SuperFieldSet';
import SuperField from '@components/forms/SuperField';
import ContactInfo from './components/ContactInfo';
import PermanentAddress from './components/PermanentAddress';
import TemporaryAddress from './components/TemporaryAddress';
import PersonalNumber from './components/PersonalNumber';
import MaritalStatus from './components/MaritalStatus';
import LastJob from './components/LastJob';
import LastCountryOfResidence from './components/LastCountryOfResidence';
// import HealthInsurance from './components/HealthInsurance';
import Educations from './components/Educations';
import Experiences from './components/Experiences';
import Languages from './components/Languages';
import Skills from './components/Skills';
import Interests from './components/Interests';
import GDPRField from './components/GDPRField';

const FormPreview = ({ selected, isTemplate, setSubmitted, applyForm, publication }) => {
    const { t } = useTranslation();

    const [data, setData] = useState({
        firstname: "",
        lastname: "",
        gender: "",
        middlename: "",
        titles_before: "",
        titles_after: "",
        all_previous_surnames: "",
        // birth info.
        date_of_birth: "",
        name_at_birth: "",
        place_of_birth: "",
        country_of_birth: "",
        contacts: null
    })
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(true)
    const [isProcessing, setIsProcessing] = useState(false)
    const [gdpr, setGDPR] = useState({})
    const [gdprs, setGDPRs] = useState([])
    const [choices, setChoices] = useState({})
    const [languages, setLanguages] = useState({})

    useEffect(() => {
        async function fetchAndSetGDPRS(){
            if( !isTemplate ){
                const request = await getRequest(API.GDPRS_PUBLIC + "?id=" + applyForm?.gdpr + "&query={id, title, is_required}", false)
    
                if(request.status === 200){
                    setGDPR({
                        id: request.response[0].id,
                        title: request.response[0].title,
                        is_checked: false,
                        is_required: request.response[0].is_required,
                    })
                }

                // loop over additional gdprs
                let gdprsArray = []
                for (let i = 0; i < applyForm?.additional_gdprs?.length || 0; i++) {
                    const request = await getRequest(API.GDPRS_PUBLIC + "?id=" + applyForm?.additional_gdprs[i] + "&query={id, title, is_required}", false)
    
                    if(request.status === 200){
                        gdprsArray.push({
                            id: request.response[0].id,
                            title: request.response[0].title,
                            is_checked: false,
                            is_required: request.response[0].is_required,
                        })
                    }
                }

                setGDPRs(gdprsArray)
            }
        }

        async function fetchChoices() {
            const request = await getRequest(API.PUBLIC + "choices/", false)
            if (request.status === 200) {
                setChoices(request.response)
            }
        }

        async function fetchLanguages() {
            const request = await getRequest(API.PUBLIC + "language_names/", false)
            if (request.status === 200) {
                setLanguages(request.response)
            }
        }

        async function fetchData() {
            setLoading(true)
            await fetchAndSetGDPRS()
            await fetchChoices()
            await fetchLanguages()
            setLoading(false)
        }

        fetchData()
        // eslint-disable-next-line
    }, [])

    const getChoices = (type) => {
        let selectedChoice = choices?.[type] || {}
        const arrayList = Object.keys(selectedChoice)
        let choice = []
        for (let i = 0; i < arrayList.length; i++) {
            choice.push({
                key: arrayList[i],
                value: arrayList[i],
                text: selectedChoice[arrayList[i]]
            })
        } 

        return choice
    } 

    const isSelected = (choice) => {
        return selected.includes(choice)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setIsProcessing(true)

        let finalDataset = {
            data: {
                firstname: data.firstname,
                lastname: data.lastname,
                gender: data?.gender ? parseInt(data?.gender) : 3,
                titles_before: data.titles_before,
                titles_after: data.titles_after,
                all_previous_surnames: data.all_previous_surnames,
                nationality: data?.nationality || null,
                citizenship: data?.citizenship || null,
                date_of_birth: data?.date_of_birth || null,
                name_at_birth: data.name_at_birth,
                place_of_birth: data.place_of_birth,
                country_of_birth: data.country_of_birth || null,
                permanent_address: data.permanent_address,
                temporary_address: data.temporary_address,
                personal_number: data.personal_number,
                marital_status: data.marital_status,
                educations: data.educations,
                languages: data.languages,
                experiences: data.experiences,
                skills: data.skills,
                interests: data.interests,
                last_job: data.last_job === "" ? null : data.last_job,
                last_country_residence: data.last_country_residence === "" ? null : data.last_country_residence ,
            },
            gdpr_agreement: {
                gdpr: gdpr?.id,
                is_checked: gdpr?.is_checked || false
            },
            additional_gdpr_agreements: gdprs.length === 0 ? null : {
                create: gdprs.map(item => {
                    return {
                        gdpr: item?.id,
                        is_checked: item?.is_checked || false
                    }
                })
            },
            publication: publication,
            config: applyForm?.id ? applyForm?.id : null,
            // attachments: null // TODO: Backend doesn't support null
        }

        if (data.contacts !== null) {
            finalDataset.data['contacts'] = data.contacts
        } else {
            finalDataset.data['contacts'] = { create: [] }
        }

        if (gdprs.length === 0) {
            delete finalDataset.additional_gdpr_agreements
        }

        if( isTemplate ){
            console.log(finalDataset)
        } else {
            const request = await createRequest(API.APPLICATION + "form_instances/", finalDataset, false)

            if( request.status === 201 ){
                setSubmitted(true)
            } else if( request.status === 400 ){
                setErrors(request.response)
            }
        }

        setIsProcessing(false)
    }

    const isFormValid = () => {
        // verify required GDPRS
        if( gdpr?.is_checked !== true ) return false
        if( gdprs.length !== 0 && gdprs.filter(item => (item.is_required === true && item.is_checked === false)).length > 0 ) return false
        
        // now verify incoming datasets based on field configuration:
        if( isSelected("firstname") && isSelected('lastname') ){
            if( data.firstname === "" || data.lastname === "") return false
        }

        if( isSelected("contacts") ){
            // validate phone or email
            const phone = data.contacts?.[0]?.create?.phone
            const email = data.contacts?.[0]?.create?.email
            if( email !== undefined && !isEmailValid(email) ) return false
            if( phone !== undefined && !isPhoneNumberValid(phone) ) return false
        }

        // if( isSelected("personal_information.birth_info") ){
        //     if( data.birth_info.date_of_birth === "" ) return false
        // }

        // if( isSelected("personal_number") ){
        //     if( data.personal_number === "" ) return false
        // }

        // if( isSelected("personal_information.marital_status") ){
        //     if( data.marital_status === "" ) return false
        // }

        // if( isSelected("personal_information.permanent_address") ){
        //     if(data.permanent_address === null ) return false
        //     if( data.permanent_address?.country === "" || data.permanent_address?.city === "" || data.permanent_address?.postcode === "" || data.permanent_address?.street === "" ) return false
        // }
        
        // if( isSelected("personal_information.temporary_address") ){
        //     if(data.temporary_address === null ) return false
        //     if( data.temporary_address?.country === "" || data.temporary_address?.city === "" || data.temporary_address?.postcode === "" || data.temporary_address?.street === "" ) return false
        // }

        return true
    }

    return (
        <SpinnerSegment loading={loading}>
            { !loading &&  
                <Form onSubmit={onSubmit}>
                    <Segment style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
                        { selected.length === 0 ?
                            <Grid centered>
                                <Header as="h3">
                                    { t('select_data_source_to_render') }
                                </Header>
                            </Grid>
                        :
                        <>
                            { (
                                isSelected("firstname") ||
                                isSelected("lastname") ||
                                isSelected("titles_before") ||
                                isSelected("titles_after") ||
                                isSelected("gender") ||
                                isSelected("all_previous_surnames")
                            ) &&
                                <SuperFieldSet legend={ t('personal_information') }>
                                        <Form.Group widths="equal">
                                            { isSelected("firstname") && (
                                                <SuperField as="input"
                                                    autoFocus
                                                    required
                                                    label={ t('firstname') }
                                                    placeholder={ t('enter_firstname') }
                                                    value={ data.firstname }
                                                    onChange={ (e) => setData({
                                                        ...data,
                                                        firstname: e.target.value
                                                    })}
                                                />
                                            )}
                                            { isSelected("lastname") && (
                                                <SuperField as="input"
                                                    required
                                                    label={ t('lastname') }
                                                    placeholder={ t('enter_lastname') }
                                                    value={ data.lastname }
                                                    onChange={ (e) => setData({
                                                        ...data,
                                                        lastname: e.target.value
                                                    })}
                                                />
                                            )}
                                        </Form.Group>
                                    <Form.Group>
                                        { isSelected("titles_before") && (
                                            <SuperField as="input"
                                                label={ t('title_before') }
                                                width="2"
                                                value={ data.titles_before }
                                                onChange={ (e) => setData({
                                                    ...data,
                                                    titles_before: e.target.value
                                                })}
                                            />
                                        )}
                                        { isSelected("titles_after") && (
                                            <SuperField as="input"
                                            label={ t('title_after') }
                                            width="2"
                                            value={ data.titles_after }
                                            onChange={ (e) => setData({
                                                ...data,
                                                titles_after: e.target.value
                                            })}
                                        />
                                        )}
                                        { isSelected("gender") && (
                                            <SuperField as="choice"
                                                width="4"
                                                customOptions={getChoices('genders')}
                                                label={ t('gender') }
                                                value={ data.gender.toString() }
                                                onChange={ (e, { value }) => setData({
                                                    ...data,
                                                    gender: value === "" ? "" : parseInt(value)
                                                })}
                                            />
                                        )}
                                        { isSelected('all_previous_surnames') && (
                                            <SuperField as="input"
                                                width="8"
                                                label={ t('all_previous_surnames') }
                                                value={ data.all_previous_surnames }
                                                onChange={ (e, { value }) => setData({
                                                    ...data,
                                                    all_previous_surnames: value
                                                })}
                                            />
                                        )}
                                    </Form.Group>
                                </SuperFieldSet>
                            }

                            { isSelected("contacts") && <ContactInfo setData={setData} errors={errors}/> }

                            { (
                                isSelected("date_of_birth") ||
                                isSelected("country_of_birth") ||
                                isSelected("name_at_birth") ||
                                isSelected("place_of_birth")
                            ) && (
                                <SuperFieldSet legend={t('birth_information')}>
                                    <Form.Group widths="equal">
                                        { isSelected("date_of_birth") && (
                                            <SuperField as="datepicker"
                                                label={ t('date_of_birth') }
                                                value={ data.date_of_birth }
                                                error={ errors?.data?.date_of_birth }
                                                onChange={ (e, { value }) => setData({
                                                    ...data,
                                                    date_of_birth: value
                                                }) }
                                            />
                                        )}
                                        { isSelected("country_of_birth") && (
                                            <SuperField as="choice"
                                                search
                                                label={t('country_of_birth')}
                                                customOptions={getChoices('countries')}
                                                value={ data.country_of_birth }
                                                onChange={ (e, { value }) => setData({
                                                    ...data,
                                                    country_of_birth: value
                                                }) }
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group widths="equal">
                                        { isSelected("name_at_birth") && (
                                            <SuperField as="input"
                                                label={ t('name_at_birth') }
                                                value={ data.name_at_birth }
                                                error={ errors?.data?.name_at_birth }
                                                onChange={ (e, { value }) => setData({
                                                    ...data,
                                                    name_at_birth: value
                                                }) }
                                            />
                                        )}
                                        { isSelected("place_of_birth") && (
                                            <SuperField as="input"
                                                search
                                                label={t('place_of_birth')}
                                                customOptions={getChoices('countries')}
                                                value={ data.place_of_birth }
                                                onChange={ (e, { value }) => setData({
                                                    ...data,
                                                    place_of_birth: value
                                                }) }
                                            />
                                        )}
                                    </Form.Group>
                                </SuperFieldSet>
                            ) }

                            { (
                                isSelected("personal_number") ||
                                isSelected("marital_status") ||
                                isSelected("last_job") ||
                                isSelected("last_country_residence") ||
                                isSelected("citizenship") ||
                                isSelected("nationality")
                            ) &&
                                <SuperFieldSet legend={t('additional_information')}>
                                    <Form.Group>
                                        { isSelected("personal_number") && <PersonalNumber setData={setData} errors={errors}/> }
                                        { isSelected("marital_status") && <MaritalStatus setData={setData} errors={errors} maritalStatuses={getChoices('marital_statuses')}/> }
                                    </Form.Group>
                                    <Form.Group>
                                        { isSelected("citizenship") && (
                                            <SuperField as="choice"
                                                width="8"
                                                search
                                                customOptions={getChoices('citizenship')}
                                                label={ t('citizenship') }
                                                value={ data.citizenship }
                                                onChange={ (e, { value }) => setData({
                                                    ...data,
                                                    citizenship: value
                                                })}
                                            />
                                        )}
                                        { isSelected("nationality") && (
                                            <SuperField as="choice"
                                                width="8"
                                                search
                                                customOptions={getChoices('nationalities')}
                                                label={ t('nationality') }
                                                value={ data.nationality }
                                                onChange={ (e, { value }) => setData({
                                                    ...data,
                                                    nationality: value
                                                })}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group>
                                        { isSelected("last_job") && <LastJob setData={setData} errors={errors}/> }
                                        { isSelected("last_country_residence") && <LastCountryOfResidence setData={setData} errors={errors} countries={getChoices('countries')}/> }
                                    </Form.Group>
                                </SuperFieldSet>
                            }

                            { (isSelected("permanent_address") || isSelected("temporary_address")) &&
                                <Grid columns={2}>
                                    <Grid.Row>
                                        <Grid.Column style={{ padding: 0 }}>
                                            { isSelected("permanent_address") && <PermanentAddress setData={setData} errors={errors} countries={getChoices('countries')}/> }
                                        </Grid.Column>
                                        <Grid.Column style={{ padding: 0 }}>
                                            { isSelected("temporary_address") && <TemporaryAddress setData={setData} errors={errors} countries={getChoices('countries')}/> }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            }

                            { isSelected("educations") && <Educations setData={setData} errors={errors} educationTypes={getChoices('education_type_choices')}/> }
                            { isSelected("languages") && <Languages setData={setData} errors={errors} languageList={languages} languageLevels={getChoices('language_level_choices')}/> }
                            { isSelected("experiences") && <Experiences setData={setData} errors={errors}/> }

                            { isSelected("skills") && <Skills setData={setData} errors={errors}/> }
                            { isSelected("interests") && <Interests setData={setData} errors={errors}/> }

                            { gdpr?.id && 
                                <GDPRField gdpr={gdpr} checked={gdpr.is_checked} setChecked={(checked) => setGDPR(prevState => ({...prevState, is_checked: checked}))} />
                            }

                            { gdprs.map(additionalGDPR => (
                                <GDPRField key={additionalGDPR.id} gdpr={additionalGDPR} checked={additionalGDPR.is_checked} setChecked={(checked) => setGDPRs(prevState => prevState.filter(item => {
                                    if(item.id === additionalGDPR.id){
                                        item.is_checked = checked
                                    }

                                    return item
                                }))} />
                            ))}

                            { !isTemplate && 
                                <Form.Field style={{ textAlign: "right"}}>
                                    <Button disabled={isProcessing || !isFormValid()} loading={isProcessing} primary>
                                        { t('submit_reaction') }
                                    </Button>
                                </Form.Field>
                            }
                        </>
                        }
                    </Segment>
                </Form>
            }
        </SpinnerSegment>
    );
};

export default FormPreview;