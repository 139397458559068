import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import ListView from '@components/ListView';
import ReactionsStats from '../components/ReactionsStats';
import ProfileCard from '@components/general/ProfileCard';
import ContactCell from '../../candidates/components/tables/ContactCell';

const ReactionsListView = ({ params }) => {
    const { t } = useTranslation();
    const [reactionStats, setReactionStats] = useState({})
    const [publisherChoiceList, setPublisherChoiceList] = useState({})
    const [rejected, setRejected] = useState({})
    const publisherChoices = useSelector(state => state.choices?.publishers)

    useEffect(() => {
        fetchReactionStats()
        createPublisherList()
        // eslint-disable-next-line
    }, [])

    const GetFullname = ({ data }) => {
        return (
            <p>{(data?.titles_before && data?.titles_before + " ") + data?.firstname + " " + data?.lastname + " " + (data?.titles_after && data?.titles_after)}</p>
        );
    }

    const fetchReactionStats = async () => {
        const request = await requests.get(`${API.JOBS_STATS}publishers/stats/?recruitment=${params.id}`)
        setReactionStats(request.response)
        const rejectedArray = request.response.results.filter(item => item.is_rejected)
        setRejected(rejectedArray)
    }

    const createPublisherList = () => {
        let choiceList = []
        Object.entries(publisherChoices).forEach(([key, value], index) => {
            choiceList.push({ value: key, text: value })
        })

        setPublisherChoiceList(choiceList)
    }

    const onReject = async (item, setData) => {
        const request = await requests.patch(API.PUBLICATION_REACTIONS + item.uuid + "/", { is_rejected: true })

        if (request.status === 200) {

            setData(prevState => (
                prevState.filter(data => {
                    if( data.id === item.id ){
                        data.is_rejected = true
                    }

                    return data
                })
            ))

            setRejected((prev) => [request.response, ...prev])

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('success'),
                description: t('reject_reaction_message'),
                animation: 'bounce',
                time: 5000,
            });
        }
    }

    const onUndoReject = async (item, setData) => {
        const request = await requests.patch(API.PUBLICATION_REACTIONS + item.uuid + "/", { is_rejected: false })

        if (request.status === 200) {

            setData(prevState => (
                prevState.filter(data => {
                    if( data.id === item.id ){
                        data.is_rejected = false
                    }

                    return data
                })
            ))

            setRejected((prev) => prev.filter((data) => data.id !== item.id));

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('success'),
                description: t('undo_rejection_message'),
                animation: 'bounce',
                time: 5000,
            });
        }
    }

    const onApply = async (item, setData) => {
        const request = await requests.post(API.PUBLICATION_REACTIONS + item.id + "/convert_to_profile/", {})

        if (request.status === 201) {
            const requestAssign = await requests.post(API.CANDIDATES + request.response.id + "/assign_to_recruitment/", { recruitment: params.id })
            if (requestAssign.status === 200) {
                setData(prevState => prevState.filter(reaction => {
                    if(reaction.id === item.id){
                        reaction.profile = request.response.id
                        reaction.status = false
                    }

                    return reaction
                }))
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('success'),
                    description: t('convert_to_profile_success_message'),
                    animation: 'bounce',
                    time: 5000,
                });
            }
        } else {
            toast({
                type: 'error',
                icon: 'warning',
                title: t('error'),
                description: t('convert_to_profile_error_message'),
                animation: 'bounce',
                time: 5000,
            });
        }
    }


    return (
        <>
            <ListView
                as="table"
                allowSearch
                endpoint={API.PUBLICATION_REACTIONS}
                initialFilters={{
                    recruitment: params.id,
                    is_rejected: "",
                    publishers: ""
                }}
                actionsCellWidth="2"
                actions={[
                    {
                        as: "modal",
                        type: "custom",
                        icon: "eye-outline",
                        customIconColor: "var(--primary)",
                        name: t('detail'),
                        modalSize: "tiny",
                        modal: (reaction) => <ProfileCard candidateData={reaction.data} />
                    },
                    {
                        as: "confirm",
                        type: "custom",
                        name: t('convert'),
                        customIconColor: "var(--blue)",
                        icon: "enter-outline",
                        text: t('would_you_like_to_convert_this_reaction_to_candidate'),
                        onClick: async (item, setData, setTotal) => onApply(item, setData),
                        isHidden: (item) => item.profile?.id !== undefined,
                    },
                    {
                        as: "confirm",
                        type: "custom",
                        name: t('reject'),
                        customIconColor: "var(--danger)",
                        icon: "trash-outline",
                        text: t('reject_react'),
                        onClick: async (item, setData) => onReject(item, setData),
                        isHidden: (item) => item.is_rejected === true || item.profile?.id !== undefined,
                    },
                    {
                        as: "confirm",
                        type: "custom",
                        name: t('undo_reject'),
                        customIconColor: "var(--blue)",
                        icon: "arrow-undo-outline",
                        text: t('undoreject'),
                        onClick: async (item, setData) => onUndoReject(item, setData),
                        isHidden: (item) => item.is_rejected !== true && item.profile?.id !== undefined,
                    },
                ]}
                renderStatisticView={(filters, data, total, params, setFilters, setTotal, setData, fetchData) => (
                    <ReactionsStats
                        data={reactionStats}
                        publisherChoiceList={publisherChoiceList}
                        rejected={rejected}
                        filters={filters}
                        setFilters={setFilters}
                        fetchData={fetchData}
                        total={reactionStats?.results?.length}
                    />
                )}
                tableHeaders={
                    [
                        { title: t('name') },
                        { title: t('contact') },
                        { title: t('created_on') },
                        { title: t('publication') },
                        { title: t('is_rejected') },
                        { title: t('gdpr') },
                    ]
                }
                renderCells={(reaction) => ([
                    { content: <GetFullname data={reaction?.data} /> },
                    { content: <ContactCell row={{ phone: reaction?.data?.contacts?.create?.[0]?.phone, email: reaction?.data?.contacts?.create?.[0]?.email}} /> },
                    { content: reaction?.created_on || "" },
                    { content: reaction?.publication?.is_published ? t('yes') : t('no') },
                    { content: reaction?.is_rejected ? t('yes') : t('no') },
                    { content: reaction?.config?.gdpr && reaction.config.gdpr.title },
                ])}
            />
        </>
    );
};

export default ReactionsListView;