import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
//store
import { requests } from '@helpers/requests';
import { API } from '@store/config';
//components
import { Button, Form, Divider, Segment } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import TaskTable from '../table/TaskTable';

const TemplateManage = ({ template, setTemplates, setMode }) => {
    const { t } = useTranslation()

    const [isProcessing, setIsProcessing] = useState(false);
    const [loadingTasks, setLoadingTasks] = useState(false);
    const [templateConfig, setTemplateConfig] = useState({
        id: template ? template.id : null,
        title: template ? template.title : "",
        type: template ? template.type.toString() : 0,
        description: template ? template.description : ''
    })

    const initTaskValues = {
        title: '',
        rank: 0,
        checklistConfig: template ? template.id : 0,
        assignedTo: [],
    }

    const [task, setTask] = useState(initTaskValues)
    const [tasks, setTasks] = useState([])

    useEffect(() => {
        async function fetchTasks() {
            if( templateConfig.id > 0 ){
                setLoadingTasks(true)
                const request = await requests.get(API.ONBOARDING_ITEMS_CONFIG + "?checklist_config="+templateConfig.id)

                if( request.status === 200 ){
                    const tasksList = request.response
                    tasksList.sort((a, b) => a.rank - b.rank)
                    setTasks(tasksList)
                }
                setLoadingTasks(false)
            }
        }

        fetchTasks()
    }, [templateConfig])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsProcessing(true);

        if( templateConfig.id > 0 ){
            const result = await requests.put(API.ONBOARDING_CHECKLIST_CONFIG + templateConfig.id + "/", templateConfig)

            if (result.status === 200) {
                setTemplateConfig({...templateConfig, id: result.response.id})
                setMode({ isManage: false, instance: null })
                setTemplates(prevState => prevState.filter(template => {
                    if(template.id === templateConfig.id){
                        template.title = templateConfig.title
                        template.description = templateConfig.description
                    }

                    return template
                }) )
            }
        } else {
            const request = await requests.post(API.ONBOARDING_CHECKLIST_CONFIG, templateConfig)
            if (request.status === 201) {
                setTemplateConfig({...templateConfig, id: request.response.id})
                setTemplates(prevState => [...prevState, request.response])
            }
        }

        setIsProcessing(false);
    }

    const createTask = async () => {
        const lastTask = tasks[tasks.length - 1]

        const request = await requests.post(API.ONBOARDING_ITEMS_CONFIG, {
            title: task.title,
            rank: lastTask ? lastTask.rank + 1 : 1,
            checklist_config: templateConfig.id,
            assigned_to: { add: task.assignedTo },
        })

        if (request.status === 201) {
            setTasks([...tasks, request.response])
            setTask(initTaskValues) // reset on create
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
                <SuperField as="choice"
                    required
                    label={ t('onboarding_type') }
                    type="boarding_types"
                    value={templateConfig.type}
                    onChange={(e, value) => setTemplateConfig({
                        ...templateConfig,
                        type: value.value
                    })}
                />

                <SuperField as="input"
                    autoFocus
                    required
                    label={ t('title') }
                    value={templateConfig.title}
                    onChange={(e) => setTemplateConfig({
                        ...templateConfig,
                        title: e.target.value
                    })}
                />
            </Form.Group>
            <Form.Group widths="equal">

                <SuperField as="textarea"
                    label={ t('description') }
                    value={templateConfig.description}
                    onChange={(e) => setTemplateConfig({
                        ...templateConfig,
                        description: e.target.value
                    })}
                />
            </Form.Group>
            { templateConfig.id ?
                <>
                    { tasks !== [] &&
                        <Segment loading={loadingTasks}>
                            <TaskTable tasks={tasks} setTasks={setTasks}/>
                            <Form.Group widths={2} style={{ marginTop: "0.5rem" }}>
                                <SuperField as="input"
                                    autoFocus
                                    label={ t('title') }
                                    value={task.title}
                                    onChange={(e) => setTask({
                                        ...task,
                                        title: e.target.value
                                    })}
                                />
                                <SuperField as="choice"
                                    label={ t('assign_person') }
                                    endpoint={API.GET_EMPLOYEES + "?query={id, fullname}"}
                                    multiple
                                    search
                                    text="fullname"
                                    value={task.assignedTo}
                                    onChange={(e, value) => setTask({
                                        ...task,
                                        assignedTo: value.value
                                    })}
                                />
                            </Form.Group>
                            <Form.Field style={{ textAlign: "left" }}>
                                <Button type="button" style={{ lineHeight: '1.2rem' }}  onClick={ () => createTask() } content={ t('add_task') } />
                            </Form.Field>
                        </Segment>
                    }
                    <Divider/>
                    
                    <Form.Field style={{ textAlign: "right" }}>
                        <Button onClick={ () => setMode({ isManage: false, instance: null }) } content={ t('close') } />
                        <Button primary disabled={isProcessing} loading={isProcessing} content={ t('save') } />
                    </Form.Field>
                </>
            : <Form.Field style={{ textAlign: "right" }}>
                <Button onClick={ () => setMode({ isManage: false, instance: null }) } content={ t('close') } />
                <Button disabled={isProcessing || templateConfig.title === "" || templateConfig.type === 0} loading={isProcessing} primary content={ t('save_and_continue') }/>
            </Form.Field>
            }

        </Form>
    )
}

export default TemplateManage