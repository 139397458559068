import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import SuperField from '@components/forms/SuperField'
import NonFieldErrors from '@components/NonFieldErrors'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Divider, Header } from 'semantic-ui-react'
import {
    BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    // BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnRedo,
    BtnStrikeThrough,
    BtnUnderline,
    BtnUndo,
    Separator,
    Toolbar,
    EditorProvider,
    Editor,
} from 'react-simple-wysiwyg'


const B2BContractForm = ({ onClose, view, record, setData, setTotal, setHeader }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [showDescription, setShowDescription] = useState(false)

    // eslint-disable-next-line
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        title: record?.title || "",
        description: record?.description || "",
        valid_from: record?.valid_from || "",
        valid_to: record?.valid_to || "",
        owner: record?.owner?.id || "",
        business_partner: record?.business_partner?.id || "",
        type: record?.type?.id || "",
        category: record?.category?.id || "",
        status: record?.status || "1",
        termination_reason: record?.termination_reason || ""
    })

    const [allowCustomID, setAllowCustomID] = useState(record?.custom_id ? true : false)
    const [customID, setCustomID] = useState(record?.custom_id || '')

    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrors([])
        setIsProcessing(true)

        const data = {
            ...form,
            is_issued: view === 1 ? true : false,
            valid_from: form.valid_from ? form.valid_from : null,
            valid_to: form.valid_to ? form.valid_to : null,
            owner: form.owner ? form.owner : null,
            business_partner: form.business_partner ? form.business_partner : null,
            category: form.category ? form.category : null,
        }

        if (customID !== '' && customID !== record?.custom_id) {
            data['custom_id'] = customID
        }

        if (record === undefined) {
            const request = await requests.post(API.B2B_CONTRACTS + "contracts/", data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                setTotal(prev => prev + 1)
                setData(prev => ([request.response, ...prev]))
                onClose()
            }
        } else {
            const request = await requests.patch(API.B2B_CONTRACTS + "contracts/" + record.id + "/", data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                setData(prev => prev.map(item => {
                    if (record.id === item.id) {
                        item = request.response
                    }
                    return item
                }))
                onClose()
            }
        }

        setIsProcessing(false)
    }

    useEffect(() => {
        if (record !== undefined) {
            setHeader(t('update_b2b_contract'))
        }
        // eslint-disable-next-line
    }, [record])

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors} />

            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    width="12"
                    required
                    autoFocus
                    label={t('title')}
                    value={form.title}
                    error={errors?.title?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({ ...prev, title: value }))}
                />
                <SuperField as="choice"
                    width="4"
                    required
                    type="business_contract_statuses"
                    label={t('status')}
                    clearable={false}
                    value={form.status?.toString?.()}
                    error={errors?.status?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({ ...prev, status: value }))}
                />
            </Form.Group>

            <SuperField
                as="checkbox"
                label={t('set_custom_id_to_b2b_contract')}
                checked={allowCustomID}
                onChange={(e, { value }) => {
                    setAllowCustomID(!allowCustomID)
                    setCustomID('')
                }}
            />

            {allowCustomID && (
                <SuperField
                    required
                    autoFocus={record === undefined}
                    as="input"
                    label={t('custom_id')}
                    value={customID}
                    error={errors?.custom_id?.[0] || false}
                    onChange={(e, { value }) => setCustomID(value)}
                />
            )}

            <Divider />

            { view === 1 
                ? 
                <Form.Group widths="equal">
                    <SuperField
                        as="choice-select"
                        search
                        required
                        label={view === 1 ? t('supplier') : t('account')}
                        value={form.owner}
                        text={(item) => item?.name}
                        endpoint={API.BUSINESS_DETAIL}
                        error={errors?.owner?.[0] || false}
                        additionalFilters="&query={id, name}&is_employer=true"
                        onChange={(e, { value }) => setForm(prev => ({ ...prev, owner: value }))}
                        initialOptions={{
                            attribute: "name",
                            source: record?.owner,
                        }} 
                    />
                    <SuperField
                        as="choice-select"
                        search
                        required
                        label={view === 1 ? t('account') : t('supplier')}
                        value={form.business_partner}
                        endpoint={API.BUSINESS_DETAIL}
                        error={errors?.business_partner?.[0] || false}
                        text={(item) => item?.name}
                        additionalFilters="&filter_by_entities=account,supplier"
                        onChange={(e, { value }) => setForm(prev => ({ ...prev, business_partner: value }))}
                        initialOptions={{
                            attribute: "name",
                            source: record?.business_partner,
                        }}
                    />
                </Form.Group>
                : 
                <Form.Group widths="equal">
                    <SuperField
                        as="choice-select"
                        search
                        required
                        label={view === 1 ? t('account') : t('supplier')}
                        value={form.business_partner}
                        endpoint={API.BUSINESS_DETAIL}
                        error={errors?.business_partner?.[0] || false}
                        text={(item) => item?.name}
                        additionalFilters="&filter_by_entities=account,supplier"
                        onChange={(e, { value }) => setForm(prev => ({ ...prev, business_partner: value }))}
                        initialOptions={{
                            attribute: "name",
                            source: record?.business_partner,
                        }}
                    />
                    <SuperField
                        as="choice-select"
                        search
                        required
                        label={view === 1 ? t('supplier') : t('account')}
                        value={form.owner}
                        text={(item) => item?.name}
                        endpoint={API.BUSINESS_DETAIL}
                        error={errors?.owner?.[0] || false}
                        additionalFilters="&query={id, name}&is_employer=true"
                        onChange={(e, { value }) => setForm(prev => ({ ...prev, owner: value }))}
                        initialOptions={{
                            attribute: "name",
                            source: record?.owner,
                        }} 
                    />
                </Form.Group>
            }
            

            <Form.Group widths="equal">
                <SuperField
                    as="datepicker"
                    required
                    closable
                    label={t('valid_from')}
                    value={form.valid_from}
                    error={errors?.valid_from?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({ ...prev, valid_from: value }))}
                />
                <SuperField
                    as="datepicker"
                    closable
                    label={t('valid_to')}
                    value={form.valid_until}
                    error={errors?.valid_until?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({ ...prev, valid_until: value }))}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="choice-select"
                    search
                    required
                    text="title"
                    value={form.type}
                    label={t('contract_type')}
                    onChange={(e, { value }) => setForm(prev => ({ ...prev, type: value }))}
                    error={errors?.type?.[0] || false}
                    endpoint={API.B2B_CONTRACTS + 'types/'}
                    settings="types/b2b-contract-types"
                    initialOptions={{
                        attribute: "title",
                        source: record?.type
                    }}
                />
                <SuperField as="choice-select"
                    search
                    text="title"
                    value={form.category}
                    label={t('category')}
                    onChange={(e, { value }) => setForm(prev => ({ ...prev, category: value }))}
                    endpoint={API.B2B_CONTRACTS + 'categories/'}
                    error={errors?.category?.[0] || false}
                    settings="types/b2b-contract-categories"
                    initialOptions={{
                        attribute: "title",
                        source: record?.category
                    }}
                />
            </Form.Group>

            <Divider />
            <Header
                as="h5"
                style={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    opacity: '0.8',
                    color: 'var(--primary)',
                }}
                onClick={() => setShowDescription(!showDescription)}
            >
                <Icon
                    name={`chevron-${showDescription ? 'down' : 'forward'}-outline`}
                    style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                />
                <span>{t('b2b_contract_description')}</span>
            </Header>
            {showDescription && (
                <>
                    <Divider/>
                    <Form.Field>
                        <EditorProvider>
                            <Editor
                                containerProps={{
                                    style: {
                                        resize: 'vertical',
                                        width: '100%',
                                        background: 'white',
                                        minHeight: '300px',
                                    },
                                }}
                                value={form.description}
                                onChange={(e) =>
                                    setForm((prev) => ({
                                        ...prev,
                                        description: e.target.value,
                                    }))
                                }
                            >
                                <Toolbar>
                                    <BtnUndo />
                                    <BtnRedo />
                                    <Separator />
                                    <BtnBold />
                                    {/* <BtnItalic /> */}
                                    <BtnUnderline />
                                    <BtnStrikeThrough />
                                    <Separator />
                                    <BtnNumberedList />
                                    <BtnBulletList />
                                    <Separator />
                                    <BtnLink />
                                    <Separator />
                                    <BtnClearFormatting />
                                </Toolbar>
                            </Editor>
                        </EditorProvider>
                    </Form.Field>
                </>
            )}

            <Divider/>

            { [2, "2"].includes(form.status) &&
                <SuperField
                    as="input"
                    label={t('termination_reason')}
                    value={form.termination_reason}
                    error={errors?.termination_reason?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({ ...prev, termination_reason: value }))}
                />
            }

            <Divider/>
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={
                        isProcessing ||
                        form.title === "" ||
                        form.valid_from === "" ||
                        form.owner === "" ||
                        form.business_partner === "" ||
                        form.status === "" ||
                        form.type === ""
                    }
                />
            </Form.Field>
        </Form>
    );
};

export default B2BContractForm