import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { toast } from 'react-semantic-toasts'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// import { setDotSeparator } from '@helpers/functions';
// components
// import Icon from '@components/Icon';
import SuperField from '@components/forms/SuperField'
import SpinnerSegment from '@components/SpinnerSegment'
import ModalCancel from '@components/buttons/ModalCancel'
import { Form, Divider, Button /*, Header*/ } from 'semantic-ui-react'

const ProcessForm = ({ onClose, record, setData, setHeader, setTotal }) => {
    const { t } = useTranslation()

    const [templates, setTemplates] = useState([])
    const [view, setView] = useState(record === undefined ? 1 : 2) // select template view
    const [loading, setLoading] = useState(true)
    const [asNew, setAsNew] = useState(false)

    useEffect(() => {
        if (asNew) {
            setView(2)
        }
    }, [asNew])

    useEffect(() => {
        async function getTemplates() {
            setLoading(true)
            if (record === undefined) {
                // only if its create
                const request = await requests.get(API.ASSETS + 'warehouse_processes/?is_template=true')
                if (request.status === 200) {
                    if (request.response?.results?.length > 0) {
                        setTemplates(request.response?.results || [])
                    } else {
                        setAsNew(true)
                    }
                }
            } else {
                setHeader(t('update_process'))
            }

            setLoading(false)
        }

        getTemplates()
        // eslint-disable-next-line
    }, [])

    return (
        <SpinnerSegment loading={loading}>
            {!loading && (
                <>
                    {view === 1 && (
                        <ProcessTemplateForm
                            asNew={asNew}
                            onClose={onClose}
                            setData={setData}
                            setTotal={setTotal}
                            setAsNew={setAsNew}
                            templates={templates}
                        />
                    )}
                    {view === 2 && (
                        <WarehouseProcessForm onClose={onClose} record={record} setData={setData} setTotal={setTotal} />
                    )}
                </>
            )}
        </SpinnerSegment>
    )
}

const ProcessTemplateForm = ({ templates, setData, setTotal, asNew, setAsNew, onClose }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [template, setTemplate] = useState('')

    const handleSubmit = async () => {
        setProcessing(true)
        const selectedTemplate = templates.find((item) => item.id === template) || undefined
        const request = await requests.post(API.ASSETS + 'warehouse_processes/', {
            title: selectedTemplate?.title || '',
            note: selectedTemplate?.note || '',
            start_date: moment().format('YYYY-MM-DD'),
            is_active: true,
            is_template: false,
            responsible_persons: {
                add: selectedTemplate.responsible_persons.map((item) => item.id),
            },
            // items: selectedTemplate.items.length > 0 ? {
            //     create: selectedTemplate.items.map(item => ({
            //         quantity: item.quantity,
            //         item: item.item.id
            //     }))
            // } : null
        })

        if (request.status === 201) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('warehouse_process_created'),
                animation: 'pulse',
                time: 2000,
            })
            setTotal((prev) => prev + 1)
            setData((prev) => [request.response, ...prev])
            onClose()
        } else {
            console.log(request)
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField
                as="choice"
                search
                required
                clearable
                value={template}
                customOptions={templates?.map((item) => ({
                    key: item.id,
                    value: item.id,
                    text: item.title,
                }))}
                label={t('select_from_existing_templates')}
                onChange={(e, { value }) => setTemplate(value)}
            />

            <SuperField
                as="checkbox"
                label={t('create_new_warehouse_process')}
                checked={asNew}
                onChange={() => setAsNew(!asNew)}
            />
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} disabled={processing} />
                <Button primary loading={processing} content={t('confirm')} disabled={template === '' || processing} />
            </Form.Field>
        </Form>
    )
}

const WarehouseProcessForm = ({ record, setTotal, setData, onClose }) => {
    const { t } = useTranslation()

    // eslint-disable-next-line
    const [loading, setLoading] = useState(false)
    // const [storedItems, setStoredItems] = useState([])
    const [processing, setProcessing] = useState(false)
    const [asTemplate, setAsTemplate] = useState(false)
    const [form, setForm] = useState({
        title: record?.title || '',
        note: record?.note || '',
        responsible_persons: record?.responsible_persons.map((item) => item.id) || [],
        allowed_items: record?.allowed_items.map((item) => item.id) || [],
        allowed_positions: record?.allowed_positions.map((item) => item.id) || [],
        start_date: record?.start_date || moment().format('YYYY-MM-DD'),
    })

    const [catalogueItems, setCatalogueItems] = useState([])
    const [warehousePositions, setwarehousePositions] = useState([])

    useEffect(() => {
        const fetchCatalogueItems = async () => {
            const request = await requests.get(API.CATALOGUE_ITEMS + '?query={id, title, code}&is_service=false')

            if (request.status === 200) {
                let items = []
                for (let i = 0; i < request.response.length; i++) {
                    const item = request.response[i]
                    items.push({
                        key: item.id,
                        value: item.id,
                        text: (item.code ? item.code + ' ' : '') + item.title,
                    })
                }

                setCatalogueItems(items)
            }
        }

        const fetchWarehousePositions = async () => {
            const request = await requests.get(
                API.ASSETS + 'warehouse_positions/?query={id, title, section, row, warehouse{id, title}}'
            )

            if (request.status === 200) {
                let items = []
                for (let i = 0; i < request.response.length; i++) {
                    const item = request.response[i]
                    items.push({
                        key: item.id,
                        value: item.id,
                        text:
                            item.title +
                            (item.section && ' / ' + item.section) +
                            (item.row && ' / ' + item.row) +
                            (item.warehouse ? ' - ' + item.warehouse.title : ''),
                        warehouse: item.warehouse?.id,
                    })
                }

                setwarehousePositions(items)
            }
        }

        fetchCatalogueItems()
        fetchWarehousePositions()
    }, [])

    const isFormValid = () => {
        if (form.title === '') return false

        return true
    }

    const handleSubmit = async () => {
        setProcessing(true)

        if (record?.id === undefined) {
            // create
            const dataset = {
                title: form?.title || '',
                note: form?.note || '',
                start_date: form?.start_date || null,
                is_active: true,
                is_template: false,
                responsible_persons: {
                    add: form.responsible_persons,
                },
                allowed_items: {
                    add: form.allowed_items,
                },
                allowed_positions: {
                    add: form.allowed_positions,
                },
            }

            const request = await requests.post(API.ASSETS + 'warehouse_processes/', dataset)
            if (request.status === 201) {
                if (asTemplate) {
                    await requests.post(API.ASSETS + 'warehouse_processes/', {
                        ...dataset,
                        is_template: true,
                    })
                }

                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('warehouse_process_created'),
                    animation: 'pulse',
                    time: 2000,
                })
                setTotal((prev) => prev + 1)
                setData((prev) => [request.response, ...prev])
                onClose()
            }
        } else {
            // update

            const dataset = {
                title: form?.title || '',
                note: form?.note || '',
                start_date: form.start_date || null,
                is_active: true,
                is_template: false,
                responsible_persons: {
                    remove: record.responsible_persons.map((item) => item.id),
                    add: form.responsible_persons,
                },
                allowed_items: {
                    remove: record.allowed_items.map((item) => item.id),
                    add: form.allowed_items,
                },
                allowed_positions: {
                    remove: record.allowed_positions.map((item) => item.id),
                    add: form.allowed_positions,
                },
            }

            const request = await requests.patch(API.ASSETS + 'warehouse_processes/' + record.id + '/', {
                ...dataset,
                // items: items_query
            })

            if (request.status === 200) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('warehouse_process_updated'),
                    animation: 'pulse',
                    time: 2000,
                })
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === record.id) {
                            item = request.response //requestProcess.response
                        }

                        return item
                    })
                )
                onClose()
            }
        }

        setProcessing(false)
    }

    return (
        <SpinnerSegment loading={loading}>
            {!loading && (
                <Form onSubmit={handleSubmit}>
                    <SuperField
                        as="input"
                        autoFocus
                        required
                        label={t('title')}
                        value={form.title}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, title: value }))}
                    />

                    <SuperField
                        as="choice"
                        search
                        multiple
                        text="fullname_with_titles"
                        label={t('responsible_persons')}
                        value={form.responsible_persons}
                        endpoint={API.EMPLOYEES + '?only_basic_info=true&is_active=true'}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, responsible_persons: value }))}
                    />

                    <SuperField
                        as="choice"
                        search
                        multiple
                        label={t('allowed_catalogue_items')}
                        value={form.allowed_items}
                        customOptions={catalogueItems}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, allowed_items: value }))}
                    />

                    <SuperField
                        as="choice"
                        search
                        multiple
                        label={t('allowed_warehouse_positions')}
                        value={form.allowed_positions}
                        customOptions={warehousePositions}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, allowed_positions: value }))}
                    />

                    <SuperField
                        as="textarea"
                        label={t('note')}
                        value={form.note}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, note: value }))}
                    />

                    <Divider />
                    <Form.Group widths="equal">
                        {record?.id === undefined && (
                            <SuperField
                                as="checkbox"
                                style={{ marginTop: '0.9rem' }}
                                label={t('save_as_template')}
                                checked={asTemplate}
                                onChange={() => setAsTemplate(!asTemplate)}
                            />
                        )}
                        <Form.Field style={{ textAlign: 'right' }}>
                            <ModalCancel onClose={onClose} disabled={processing} />
                            <Button
                                primary
                                loading={processing}
                                content={t('confirm')}
                                disabled={!isFormValid() || processing}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
            )}
        </SpinnerSegment>
    )
}

export default ProcessForm
