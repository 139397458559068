import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useHasPermissions } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import UserAvatar from 'react-user-avatar';
import CanView from '@components/perms/CanView';
import { Grid, Checkbox, Popup } from 'semantic-ui-react';
import ConfirmModal from '@components/modals/ConfrimModal';
import { FlexRow, FlexItem } from '@components/tables/FlexTable'
import PdfUpload from './PdfUpload'
import EditTaskForm from './forms/EditTasksForm'


const TaskRow = ({ task, originalResult, isTerminated, setData, setResult, result, key, instanceID }) => {
     const { t } = useTranslation();
     const [item, setItem] = useState(task)
     // const assignees = item.assigned_to?.map(item => item)
     const [state, setState] = useState(item.is_checked)
     const [edit, setEdit] = useState(false)

     const canManage = useHasPermissions(['onboarding.c_view_all_checklist_instances']);

     const handleCheck = async (id) => {
          const request = await requests.patch(API.ONBOARDING_ITEMS + id + '/', { is_checked: !state })
          if (request.status === 200) {
               let updatedItem = null
               setData(prev => ({
                    ...prev,
                    data: { 
                         ...prev.data,
                         results: prev.data.results.filter(item => {
                              if( item.id === instanceID ){
                                   item.all_checked_items_count = !state === true ? item.all_checked_items_count + 1 : item.all_checked_items_count - 1
                                   item.is_checked = !state
                                   item.checked_by = request.response.checked_by
                                   item.checked_on = request.response.checked_on
     
                                   updatedItem = item
                              }
     
                              return item
                         })
                    }

               }))
               setItem({...item, checked_by: updatedItem.checked_by, checked_on: updatedItem.checked_on})
               setState(request.response.is_checked)
          }

          if (request.status === 400) {
               alert(request.response.non_field_errors)
          }
     }

     const onUpload = async (file) => {
          const formData = new FormData();
          formData.append("attachment", file);

          const request = await requests.put(API.ONBOARDING_ITEMS + item.id + "/attachment/", formData);
          if (request.status === 200) {
               setResult(prevState => prevState.filter(task => {
                    if( task.id === item.id ){
                         task.attachment = request.response.attachment
                    }

                    return task
               }))

               setItem({...item, attachment: request.response.attachment})
          }
     }

     const onDeleteFile = async () => {
          const request = await requests.del(API.ONBOARDING_ITEMS + item.id + "/attachment/")
          if (request.status === 204) {
               setResult(prev => prev.filter(task => {
                    if( task.id === item.id ){
                         task.attachment = null
                    }
                    return task
               }))
               setItem({...item, attachment: null})
          }
     }

     const onUpdate = async (item) => {
          setResult(prev => prev.filter(task => {
               if( task.id === item.id ){
                    task.title = item.title
                    task.assigned_to = item.assigned_to
                    task.assigned_team = item.assigned_team
               }

               return task
          }))

          setItem(item)
          setEdit(false)
     }

     const onDelete = async (id) => {
          const request = await requests.del(API.ONBOARDING_ITEMS  + id + '/')
          if (request.status === 204) {
               setResult(prev => prev.filter(item => item.id !== id))
          }
     }

     return (
          <>
               {!edit
               ?
                    <FlexRow key={key} padding="1rem" background="var(--white)">
                         <FlexItem basis="200%">
                              <Checkbox
                                   style={{ fontWeight: "bold"}}
                                   label={item.title}
                                   checked={state}
                                   onClick={() => canManage ? handleCheck(item.id) : null}
                              />
                              <small style={{ display: "block", marginLeft: "2rem", opacity: ".5" }}>
                                   {state &&
                                        <>
                                             {item.checked_on ? moment(item.checked_on).format('YYYY-MM-DD') : ''}
                                             <span style={{ marginLeft: '0.5rem' }}> by</span>

                                             <span style={{ fontWeight: 'bold' }}> {item.checked_by?.name ? item.checked_by?.name : ''}</span>
                                        </>
                                   }
                              </small>
                         </FlexItem>
                         <FlexItem basis="30%">
                              {item?.attachment ?
                                   <>
                                        <a href={item.attachment} target="_blank" rel="noopener noreferrer" download>
                                             <Icon name='document-outline' style={{ cursor: "pointer", fontSize: "2rem" }}/>
                                        </a>
                                        <CanView permissions={["onboarding.c_view_all_checklist_instances"]}>
                                             <Icon onClick={ () => onDeleteFile() } name='close-outline' style={{ cursor: "pointer", color: "var(--danger)" }}/>
                                        </CanView>
                                   </>

                                   :
                                   <CanView permissions={["onboarding.c_view_all_checklist_instances"]}>
                                        <PdfUpload handleUpload={(file) => onUpload(file)}/>
                                   </CanView>
                              }
                         </FlexItem>
                         <FlexItem>
                              <div style={{ display: "flex", flexDirection: "row"}}>
                                   { item?.assigned_to?.map((assignee, index) => (
                                        <Popup
                                             style={{ cursor: "pointer"}}
                                             position='top center'
                                             trigger={
                                                  <div style={{ cursor: "pointer" }}>
                                                       <UserAvatar
                                                            key={index}
                                                            style={{ paddingLeft: '0rem', fontSize: 30 / 2.5 + "px" }}
                                                            size={30}
                                                            name={assignee?.fullname}
                                                            color='var(--variant5)'
                                                            src={assignee?.profile_picture ? assignee?.profile_picture : ''}
                                                       />
                                                  </div>
                                             }
                                             content={ assignee?.fullname }
                                        />
                                   ))}
                              </div>
                         </FlexItem>
                         {/* <FlexItem>
                              {item.assigned_teams.map((team, index) => (
                                   <Label key={index} style={{ marginBottom: '0.2rem' }}>{team.name}   </Label>
                              ))}
                         </FlexItem> */}
                         <FlexItem>
                              <CanView permissions={["onboarding.c_view_all_checklist_instances"]}>
                                   <FlexRow padding="0" background="transparent">
                                        <FlexItem basis="50%" textAlign="center">
                                             <Icon name='pencil-outline' style={{ cursor: "pointer", marginRight: "2rem" }} onClick={() => setEdit(true)} />
                                             <ConfirmModal
                                                  onConfirm={() => onDelete(item.id)}
                                                  description={ t('delete_item') }
                                                  button={
                                                       <Icon name='close-outline' style={{ cursor: "pointer", color: "var(--danger)"}}/>
                                                  }
                                             />
                                        </FlexItem>
                                   </FlexRow>
                              </CanView>
                         </FlexItem>
                    </FlexRow>
               :
                    <Grid.Row key={item.id} columns={3}>
                         <EditTaskForm onConfirm={(item) => onUpdate(item)} onClose={ () => setEdit(false) } taskEdit={item} />
                    </Grid.Row>
               }
          </>
     )


}

export default TaskRow