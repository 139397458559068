import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
// store
import { API } from '@store/config'
// import { useHasPermission } from '@helpers/hooks'
import { setLocaleLanguage, tzDateTime } from '@helpers/dates'
// components
import Icon from '@components/Icon'
import ListView from '@components/ListView'
import CanView from '@components/perms/CanView'
import DatePresets from '@components/DatePresets'
import SuperField from '@components/forms/SuperField'
import { Header, Form, Label } from 'semantic-ui-react'
import SuperDuperModal from '@components/modals/SuperDuperModal'
// specific comps
import AdvanceForm from '../components/AdvanceForm'
import AddDateOfPayment from '../components/AddDateOfPayment'

const Deductions = () => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    return (
        <CanView
            permissions={['payrolls.c_view_all_deduction_payments', 'payrolls.c_manage_user_deduction_payments']}
            redirect
        >
            <ListView
                as="table"
                allowSearch
                isSoftDeleteAllowed
                endpoint={API.PAYROLLS + 'deduction_payments/'}
                actionsCellWidth="2"
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        name: t('add_deduction'),
                        modalSize: 'tiny',
                        modal: <AdvanceForm isAdvance={false} />,
                        permissions: [
                            'payrolls.c_manage_all_deduction_payments',
                            'payrolls.c_manage_user_deduction_payments',
                        ],
                    },
                    {
                        as: 'modal',
                        type: 'edit',
                        name: t('edit'),
                        modalSize: 'tiny',
                        modal: <AdvanceForm isAdvance={false} />,
                        permissions: [
                            'payrolls.c_manage_all_deduction_payments',
                            'payrolls.c_manage_user_deduction_payments',
                        ],
                    },
                    {
                        name: t('delete'),
                        type: 'delete',
                        as: 'delete',
                        text: t('are_you_sure_that_you_want_to_remove_this_record'),
                        permissions: ['payrolls.c_delete_all_deduction_payments'],
                    },
                ]}
                initialFilters={{
                    date_from: '',
                    date_to: '',
                    profile: '',
                    employer: '',
                    contract_type: '',
                    unit: '',
                    is_approved: '',
                    ordering: ['-created_on'],
                }}
                tableHeaders={[
                    { title: t('employee') },
                    { title: t('amount_sum') },
                    { title: t('billing_period') },
                    { title: t('date_of_payment') },
                    { title: t('payroll_period') },
                    { title: t('note') },
                    { title: t('approval_status') },
                    { title: t('created') },
                ]}
                exports={[
                    {
                        endpoint: API.EXPORTS + 'dynamic_deduction_payments/',
                        permissions: ['payrolls.c_view_all_deduction_payments'],
                        apply_filters: true,
                        type: 'xlsx',
                        filename: 'deduction_payments',
                    },
                ]}
                listAdditionActions={(data, setData, total, setTotal, response) => [
                    {
                        as: 'filter',
                        index: 0,
                        name: t('all'),
                        filters: {
                            is_approved: null,
                        },
                        // count: response?.total_active || 0
                    },
                    {
                        as: 'filter',
                        index: 1,
                        name: t('approved'),
                        filters: {
                            is_approved: true,
                        },
                        // count: response?.total_inactive || 0
                    },
                    {
                        as: 'filter',
                        index: 2,
                        name: t('denied'),
                        filters: {
                            is_approved: false,
                        },
                        // count: response?.total || 0
                    },
                ]}
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <Form.Group widths="equal">
                            <SuperField
                                search
                                as="datepicker"
                                label={t('date_from')}
                                value={filters.date_from}
                                onChange={(e, { value }) => {
                                    setFilters((prev) => ({ ...prev, date_from: value }))
                                }}
                            />
                            <SuperField
                                search
                                as="datepicker"
                                label={t('date_to')}
                                value={filters.date_to}
                                onChange={(e, { value }) => {
                                    setFilters((prev) => ({ ...prev, date_to: value }))
                                }}
                            />
                        </Form.Group>

                        <DatePresets
                            open={true}
                            from={filters.date_from}
                            to={filters.date_to}
                            onSelection={(firstMonthDay, lastMonthDay, year) => {
                                setFilters((prev) => ({
                                    ...prev,
                                    date_from: firstMonthDay,
                                    date_to: lastMonthDay,
                                    year: year,
                                }))
                            }}
                        />

                        <Form.Group widths="equal">
                            <SuperField
                                search
                                as="choice"
                                label={t('employee')}
                                endpoint={API.EMPLOYEES + '?only_basic_info=true&is_active=true'}
                                text="fullname"
                                value={filters.profile}
                                onChange={(e, { value }) => {
                                    setFilters({ ...filters, profile: value })
                                }}
                            />
                            <SuperField
                                as="choice"
                                search
                                label={t('employer')}
                                value={filters.employer}
                                text="name"
                                endpoint={API.BUSINESS_DETAIL + '?query={id, name}&is_employer=true'}
                                onChange={(e, { value }) => setFilters({ ...filters, employer: value })}
                            />
                        </Form.Group>

                        <Form.Group widths="equal">
                            <SuperField
                                label={t('department')}
                                as="choice"
                                endpoint={API.UNITS + '?query={id, name}&only_from_active_company=true'}
                                text="name"
                                value={filters.unit}
                                onChange={(e, { value }) => setFilters({ ...filters, unit: value })}
                            />

                            <SuperField
                                as="choice"
                                label={t('contract_type')}
                                endpoint={API.CONTRACTS_TYPES}
                                text="title"
                                value={filters.contract_type}
                                onChange={(e, { value }) => setFilters({ ...filters, contract_type: value })}
                            />
                        </Form.Group>
                    </>
                )}
                renderCells={(deduction, setData) => [
                    {
                        content: (
                            <>
                                <strong style={{ fontSize: '1rem' }}>
                                    {deduction?.contract?.employee?.fullname_with_titles}
                                </strong>{' '}
                                <br />
                                <span style={{ opacity: '0.8' }}>
                                    {deduction?.contract?.employee?.custom_prefix_and_id || ''}
                                </span>
                            </>
                        ),
                    },
                    { content: deduction.amount + ' ' + deduction.currency },
                    { content: (deduction.billing_month || '-') + '/' + (deduction.billing_year || '-') },
                    {
                        content: deduction.date_of_payment ? (
                            moment(deduction.date_of_payment).format(dateFormat)
                        ) : (
                            <CanView permissions={['payrolls.c_view_all_deduction_payments']}>
                                <SuperDuperModal
                                    size="mini"
                                    header={t('add_date_of_payment')}
                                    trigger={
                                        <Header style={{ padding: 0, cursor: 'pointer', paddingTop: '0.5rem' }} as="h4">
                                            <Icon name="add-outline" style={{ fontSize: '1rem' }} />
                                        </Header>
                                    }
                                    content={
                                        <AddDateOfPayment
                                            data={deduction}
                                            endpoint={'deduction_payments/'}
                                            setData={setData}
                                        />
                                    }
                                />
                            </CanView>
                        ),
                    },
                    {
                        content: (
                            <>
                                {deduction.payroll === null ? (
                                    t('unassigned_period')
                                ) : (
                                    <>
                                        <span style={{ textTransform: 'capitalize' }}>
                                            {moment(deduction.payroll.date_from)
                                                .locale(setLocaleLanguage())
                                                .format('MMMM')}{' '}
                                            {moment(deduction.payroll.date_from).format('YYYY')}
                                        </span>
                                    </>
                                )}
                            </>
                        ),
                    },
                    { content: deduction.note },
                    {
                        content: (
                            <>
                                {deduction.is_approved && <Label color="green">{t('approved')}</Label>}
                                {!deduction.is_approved ? (
                                    deduction.approval !== undefined ? (
                                        deduction.approval?.is_approved === null && (
                                            <Label color="orange">{t('pending')}</Label>
                                        )
                                    ) : (
                                        <Label color="red">{t('denied')}</Label>
                                    )
                                ) : (
                                    ''
                                )}
                            </>
                        ),
                    },
                    {
                        content: (
                            <div>
                                {tzDateTime(deduction.created_on).format(dateFormat + ' HH:mm')} <br />
                                <span>
                                    {t('created_by')} <strong>{deduction.created_by?.name || '--'}</strong>
                                </span>
                            </div>
                        ),
                    },
                ]}
            />
        </CanView>
    )
}

export default Deductions
