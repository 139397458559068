import React from 'react';
import { Popup } from 'semantic-ui-react';

const ShortenText = ({ text, textLength }) => {
    const maxLength = textLength || 20;

    return (
        <Popup
            trigger={
                <span>
                {text?.length > maxLength
                    ? text.slice(0, maxLength) + (text.length <= maxLength ? '' : '...')
                    : text}
                </span>
            }
            content={text}
        />
    );
};

export default ShortenText;
