import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-semantic-toasts'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import moment from 'moment'
import '@store/languages'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { useIsMount, useHasPermission } from '@helpers/hooks'
import { requests } from '@helpers/requests'
import { setLocaleLanguage, tzDateTime, getDates, convertTime } from '@helpers/dates'
// components
import CanView from '@components/perms/CanView'
import Icon from '@components/Icon'
// import UserAvatar from 'react-user-avatar';
import SuperField from '@components/forms/SuperField'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import ConfirmModal from '@components/modals/ConfrimModal'
import {
    Divider,
    Form,
    Button,
    Table,
    Popup,
    Segment,
    Dropdown,
    Modal,
    Label,
    Checkbox,
    Icon as SemanticIcon,
} from 'semantic-ui-react'
// specific components
import CellEvent from '../components/attendance-planner/CellEvent'
import ShiftForm from '../components/attendance-planner/ShiftForm'
import BulkCopyForm from '../components/attendance-planner/BulkCopyForm'
import BulkShiftForm from '../components/attendance-planner/BulkShiftForm'
import PlannerRecordForm from '../components/attendance-planner/PlannerRecordForm'

const areAllObjectsInArray = (dataArray, selectedArray) => {
    if (dataArray.length === 0) return false
    for (const dataObject of dataArray) {
        const dataObjectId = dataObject.id
        const matchingObject = selectedArray.find((selectedObject) => selectedObject.id === dataObjectId)

        if (!matchingObject) {
            return false
        }
    }

    return true
}

const calculateContractDuration = (contract, records) => {
    let duration = 0
    const contractRecords = records.filter(
        (item) => item.contract === contract?.id && item.type === 3 && item.is_deleted === false
    )
    for (let i = 0; i < contractRecords.length; i++) {
        duration += parseFloat(contractRecords[i].duration)
    }

    return parseFloat(duration).toFixed(2).replace('.00', '')
}

const calculateWeekContractDuration = (week, contract, records) => {
    let duration = 0
    const weekRecords = records.filter(
        (item) => item.contract === contract && item.week === week && item.type === 3 && item.is_deleted === false
    )
    for (let i = 0; i < weekRecords.length; i++) {
        duration += parseFloat(weekRecords[i].duration)
    }

    return parseFloat(duration).toFixed(2).replace('.00', '')
}

const calculateWeekDuration = (week, records) => {
    let duration = 0
    const weekRecords = records.filter((item) => item.week === week && item.type === 3 && item.is_deleted === false)
    for (let i = 0; i < weekRecords.length; i++) {
        duration += parseFloat(weekRecords[i].duration)
    }

    return parseFloat(duration).toFixed(2).replace('.00', '')
}

const determinateRecordType = (selectedAction) => {
    let type = 3
    if (selectedAction?.id === 'vacation') type = 1
    if (selectedAction?.id === 'doctor_visit') type = 2
    if (!['vacation', 'doctor_visit', 'remove_record', 'unavailability'].includes(selectedAction?.id)) type = 3

    return type
}

const AttendancePlanner = () => {
    const { t } = useTranslation()
    const isMount = useIsMount()
    // eslint-disable-next-line
    const profile_id = useSelector((state) => state?.user?.profile_id || null)

    const canManageOnlyAssignedEmployees = useHasPermission(
        'attendance.c_can_manage_only_employees_as_responsible_person'
    )

    const excludeSelf = useHasPermission('attendance.c_can_not_manage_self_attendance')

    const [excludeWeekends, setExcludeWeekends] = useState(false)
    const [excludeHolidays, setExcludeHolidays] = useState(false)
    const [showWeekDuration, setShowWeekDuration] = useState(false)
    const [selectedAction, setSelectedAction] = useState(null)
    const [saving, setSaving] = useState(false)
    // eslint-disable-next-line
    const [errors, setErrors] = useState(null)

    const [modal, setModal] = useState({
        open: false,
        selectedDay: null,
        contract: null,
    })

    const [selectedRows, setSelectedRows] = useState([])
    const [records, setRecords] = useState([])
    const [shifts, setShifts] = useState([])
    const [contracts, setContracts] = useState([])
    const [loading, setLoading] = useState(false)
    const [isGenerated, setIsGenerated] = useState(false)
    const [loadingPlanner, setLoadingPlanner] = useState(false)
    const [durationConfig, setDurationConfig] = useState({})
    const [config, setConfig] = useState({
        date_from: moment().startOf('month').format('YYYY-MM-DD'),
        date_to: moment().endOf('month').format('YYYY-MM-DD'),
        view_mode: 'monthly', // view: Monthly, Weekly
        unit: '',
        position_group: '',
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        weeks: [],
        dates: [],
    })

    const [scrollPosition, setScrollPosition] = useState(0)
    const tableContainerRef = useRef(null)

    const fetchShifts = async () => {
        setLoading(true)
        const request = await requests.get(API.ATTENDANCE_BASE + 'shifts/')
        if (request.status === 200) {
            const response = request.response
            let shiftList = []
            for (let i = 0; i < response.length; i++) {
                shiftList.push({
                    ...response[i],
                    time_from: convertTime(response[i].time_from, false),
                    time_to: convertTime(response[i].time_to, false),
                })
            }
            setShifts(shiftList)
        }
        setLoading(false)
    }

    const handleCalendarRender = (holidays, dates, weeks) => {
        setScrollPosition(0)
        const firstDay = moment(config.year + '-' + config.month + '-01').startOf('month')
        const lastDay = moment(config.year + '-' + config.month + '-01').endOf('month')
        // const dates = getDates(firstDay, lastDay)
        // const duplicate_weeks = dates.map(date => moment(date).isoWeek())
        // const unique_weeks = [...new Set(duplicate_weeks)]

        setConfig((prev) => ({
            ...prev,
            date_from: firstDay.format('YYYY-MM-DD'),
            date_to: lastDay.format('YYYY-MM-DD'),
            weeks: weeks,
            highlightedWeeks: weeks.filter((_, index) => index % 2 !== 0),
            dates: dates.map((date) => ({
                date: date,
                dayNumber: moment(date).format('DD'),
                week: moment(date).isoWeek(),
                shortname: moment(date).locale(setLocaleLanguage()).format('ddd'),
                isWeekend: [0, 6].includes(moment(date).day()),
                isSunday: moment(date).day() === 0,
                isSaturday: moment(date).day() === 6,
                holiday: holidays?.[date] || null,
            })),
        }))
    }

    useEffect(() => {
        fetchShifts()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const handleKeyDown = (event) => {
            switch (event.key) {
                case 'r':
                    setSelectedAction({
                        id: 'remove_record',
                        icon: 'trash-outline',
                        displayName: t('remove_record'),
                    })
                    break
                case 'd':
                    setSelectedAction({
                        id: 'doctor_visit',
                        icon: 'add-outline',
                        displayName: t('doctor_visit'),
                    })
                    break
                case 'v':
                    setSelectedAction({
                        id: 'vacation',
                        icon: 'add-outline',
                        displayName: t('vacation'),
                    })
                    break
                case 'Escape':
                    if (selectedAction?.id !== null) setSelectedAction(null)
                    break
                default:
                    break
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
        // eslint-disable-next-line
    }, [])

    const handleScroll = () => {
        setScrollPosition(tableContainerRef?.current?.scrollTop || 0)
    }

    useEffect(() => {
        if (tableContainerRef?.current) {
            tableContainerRef.current.addEventListener('scroll', handleScroll)
        }

        return () => {
            // eslint-disable-next-line
            if (tableContainerRef?.current) {
                // eslint-disable-next-line
                tableContainerRef.current.removeEventListener('scroll', handleScroll)
            }
        }

        // eslint-disable-next-line
    }, [tableContainerRef, records])

    useEffect(() => {
        if (!isMount && !loadingPlanner) {
            performCalculations(records, config.weeks, contracts)
        }

        // eslint-disable-next-line
    }, [records])

    const monthList = [
        { key: 1, value: '01', text: t('january') },
        { key: 2, value: '02', text: t('february') },
        { key: 3, value: '03', text: t('march') },
        { key: 4, value: '04', text: t('april') },
        { key: 5, value: '05', text: t('may') },
        { key: 6, value: '06', text: t('june') },
        { key: 7, value: '07', text: t('july') },
        { key: 8, value: '08', text: t('august') },
        { key: 9, value: '09', text: t('september') },
        { key: 10, value: '10', text: t('october') },
        { key: 11, value: '11', text: t('november') },
        { key: 12, value: '12', text: t('december') },
    ]

    const calculateTime = (start, end, as) => {
        let time = 0
        as = as || 'hours'
        let start_time = start ? tzDateTime(moment(start).format('YYYY-MM-DD HH:mm'), true) : null
        let end_time = end ? tzDateTime(moment(end).format('YYYY-MM-DD HH:mm'), true) : null

        if (start_time !== null && end_time !== null) {
            // Check if end_time is higher, add 1 day if true
            if (end_time.isBefore(start_time)) {
                end_time.add(1, 'day')
            }

            // Calculate the difference
            if (as === 'minutes') {
                time = moment.duration(end_time.diff(start_time)).asMinutes()
            } else {
                time = moment.duration(end_time.diff(start_time)).asHours()
            }
        }

        return as === 'hours' ? time.toFixed(2) : time
    }

    const handleBreakDeduction = (time_from, time_to, shift) => {
        let duration = calculateTime(time_from, time_to, 'minutes')
        if (shift?.pause_length !== '' && parseFloat(shift?.pause_length) > 0) {
            duration = duration - parseFloat(shift.pause_length)
        }

        if (shift?.second_pause_length !== '' && parseFloat(shift?.second_pause_length) > 0) {
            duration = duration - parseFloat(shift.second_pause_length)
        }

        return duration > 0 ? parseFloat(duration / 60) : 0
    }

    const performCalculations = (records, weeks, contracts) => {
        let weekDurations = []
        // calculate week duration
        if (weeks.length > 0) {
            for (let i = 0; i < weeks.length; i++) {
                weekDurations.push({
                    id: weeks[i],
                    duration: calculateWeekDuration(weeks[i], records),
                })
            }
        }

        let contractDurations = []
        if (contracts.length > 0) {
            // calculate contract duration
            for (let i = 0; i < contracts.length; i++) {
                let weekContractDurations = []
                for (let y = 0; y < weeks.length; y++) {
                    weekContractDurations.push({
                        id: weeks[y],
                        duration: calculateWeekContractDuration(weeks[y], contracts[i].id, records),
                    })
                }
                contractDurations.push({
                    id: contracts[i].id,
                    weekDurations: weekContractDurations,
                    duration: calculateContractDuration(contracts[i], records),
                })
            }
        }

        setDurationConfig({
            weeks: weekDurations,
            contracts: contractDurations,
        })
    }

    const handleSubmit = async () => {
        setLoadingPlanner(true)
        setIsGenerated(false)
        setRecords([])
        const firstDay = moment(config.year + '-' + config.month + '-01')
            .startOf('month')
            .format('YYYY-MM-DD')
        const lastDay = moment(config.year + '-' + config.month + '-01')
            .endOf('month')
            .format('YYYY-MM-DD')
        const dates = getDates(moment(firstDay), moment(lastDay))
        const duplicate_weeks = dates.map((date) => moment(date).isoWeek())
        const unique_weeks = [...new Set(duplicate_weeks)]

        let holidayList = []
        const recordsList = []
        const request = await requests.get(API.COMMON + 'holidays/?country=SK&year=' + config.year)
        if (request.status === 200) holidayList = request.response

        let positionGroupQuery = ''
        if (config.position_group) positionGroupQuery = '&position_group=' + config.position_group

        // fetch contracts
        const requestContracts = await requests.get(
            API.CONTRACTS +
                '?only_basic_info=true&status=1&status=3&exclude_freelancer=true' +
                (canManageOnlyAssignedEmployees ? '&unit=' : '&descendant_units=') +
                config.unit +
                positionGroupQuery
        )
        if (requestContracts.status === 200) {
            const response = requestContracts.response?.results || []
            const tmpArray = []
            for (let i = 0; i < response.length; i++) {
                tmpArray.push({
                    id: response[i].id,
                    shortname: response[i].employee?.fullname || '--',
                    fullname: response[i].employee?.fullname || '--',
                    profile_id: response[i].employee?.id || null,
                    profile_picture: response[i].employee?.profile_picture || null,
                    position: response[i].work_position.title,
                    custom_prefix_and_id: response[i].employee?.custom_prefix_and_id || '',
                    fond: response[i].fond || '',
                    fond_per_unit: response[i].fond_per_unit || '',
                    fond_per_unit_display: response[i].fond_per_unit_display || '',
                })
            }

            // fetch records
            let dateRange = '&date_after=' + firstDay + '&date_before=' + lastDay
            let contractList = ''
            for (let i = 0; i < response.length; i++) {
                contractList += '&contracts=' + response[i].id
            }

            const requestRecords = await requests.get(
                API.ATTENDANCE_BASE +
                    'planner_records/?query={id, employee{id}, contract{id}, shift, type, type_display, date, datetime_from, datetime_to}' +
                    dateRange +
                    contractList
            )
            if (requestRecords.status === 200) {
                const recordsResponse = requestRecords.response
                for (let i = 0; i < recordsResponse.length; i++) {
                    if (excludeSelf && profile_id && response?.[i]?.employee?.id === profile_id) {
                        // pass
                    } else {
                        let shift = recordsResponse[i].shift
                        const duration = handleBreakDeduction(
                            recordsResponse[i].datetime_from,
                            recordsResponse[i].datetime_to,
                            shift
                        )
                        recordsList.push({
                            id: recordsResponse[i].id,
                            is_deleted: false,
                            employee: recordsResponse[i].employee?.id,
                            contract: recordsResponse[i].contract?.id,
                            date: recordsResponse[i].date,
                            week: moment(recordsResponse[i].date).isoWeek(),
                            type: parseInt(recordsResponse[i].type),
                            type_display: recordsResponse[i].type_display,
                            shift: recordsResponse[i].shift?.id || null,
                            color: recordsResponse[i].shift?.color || '#000000',
                            time_from: recordsResponse[i].datetime_from
                                ? tzDateTime(recordsResponse[i].datetime_from).format('HH:mm')
                                : null,
                            time_to: recordsResponse[i].datetime_to
                                ? tzDateTime(recordsResponse[i].datetime_to).format('HH:mm')
                                : null,
                            duration: parseFloat(duration),
                            duration_display: duration.toString().replace('.00', ''),
                        })
                    }
                }
            }

            setContracts(tmpArray)
            setRecords(recordsList)
            performCalculations(recordsList, unique_weeks, tmpArray)
        }

        handleCalendarRender(holidayList, dates, unique_weeks)
        setLoadingPlanner(false)
        setIsGenerated(true)
    }

    const handleRowDelete = () => {
        const rows = selectedRows
        const existingRecords = records
        const toKeep = existingRecords.filter((item) => !rows.some((row) => row.id === item.contract))
        const updatedRecords = existingRecords.map((item) => {
            if (rows.some((row) => row.id === item.contract)) {
                return { ...item, is_deleted: true }
            }
            return item
        })
        const remainingRecords = updatedRecords.filter((item) => !toKeep.includes(item))
        const toKeepAfterUpdate = remainingRecords.filter((item) => item.id !== 0)

        setRecords([...toKeep, ...toKeepAfterUpdate])
        setSelectedRows([])
    }

    const excludeDates = (dates) => {
        if (excludeWeekends) {
            dates = dates.filter((item) => !item.isWeekend)
        }

        if (excludeHolidays) {
            dates = dates.filter((item) => item.holiday === null)
        }

        return dates
    }

    const handlePlannerSave = async () => {
        setSaving(true)
        setErrors(null)

        const request = await requests.post(
            API.ATTENDANCE_BASE + 'planner_records/bulk/',
            records.map((item) => {
                const defaultFormat = 'YYYY-MM-DD HH:mm'
                const datetime_from = item.time_from ? moment(item.date + ' ' + item.time_from, defaultFormat) : null
                const datetime_to = item.time_from ? moment(item.date + ' ' + item.time_to, defaultFormat) : null

                if (datetime_to && datetime_from) {
                    if (datetime_to.isBefore(datetime_from)) {
                        datetime_to.add(1, 'day')
                    }
                }

                return {
                    id: item.id > 0 ? item.id : null,
                    to_delete: item.is_deleted,
                    date: item.date,
                    type: parseInt(item.type),
                    is_approved: true,
                    employee: item.employee,
                    contract: item.contract,
                    shift: item.shift,

                    datetime_from: datetime_from
                        ? tzDateTime(datetime_from.format(defaultFormat), true).format(defaultFormat)
                        : null,
                    datetime_to: datetime_to
                        ? tzDateTime(datetime_to.format(defaultFormat), true).format(defaultFormat)
                        : null,
                }
            })
        )

        if (request.status === 400) setErrors(request.response)
        if (request.status === 201) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('attendance_plan_saved'),
                animation: 'pulse',
                time: 2000,
            })
            setRecords((prev) =>
                prev.filter((item) => {
                    const createdRecord = request.response.find(
                        (newRecord) =>
                            newRecord.employee?.id === item.employee &&
                            newRecord.contract?.id === item.contract &&
                            newRecord.date === item.date
                    )
                    if (
                        createdRecord &&
                        createdRecord.employee?.id === item.employee &&
                        createdRecord.contract?.id === item.contract &&
                        createdRecord.date === item.date
                    ) {
                        item.id = createdRecord?.id || 0
                    }
                    return item
                })
            )
        }

        setSaving(false)
    }

    const isLastDayOfWeek = (day) => {
        let datesOfWeek = config.dates.filter((item) => item.week === day.week)
        if (excludeWeekends) datesOfWeek = datesOfWeek.filter((item) => !item.isWeekend)
        if (excludeHolidays) datesOfWeek = datesOfWeek.filter((item) => item.holiday === null)
        let isLast = false

        if (datesOfWeek.length > 0) {
            isLast = datesOfWeek[datesOfWeek.length - 1]?.date === day.date
        }

        return isLast
    }

    return (
        <CanView permissions={['attendance.c_manage_all_planner_records']} redirect>
            <Form>
                <Form.Group widths={6}>
                    <SuperField
                        as="yearpicker"
                        label={t('year')}
                        value={config.year}
                        width="2"
                        dateFormat="YYYY"
                        onChange={(e, { value }) => setConfig((prev) => ({ ...prev, year: value }))}
                    />
                    <SuperField
                        as="choice"
                        search
                        width="2"
                        label={t('month')}
                        clearable={false}
                        value={config.month}
                        customOptions={monthList}
                        onChange={(e, { value }) => setConfig((prev) => ({ ...prev, month: value }))}
                    />
                    
                    <SuperField
                        as="choice-select"
                        search
                        required
                        label={t('department')}
                        clearable={false}
                        value={config.unit}
                        endpoint={API.UNITS}
                        text="name"
                        additionalFilters={'&query={id, name}&only_basic_info=true&only_from_active_company=true' + (canManageOnlyAssignedEmployees && profile_id ? '&responsible_persons=' + profile_id : '')}
                        onChange={(e, { value }) => setConfig((prev) => ({ ...prev, unit: value }))}
                    />
                    <SuperField
                        as="choice-select"
                        search
                        text="title"
                        label={t('work_position_group')}
                        value={config.position_group}
                        endpoint={API.POSITIONS_GROUPS}
                        additionalFilters={"&query={id, title}&only_basic_info=true"}
                        onChange={(e, { value }) => setConfig((prev) => ({ ...prev, position_group: value }))}
                    />
                    
                    <Form.Field>
                        <Button
                            primary
                            type="button"
                            onClick={() => handleSubmit()}
                            style={{ marginTop: '1.7rem' }}
                            disabled={
                                config.unit === '' || config.month === '' || config.year === '' || loading || saving
                            }
                        >
                            {t('confirm')}
                        </Button>
                    </Form.Field>
                </Form.Group>
            </Form>
            <Divider />

            <Segment
                loading={loadingPlanner || saving}
                style={{
                    padding: 0,
                    background: 'transparent',
                    boxShadow: 'none',
                    border: 'none',
                    marginBottom: '1rem',
                    marginTop: '1rem',
                }}
            >
                {loadingPlanner && (
                    <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '6rem' }}>
                        {t('loading_attendance_planner')}
                    </p>
                )}

                {saving && (
                    <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '6rem' }}>
                        {t('saving_attendance_planner_this_may_take_a_while')}
                    </p>
                )}

                {!loadingPlanner && !isGenerated && !saving && (
                    <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '1rem', fontWeight: 'bold' }}>
                        {t('select_options_to_generate_monthly_attendance_planner')}
                    </p>
                )}
                {!loadingPlanner && !saving && isGenerated && (
                    <Form>
                        <Form.Group>
                            <Form.Field>
                                <Dropdown
                                    as={Button}
                                    basic
                                    simple
                                    size="tiny"
                                    text={t('bulk_actions')}
                                    disabled={selectedRows.length === 0}
                                    style={{ boxShadow: 'none' }}
                                >
                                    <Dropdown.Menu direction="right">
                                        <SuperDuperModal
                                            size="tiny"
                                            centered={false}
                                            header={t('add_shift_schedule')}
                                            trigger={
                                                <Dropdown.Item>
                                                    <Icon name="add-outline" style={{ marginRight: '0.5rem' }} />
                                                    <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                        {t('add_shift_schedule')}
                                                    </span>
                                                </Dropdown.Item>
                                            }
                                            content={
                                                <BulkShiftForm
                                                    config={config}
                                                    shifts={shifts}
                                                    records={records}
                                                    setRecords={setRecords}
                                                    selectedRows={selectedRows}
                                                    setSelectedRows={setSelectedRows}
                                                />
                                            }
                                        />

                                        <SuperDuperModal
                                            size="tiny"
                                            centered={false}
                                            header={t('copy_from_existing_plan')}
                                            trigger={
                                                <Dropdown.Item>
                                                    <Icon name="copy-outline" style={{ marginRight: '0.5rem' }} />
                                                    <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                        {t('copy_from_existing_plan')}
                                                    </span>
                                                </Dropdown.Item>
                                            }
                                            content={
                                                <BulkCopyForm
                                                    config={config}
                                                    records={records}
                                                    contracts={contracts}
                                                    setRecords={setRecords}
                                                    selectedRows={selectedRows}
                                                    setSelectedRows={setSelectedRows}
                                                />
                                            }
                                        />

                                        <Dropdown.Divider />

                                        <ConfirmModal
                                            description={t(
                                                'this_action_will_remove_all_records_from_selected_rows_are_you_sure'
                                            )}
                                            onConfirm={() => handleRowDelete()}
                                            button={
                                                <Dropdown.Item>
                                                    <Icon
                                                        name="trash-outline"
                                                        style={{ marginRight: '0.5rem', color: 'var(--danger)' }}
                                                    />
                                                    <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                        {t('remove_records_from_selected_row')}
                                                    </span>
                                                </Dropdown.Item>
                                            }
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Field>
                            <Form.Field style={{ marginTop: '0.5rem' }}>
                                <span style={{ marginRight: '0.5rem' }}>{t('quick_action')}: </span>
                                <Dropdown
                                    size="tiny"
                                    text={
                                        <span style={{ fontWeight: 'bold' }}>
                                            {selectedAction === null ? (
                                                t('nothing_selected')
                                            ) : (
                                                <span>
                                                    <Icon
                                                        name={selectedAction?.icon}
                                                        style={{
                                                            marginRight: '0.3rem',
                                                            position: 'relative',
                                                            bottom: '-0.1rem',
                                                        }}
                                                    />
                                                    {selectedAction?.displayName}
                                                </span>
                                            )}
                                        </span>
                                    }
                                >
                                    <Dropdown.Menu direction="right" style={{ minWidth: '250px' }}>
                                        <Dropdown.Item
                                            active={selectedAction === 'remove_record'}
                                            onClick={() =>
                                                setSelectedAction({
                                                    id: 'remove_record',
                                                    icon: 'trash-outline',
                                                    displayName: t('remove_record'),
                                                })
                                            }
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div>
                                                    <Icon
                                                        name="trash-outline"
                                                        style={{
                                                            marginRight: '0.3rem',
                                                            position: 'relative',
                                                            bottom: '-0.1rem',
                                                            color: 'var(--danger)',
                                                        }}
                                                    />{' '}
                                                    {t('remove_record')}
                                                </div>
                                                <Popup
                                                    position="right center"
                                                    trigger={
                                                        <Label
                                                            basic
                                                            size="tiny"
                                                            style={{
                                                                opacity: 0.9,
                                                                marginLeft: '1rem',
                                                                position: 'relative',
                                                                top: '0.1rem',
                                                            }}
                                                        >
                                                            R
                                                        </Label>
                                                    }
                                                    content={t('keyboard_shortcut') + ': R'}
                                                />
                                            </div>
                                        </Dropdown.Item>

                                        <Dropdown.Header content={t('presets')} />
                                        <Dropdown.Item
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                            }}
                                            onClick={() =>
                                                setSelectedAction({
                                                    id: 'doctor_visit',
                                                    icon: 'add-outline',
                                                    displayName: t('doctor_visit'),
                                                })
                                            }
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div>
                                                    <Icon
                                                        name="add-outline"
                                                        style={{
                                                            marginRight: '0.3rem',
                                                            position: 'relative',
                                                            bottom: '-0.1rem',
                                                        }}
                                                    />{' '}
                                                    {t('doctor_visit')}
                                                </div>
                                                <Popup
                                                    position="right center"
                                                    trigger={
                                                        <Label
                                                            basic
                                                            size="tiny"
                                                            style={{
                                                                opacity: 0.9,
                                                                marginLeft: '1rem',
                                                                position: 'relative',
                                                                top: '0.1rem',
                                                            }}
                                                        >
                                                            D
                                                        </Label>
                                                    }
                                                    content={t('keyboard_shortcut') + ': D'}
                                                />
                                            </div>
                                            <SemanticIcon name="circle" style={{ marginRight: 0, color: 'orange' }} />
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                            }}
                                            onClick={() =>
                                                setSelectedAction({
                                                    id: 'vacation',
                                                    icon: 'add-outline',
                                                    displayName: t('vacation'),
                                                })
                                            }
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div>
                                                    <Icon
                                                        name="add-outline"
                                                        style={{
                                                            marginRight: '0.3rem',
                                                            position: 'relative',
                                                            bottom: '-0.1rem',
                                                        }}
                                                    />{' '}
                                                    {t('vacation')}
                                                </div>
                                                <Popup
                                                    position="right center"
                                                    trigger={
                                                        <Label
                                                            basic
                                                            size="tiny"
                                                            style={{
                                                                opacity: 0.9,
                                                                marginLeft: '1rem',
                                                                position: 'relative',
                                                                top: '0.1rem',
                                                            }}
                                                        >
                                                            V
                                                        </Label>
                                                    }
                                                    content={t('keyboard_shortcut') + ': V'}
                                                />
                                            </div>
                                            <SemanticIcon name="circle" style={{ marginRight: 0, color: 'red' }} />
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item
                                            style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                                            disabled
                                            // onClick={() => setSelectedAction({
                                            //     id: "unavailability",
                                            //     icon: "add-outline",
                                            //     displayName: t('unavailability'),
                                            // })}
                                        >
                                            <div>
                                                <Icon name="add-outline" style={{ marginRight: "0.3rem", position: "relative", bottom: "-0.1rem" }}/> {t('unavailability')}
                                            </div>
                                            <SemanticIcon name="circle" style={{ marginRight: 0, color: "grey" }}/>
                                        </Dropdown.Item> */}

                                        <Dropdown.Header
                                            content={
                                                <>
                                                    {t('work_schedule')}
                                                    <CanView permissions={['attendance.c_manage_shifts']}>
                                                        <Popup
                                                            position="right center"
                                                            content={t('go_to_work_shift_management')}
                                                            trigger={
                                                                <Link
                                                                    to={routes.SETTINGS + 'work-shifts'}
                                                                    target="_blank"
                                                                >
                                                                    <Icon
                                                                        name="settings-outline"
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            color: 'var(--dark)',
                                                                            opacity: '0.9',
                                                                            marginLeft: '0.5rem',
                                                                        }}
                                                                    />
                                                                </Link>
                                                            }
                                                        />
                                                    </CanView>
                                                </>
                                            }
                                        />
                                        {shifts.length === 0 && (
                                            <p style={{ fontWeight: 'bold', paddingLeft: '1rem', opacity: '0.7' }}>
                                                {t('no_shifts_available')}
                                            </p>
                                        )}
                                        {shifts.map((item) => (
                                            <Dropdown.Item
                                                key={item.id}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                }}
                                                onClick={() =>
                                                    setSelectedAction({
                                                        id: item.id,
                                                        icon: 'add-outline',
                                                        displayName: item.title,
                                                    })
                                                }
                                            >
                                                <div>
                                                    <Icon
                                                        name="add-outline"
                                                        style={{
                                                            marginRight: '0.3rem',
                                                            position: 'relative',
                                                            bottom: '-0.1rem',
                                                        }}
                                                    />{' '}
                                                    {item.title}
                                                </div>
                                                <SemanticIcon
                                                    name="circle"
                                                    style={{ marginRight: 0, color: item.color }}
                                                />
                                            </Dropdown.Item>
                                        ))}
                                        <Dropdown.Divider />
                                        <CanView permissions={['attendance.c_manage_shifts']}>
                                            <SuperDuperModal
                                                size="tiny"
                                                header={t('add_shift')}
                                                trigger={
                                                    <Dropdown.Item
                                                        style={{
                                                            background: 'var(--primary)',
                                                            color: 'var(--white)',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        <Icon
                                                            name="add-outline"
                                                            style={{
                                                                marginRight: '0.3rem',
                                                                position: 'relative',
                                                                bottom: '-0.1rem',
                                                            }}
                                                        />{' '}
                                                        {t('add_new_shift')}
                                                    </Dropdown.Item>
                                                }
                                                content={
                                                    <ShiftForm
                                                        setShifts={setShifts}
                                                        setSelectedAction={setSelectedAction}
                                                    />
                                                }
                                            />
                                        </CanView>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {selectedAction !== null && (
                                    <Popup
                                        position="top center"
                                        trigger={
                                            <Icon
                                                name="close"
                                                style={{
                                                    position: 'relative',
                                                    marginBottom: '-0.2rem',
                                                    marginLeft: '0.5rem',
                                                    color: 'var(--danger)',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => setSelectedAction(null)}
                                            />
                                        }
                                        content={t('esc_to_unselect_action')}
                                    />
                                )}
                            </Form.Field>
                            <Form.Field
                                style={{
                                    marginTop: '0.5rem',
                                    marginLeft: '0',
                                    marginRight: '1rem',
                                    borderRight: '1px solid var(--light-grey)',
                                }}
                            />
                            <SuperField
                                as="checkbox"
                                style={{ marginTop: '0.5rem' }}
                                label={t('exclude_weekends')}
                                checked={excludeWeekends}
                                onChange={() => setExcludeWeekends(!excludeWeekends)}
                            />
                            <SuperField
                                as="checkbox"
                                style={{ marginTop: '0.5rem' }}
                                label={t('exclude_holidays')}
                                checked={excludeHolidays}
                                onChange={() => setExcludeHolidays(!excludeHolidays)}
                            />
                            <SuperField
                                as="checkbox"
                                style={{ marginTop: '0.5rem' }}
                                label={t('show_weekly_hours')}
                                checked={showWeekDuration}
                                onChange={() => setShowWeekDuration(!showWeekDuration)}
                            />
                            <Form.Field
                                style={{
                                    marginTop: '0.5rem',
                                    marginLeft: '0',
                                    marginRight: '1rem',
                                    borderRight: '1px solid var(--light-grey)',
                                }}
                            />
                            <Form.Field>
                                <Button
                                    onClick={() => handlePlannerSave()}
                                    primary
                                    type="button"
                                    size="tiny"
                                    content={t('save_changes')}
                                    style={{ position: 'relative', top: '0.2rem' }}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                )}

                <div className="table-container-wrapper"></div>
                <div
                    className="attendance-planner-table-container"
                    ref={tableContainerRef}
                    style={{ overflow: loadingPlanner ? 'hidden' : 'auto', height: `${65}vh` }}
                >
                    {!loadingPlanner && !saving && isGenerated && (
                        <Table
                            celled
                            singleLine
                            unstackable
                            compact
                            className="attendance-planner-table"
                            style={{ width: '100%' }}
                        >
                            <Table.Header
                                style={{
                                    transform: `translateY(${scrollPosition}px)`,
                                    transition: 'transform 0.2s ease-in-out',
                                    position: 'relative',
                                    zIndex: 4,
                                }}
                            >
                                <Table.Row className="header-row" style={{ textAlign: 'center' }}>
                                    <Table.HeaderCell
                                        rowSpan="2"
                                        verticalAlign="bottom"
                                        style={{ textAlign: 'center', backgroung: 'transparent' }}
                                    >
                                        <Checkbox
                                            checked={areAllObjectsInArray(contracts, selectedRows) ? true : false}
                                            onChange={() => {
                                                if (areAllObjectsInArray(contracts, selectedRows)) {
                                                    setSelectedRows([])
                                                } else {
                                                    let toAdd = []
                                                    for (let i = 0; i < contracts.length; i++) {
                                                        if (!selectedRows.find((item) => item.id === contracts[i].id)) {
                                                            toAdd.push(contracts[i])
                                                        }
                                                    }
                                                    setSelectedRows((prev) => [...prev, ...toAdd])
                                                }
                                            }}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        verticalAlign="bottom"
                                        className="sticky-column-left"
                                        rowSpan="2"
                                        style={{
                                            textAlign: 'left',
                                            borderRight: '1px solid #e2e3e5',
                                            backgroung: 'transparent',
                                        }}
                                    >
                                        {t('employee')}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        verticalAlign="bottom"
                                        rowSpan="2"
                                        style={{
                                            textAlign: 'left',
                                            borderRight: '1px solid #e2e3e5',
                                            backgroung: 'transparent',
                                        }}
                                    >
                                        {t('fond')}
                                    </Table.HeaderCell>
                                    {config.weeks.map((week) => (
                                        <Table.HeaderCell
                                            key={week}
                                            colSpan={
                                                (showWeekDuration ? 1 : 0) +
                                                    excludeDates(config.dates).filter((day) => day.week === week)
                                                        ?.length || 1
                                            }
                                            style={{
                                                paddingTop: '0.2rem',
                                                paddingBottom: '0.2rem',
                                                background: config.highlightedWeeks.includes(week)
                                                    ? 'var(--light)'
                                                    : 'var(--white)',
                                                // transform: `translateY(${scrollPosition}px)`,
                                                // transition: 'transform 0.2s ease-in-out'
                                            }}
                                        >
                                            {t('week')} {week} <br />
                                            <small style={{ opacity: '0.7' }}>
                                                {' '}
                                                {durationConfig?.weeks?.find((item) => item.id === week)?.duration ||
                                                    0}{' '}
                                                <span style={{ textTransform: 'lowercase' }}>
                                                    {t('hours_shortcut')}.
                                                </span>{' '}
                                            </small>
                                        </Table.HeaderCell>
                                    ))}
                                    <Table.HeaderCell
                                        className="sticky-column-right"
                                        rowSpan="2"
                                        style={{
                                            textAlign: 'center',
                                            borderRight: '1px solid #e2e3e5',
                                            backgroung: 'transparent',
                                        }}
                                    >
                                        {t('total_hours')}
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row className="header-row" style={{ textAlign: 'center' }}>
                                    {excludeDates(config.dates).map((day, index) => (
                                        <Fragment key={index}>
                                            <Table.HeaderCell
                                                style={{
                                                    background: config.highlightedWeeks.includes(day.week)
                                                        ? 'var(--light)'
                                                        : 'var(--white)',
                                                    color:
                                                        day.holiday !== null
                                                            ? 'orange'
                                                            : day.isWeekend
                                                            ? 'var(--danger)'
                                                            : 'var(--dark)',
                                                    paddingTop: '0.2rem',
                                                    paddingBottom: '0.2rem',
                                                    paddingRight: '0.2rem',
                                                    paddingLeft: '0.2rem',
                                                    // transform: `translateY(${scrollPosition}px)`,
                                                    // transition: 'transform 0.2s ease-in-out'
                                                }}
                                            >
                                                {day.holiday === null ? (
                                                    day.isWeekend ? (
                                                        <Popup
                                                            position="top center"
                                                            trigger={
                                                                <div>
                                                                    {day.dayNumber}. <br />
                                                                    <small
                                                                        style={{
                                                                            opacity: '0.8',
                                                                            textTransform: 'capitalize',
                                                                        }}
                                                                    >
                                                                        {day.shortname}.
                                                                    </small>
                                                                </div>
                                                            }
                                                            content={
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <span style={{ textTransform: 'capitalize' }}>
                                                                        {moment(day.date)
                                                                            .locale(setLocaleLanguage())
                                                                            .format('dddd')}
                                                                    </span>{' '}
                                                                    <br />
                                                                    <small>
                                                                        <strong>{t('weekend')}</strong>
                                                                    </small>
                                                                </div>
                                                            }
                                                        />
                                                    ) : (
                                                        <div>
                                                            {day.dayNumber}. <br />
                                                            <small
                                                                style={{ opacity: '0.8', textTransform: 'capitalize' }}
                                                            >
                                                                {day.shortname}.
                                                            </small>
                                                        </div>
                                                    )
                                                ) : (
                                                    <Popup
                                                        position="top center"
                                                        trigger={
                                                            <div>
                                                                {day.dayNumber}. <br />
                                                                <small
                                                                    style={{
                                                                        opacity: '0.8',
                                                                        textTransform: 'capitalize',
                                                                    }}
                                                                >
                                                                    {day.shortname}.
                                                                </small>{' '}
                                                                <br />
                                                            </div>
                                                        }
                                                        content={
                                                            <div style={{ textAlign: 'center' }}>
                                                                {day.holiday} <br />
                                                                <small>
                                                                    <strong>{t('holiday')}</strong>
                                                                </small>
                                                            </div>
                                                        }
                                                    />
                                                )}
                                            </Table.HeaderCell>
                                            {showWeekDuration && isLastDayOfWeek(day) && (
                                                <Table.HeaderCell
                                                    style={{
                                                        background: config.highlightedWeeks.includes(day.week)
                                                            ? 'var(--light)'
                                                            : 'var(--white)',
                                                        paddingTop: '0.2rem',
                                                        paddingBottom: '0.2rem',
                                                        paddingRight: '0.2rem',
                                                        paddingLeft: '0.2rem',
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: '0.8rem',
                                                            wordWrap: 'break-word',
                                                            whiteSpace: 'pre-wrap',
                                                        }}
                                                    >
                                                        {t('weekly_hours')}
                                                    </span>
                                                </Table.HeaderCell>
                                            )}
                                        </Fragment>
                                    ))}
                                </Table.Row>
                            </Table.Header>

                            {/* Table Content */}
                            <Table.Body>
                                {contracts.length === 0 && (
                                    <Table.Row>
                                        <Table.Cell
                                            colSpan={
                                                (showWeekDuration ? config.weeks.length : 0) +
                                                4 +
                                                excludeDates(config.dates).length
                                            }
                                            style={{ textAlign: 'center', fontWeight: 'bold', padding: '2rem' }}
                                        >
                                            {t('no_data')}
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                                {contracts.map((contract, index) => (
                                    <Table.Row key={contract.id}>
                                        <Table.Cell
                                            style={{ textAlign: 'center', backgroundColor: '#f8f8f8' }}
                                            verticalAlign="middle"
                                        >
                                            <Checkbox
                                                checked={
                                                    selectedRows?.find(
                                                        (selectedItem) => selectedItem.id === contract.id
                                                    ) !== undefined
                                                        ? true
                                                        : false
                                                }
                                                onChange={() => {
                                                    if (
                                                        selectedRows.find(
                                                            (selectedItem) => selectedItem.id === contract.id
                                                        ) === undefined
                                                    ) {
                                                        // select
                                                        setSelectedRows((prev) => [...prev, contract])
                                                    } else {
                                                        // remove
                                                        setSelectedRows((prev) =>
                                                            prev.filter(
                                                                (selectedItem) => selectedItem.id !== contract.id
                                                            )
                                                        )
                                                    }
                                                }}
                                            />
                                        </Table.Cell>
                                        <Table.Cell className="sticky-column-left" style={{ padding: '0.2rem 1rem' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {/* <UserAvatar
                                                        style={{ color: "white", marginRight: "0.5rem", paddingLeft: '0rem', fontSize: 30 / 2.5 + "px", borderRadius: "50%", border: "2px solid white" }}
                                                        size={30}
                                                        name={contract.shortname}
                                                        src={null}
                                                    /> */}
                                                <div>
                                                    <strong>{contract.fullname}</strong>
                                                    <br />
                                                    <small style={{ opacity: '0.8' }}>
                                                        {contract.custom_prefix_and_id !== ''
                                                            ? contract.custom_prefix_and_id + ' · '
                                                            : ''}
                                                        {contract.position}
                                                    </small>
                                                </div>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell
                                            style={{ textAlign: 'left', backgroundColor: '#f8f8f8' }}
                                            verticalAlign="middle"
                                        >
                                            {contract.fond}{' '}
                                            <span style={{ textTransform: 'lowercase' }}> {t('hours_shortcut')}.</span>
                                            <br />
                                            <small>{contract.fond_per_unit_display}</small>
                                        </Table.Cell>

                                        {excludeDates(config.dates).map((day, index) => (
                                            <Fragment key={index}>
                                                <CellEvent
                                                    day={day}
                                                    key={index}
                                                    index={index}
                                                    shifts={shifts}
                                                    config={config}
                                                    records={records}
                                                    setModal={setModal}
                                                    contract={contract}
                                                    setRecords={setRecords}
                                                    selectedAction={selectedAction}
                                                    determinateRecordType={determinateRecordType}
                                                />
                                                {showWeekDuration && isLastDayOfWeek(day) && (
                                                    <Table.Cell
                                                        style={{
                                                            fontWeight: 'bold',
                                                            textAlign: 'center',
                                                            background: !config.highlightedWeeks.includes(day.week)
                                                                ? 'transparent'
                                                                : 'var(--light)',
                                                        }}
                                                    >
                                                        {durationConfig?.contracts
                                                            ?.find((item) => item.id === contract.id)
                                                            ?.weekDurations?.find((item) => item.id === day.week)
                                                            ?.duration || 0}
                                                    </Table.Cell>
                                                )}
                                            </Fragment>
                                        ))}

                                        <Table.Cell
                                            className="sticky-column-right"
                                            style={{ textAlign: 'center', fontWeight: 'bold' }}
                                            verticalAlign="middle"
                                        >
                                            {durationConfig?.contracts?.find((item) => item.id === contract.id)
                                                ?.duration || 0}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    )}
                </div>
            </Segment>

            <Modal
                closeIcon
                size="tiny"
                centered={false}
                open={modal.open}
                onClose={() => setModal((prev) => ({ ...prev, open: false, selectedDay: null, contract: null }))}
            >
                <Modal.Header>{t('manage_planner_record')}</Modal.Header>
                <Modal.Content>
                    <PlannerRecordForm
                        shifts={shifts}
                        records={records}
                        onClose={() =>
                            setModal((prev) => ({ ...prev, open: false, selectedDay: null, contract: null }))
                        }
                        setRecords={setRecords}
                        data={{
                            day: modal.selectedDay,
                            contract: modal.contract,
                        }}
                    />
                </Modal.Content>
            </Modal>
        </CanView>
    )
}

export default AttendancePlanner
