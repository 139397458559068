import React from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { deleteRequest } from '@services/ServiceCommon';
// components
import Icon from '@components/Icon';
import Action from '@components/general/Action';
import { Segment, Button, Divider, Header } from 'semantic-ui-react';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import CanView from '@components/perms/CanView';

const ApplyFormList = ({ result, setResult, applyForm, setApplyForm }) => {
    const { t } = useTranslation();

    const deleteConfiguration = async (id) => {
        const request = await deleteRequest(API.APPLICATION + "form_configs/" + id + "/")
        if (request.status === 204) {
            setResult({
                ...result,
                data: result.data.filter(item => item.id !== id)
            })
        } else {
            alert(t('error_while_deleting_configuration'))
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <FlexRow padding="0">
                <FlexItem content={<h3> {t('apply_forms_templates')} </h3>} />
                <FlexItem>
                    <Button type="button" primary floated="right" onClick={() => setApplyForm({ id: 0 })}>
                        {t('create_form_template')}
                    </Button>
                </FlexItem>
            </FlexRow>
            <Divider />
            {result.data.length === 0 && <Header as="h3" textAlign="center" content={t('empty_list_apply_forms_config')} />}
            {result.data.map((item, index) => (
                <Segment
                    clearing
                    key={index}
                    style={{ marginBottom: "0" }}
                    className="segment-hover"
                >
                    <FlexRow padding="0">
                        <FlexItem basis="200%">
                            <strong>{item.name}</strong>
                        </FlexItem>
                        <FlexItem>
                            {item.is_archived
                                ? <span style={{ color: "var(--danger)", fontWeight: "bold" }}>{t('archived')}</span>
                                : <span style={{ color: "var(--success)", fontWeight: "bold" }}>{t('active')}</span>
                            }
                        </FlexItem>
                        <FlexItem basis="50%">
                            <Icon name="clipboard-outline" style={{ marginRight: "0.5rem" }} /> <span style={{ position: "relative", top: "-0.2rem" }}>{item.fields.length}</span>
                        </FlexItem>
                        <FlexItem basis="50%">
                            <Icon name="document-outline" style={{ marginRight: "0.5rem" }} /> <span style={{ position: "relative", top: "-0.2rem" }}>{item.attachments.length}</span>
                        </FlexItem>
                        <CanView permissions={["jobs.c_manage_application_forms"]}>
                            <FlexItem textAlign="center" basis="20%">
                                <Icon style={{ cursor: "pointer" }} onClick={() => setApplyForm(item)} title={t('show_configuration')} name="pencil-outline" />
                            </FlexItem>
                            <FlexItem textAlign="center" basis="20%">
                                <Action as="confirm" type="icon" icon="close-outline" tooltip={t('remove')} size="huge" iconColor="var(--danger)" paddingTop="1rem"
                                    onClick={() => deleteConfiguration(item.id)}
                                    text={t('are_you_sure_you_want_to_delete_configuration')}
                                />
                            </FlexItem>
                        </CanView>
                    </FlexRow>
                </Segment>
            ))}
        </div>
    );
};

export default ApplyFormList;