import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
// store
import { requests } from '@helpers/requests'
import { API } from '@store/config'
// components
import Icon from '@components/Icon'
import Address from '@components/forms/Address'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Divider, Header, Button } from 'semantic-ui-react'

const VisaAndResidenceForm = ({ setData, setTotal, onClose, singleItem, setHeader }) => {
    const { t } = useTranslation()

    const initialRegister = {
        country: '',
        valid_from: '',
        valid_to: ''
    }
    
    const [isProcessing, setIsProcessing] = useState(false)
    const [profiles, setProfiles] = useState([])
    const [showPromiseInfo, setShowPromiseInfo] = useState(false)
    const [showAccommodationInfo, setShowAccommodationInfo] = useState(false)
    const [showRegisterInfo, setShowRegisterInfo] = useState(false)
    const [register, setRegister] = useState( singleItem?.other_country_register || [])
    const [removedRegisters, setRemovedRegisters] = useState([])
    //const [options, setOptions] = useState([])
    const [form, setForm] = useState({
        profile: singleItem?.profile?.id || '',
        place_of_submission: singleItem?.place_of_submission?.id || '',
        date_of_submission: singleItem?.date_of_submission || "",
        type_of_residence: singleItem?.type_of_residence?.id || '',
        purpose_of_stay: singleItem?.purpose_of_stay?.id || '',
        valid_from: singleItem?.valid_from || "",
        valid_to: singleItem?.valid_to || "",
        responsible_person: singleItem?.responsible_person?.id || '',
        financial_security: singleItem?.financial_security || false,
        short_position: singleItem?.short_position || false,
        significant_investor: singleItem?.significant_investor || false,
        accommodation: singleItem?.accommodation?.id || '',
        accommodation_valid_from: singleItem?.accommodation_valid_from || "",
        accommodation_valid_to: singleItem?.accommodation_valid_to || "",
        register_of_penalties: singleItem?.register_of_penalties || false,
        register_of_penalties_valid_from: singleItem?.register_of_penalties_valid_from || "",
        register_of_penalties_valid_to: singleItem?.register_of_penalties_valid_to || "",
        employer: singleItem?.employer?.id || '',
        user_employer: singleItem?.user_employer?.id || '',
        position: singleItem?.position?.id || '',
        id_vpm: singleItem?.id_vpm || '',
        salary: singleItem?.salary || "",
        fond: singleItem?.fond || "",
        fond_per_unit: singleItem?.fond_per_unit || "",
        promise_from: singleItem?.promise_from || "",
        promise_to: singleItem?.promise_to || "",
        promise_valid_until: singleItem?.promise_valid_until || ""
    })

    const [workplace, setWorkplace] = useState({
        street: singleItem?.work_place?.street || '',
        city: singleItem?.work_place?.city || '',
        postcode: singleItem?.work_place?.postcode || '',
        country: singleItem?.work_place?.country || 'SK',
        address_line: singleItem?.work_place?.address_line || '',
        state: singleItem?.work_place?.state || '',
        number: singleItem?.work_place?.number || '',
        orientation_number: singleItem?.work_place?.orientation_number || '',
    })

    useEffect(() => {
        async function getHeader() {
            if (singleItem !== undefined) {
                setHeader(t('update_foreigner_process'))
            }
        }

        getHeader()
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async () => {
        setIsProcessing(true)

        if(singleItem){
            let createdRegisters = []
            let updatedRegisters = {}

            for(let i = 0; i < register.length; i++){
                if(register[i].id){
                    if(register[i].country) {
                        updatedRegisters[register[i].id] = {
                            country: register[i].country ? register[i].country : null,
                            valid_from: register[i].valid_from ? register[i].valid_from : null,
                            valid_to: register[i].valid_to ? register[i].valid_to : null
                        };
                    }
                } else {
                    if (register[i].country) {
                        createdRegisters.push({
                            country: register[i].country,
                            valid_from: register[i].valid_from ? register[i].valid_from : null,
                            valid_to: register[i].valid_to ? register[i].valid_to : null
                        })
                    }
                }
            }

            const formData = {
                ...form,
                place_of_submission: form.place_of_submission ? form.place_of_submission : null,
                date_of_submission: form.date_of_submission ? form.date_of_submission : null,
                type_of_residence: form.type_of_residence ? form.type_of_residence : null,
                purpose_of_stay: form.purpose_of_stay ? form.purpose_of_stay : null,
                valid_from: form.valid_from ? form.valid_from : null,
                valid_to: form.valid_to ? form.valid_to : null,
                responsible_person: form.responsible_person ? form.responsible_person : null,
                accommodation: form.accommodation ? form.accommodation : null,
                accommodation_valid_from: form.accommodation_valid_from ? form.accommodation_valid_from : null,
                accommodation_valid_to: form.accommodation_valid_to ? form.accommodation_valid_to : null,
                register_of_penalties_valid_from: form.register_of_penalties_valid_from ? form.register_of_penalties_valid_from : null,
                register_of_penalties_valid_to: form.register_of_penalties_valid_to ? form.register_of_penalties_valid_to : null,
                employer: form.employer ? form.employer : null,
                user_employer: form.user_employer ? form.user_employer : null,
                position: form.position ? form.position : null,
                fond: form.fond ? form.fond : null,
                salary: form.salary ? form.salary : null,
                fond_per_unit: form.fond_per_unit ? form.fond_per_unit : null,
                promise_from: form.promise_from ? form.promise_from : null,
                promise_to: form.promise_to ? form.promise_to : null,
                promise_valid_until: form.promise_valid_until ? form.promise_valid_until : null,
                work_place: workplace,
                other_country_register: removedRegisters.length > 0 ? {
                    create: createdRegisters,
                    update: updatedRegisters,
                    remove: removedRegisters,
                } : {
                    create: createdRegisters,
                    update: updatedRegisters,
                }
            }

            const request = await requests.patch(API.FOREIGNERS + "processes/" + singleItem.id + "/", formData)
            if(request.status === 200){
                setData(prev => prev.map(item => {
                    if (item.id === singleItem.id) {
                        item = request.response
                    }
                    return item
                }))
            }
        }
        else{
            if (profiles) {
                const formDatas = []

                for (let i = 0; i < profiles.length; i++) {
                    formDatas.push({
                        ...form,
                        profile: profiles[i],
                        place_of_submission: form.place_of_submission ? form.place_of_submission : null,
                        date_of_submission: form.date_of_submission ? form.date_of_submission : null,
                        type_of_residence: form.type_of_residence ? form.type_of_residence : null,
                        purpose_of_stay: form.purpose_of_stay ? form.purpose_of_stay : null,
                        valid_from: form.valid_from ? form.valid_from : null,
                        valid_to: form.valid_to ? form.valid_to : null,
                        responsible_person: form.responsible_person ? form.responsible_person : null,
                        accommodation: form.accommodation ? form.accommodation : null,
                        accommodation_valid_from: form.accommodation_valid_from ? form.accommodation_valid_from : null,
                        accommodation_valid_to: form.accommodation_valid_to ? form.accommodation_valid_to : null,
                        register_of_penalties_valid_from: form.register_of_penalties_valid_from ? form.register_of_penalties_valid_from : null,
                        register_of_penalties_valid_to: form.register_of_penalties_valid_to ? form.register_of_penalties_valid_to : null,
                        employer: form.employer ? form.employer : null,
                        user_employer: form.user_employer ? form.user_employer : null,
                        position: form.position ? form.position : null,
                        fond: form.fond ? form.fond : null,
                        salary: form.salary ? form.salary : null,
                        fond_per_unit: form.fond_per_unit ? form.fond_per_unit : null,
                        promise_from: form.promise_from ? form.promise_from : null,
                        promise_to: form.promise_to ? form.promise_to : null,
                        promise_valid_until: form.promise_valid_until ? form.promise_valid_until : null,
                        work_place: workplace,
                        other_country_register: { create: register.filter(item => item.country !== "").map(item => ({
                            country: item.country,
                            valid_from: item.valid_from ? item.valid_from : null,
                            valid_to: item.valid_to ? item.valid_to : null
                        }))}
                    })
                }

                const request = await requests.post(API.FOREIGNERS_BULK, formDatas)
                if (request.status === 201) {
                    toast({
                        type: 'success',
                        icon: 'check circle',
                        title: t('success'),
                        animation: 'pulse',
                        time: 2000,
                    })
                    setData(prev => ([ ...request.response, ...prev]))
                    setTotal(total => total + request.response.length)
                }
            }
        }
        setRemovedRegisters([])
        setIsProcessing(false)
        onClose()
    }

    return (
        <Form onSubmit={handleSubmit}>
            {!singleItem &&
            <>
                <SuperField as="choice-select"
                    search
                    multiple
                    required
                    value={profiles}
                    endpoint={API.FOREIGNERS}
                    text="fullname_with_titles"
                    additionalFilters={'&only_basic_info=true'}
                    onChange={(e, { value }) => setProfiles(value)}
                    label={t('select_one_or_multiple_profiles_to_start_process')}
                    initialOptions={{
                        attribute: "fullname",
                        source: profiles,
                    }}
                />
                <Divider />
            </>
            }

            <Form.Group widths="equal">
                <SuperField
                    search
                    as="choice-select"
                    text="title"
                    endpoint={API.FOREIGNERS + 'types_of_residence/'}
                    label={t('type_of_residence')}
                    value={form.type_of_residence}
                    onChange={(e, { value }) => setForm(prev => ({ ...prev, type_of_residence: value }))}
                    settings="types/types-of-residence"
                    initialOptions={{
                        attribute: "title",
                        source: singleItem?.type_of_residence
                    }}
                />
                <SuperField
                    search
                    as="choice-select"
                    text="title"
                    endpoint={API.FOREIGNERS + 'purposes_of_stay/'}
                    label={t('purpose_of_stay')}
                    value={form.purpose_of_stay}
                    onChange={(e, { value }) => setForm(prev => ({ ...prev, purpose_of_stay: value }))}
                    settings="types/purposes-of-stay"
                    initialOptions={{
                        attribute: "title",
                        source: singleItem?.purpose_of_stay
                    }}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField
                    search
                    as="datepicker"
                    label={t('date_of_submission')}
                    value={form.date_of_submission}
                    onChange={(e, { value }) => {
                        setForm(prev => ({ ...prev, date_of_submission: value }))
                    }}
                />
                <SuperField as="choice-select"
                    search
                    text="title"
                    label={t('place_of_submission')}
                    value={form.place_of_submission}
                    onChange={(e, { value }) => setForm(prev => ({ ...prev, place_of_submission: value }))}
                    endpoint={API.FOREIGNERS + 'submission_offices/'}
                    settings="submission-offices"
                    initialOptions={{
                        attribute: "title",
                        source: singleItem?.place_of_submission
                    }}
                />
            </Form.Group>

            <Divider />
            <Header
                as="h5"
                style={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    opacity: '0.8',
                    color: 'var(--primary)',
                }}
                onClick={() => setShowAccommodationInfo(!showAccommodationInfo)}
            >
                <Icon
                    name={`chevron-${showAccommodationInfo ? 'down' : 'forward'}-outline`}
                    style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                />
                <span>{t('accommodation')}</span>
            </Header>

            {showAccommodationInfo && (
                <>
                    <Divider/>
                    <SuperField as="choice-select"
                        search
                        allowAdditions
                        label={t('accommodation')}
                        value={form.accommodation}
                        endpoint={API.ACCOMMODATIONS}
                        text="name"
                        additionalFilters="&query={id, name}"
                        additionsConfig={{
                            attribute: "name",
                            endpoint: API.ACCOMMODATIONS
                        }}
                        initialOptions={{
                            attribute: "name",
                            source: singleItem?.accommodation
                        }}
                        onChange={(e, { value }) => setForm(prev => ({ ...prev, accommodation: value }))}
                    />
                    {form.accommodation &&
                        <Form.Group widths="equal">
                            <SuperField
                                search
                                as="datepicker"
                                label={t('valid_from')}
                                value={form.accommodation_valid_from}
                                onChange={(e, { value }) => { setForm(prev => ({ ...prev, accommodation_valid_from: value })) }}
                            />
                            <SuperField
                                search
                                as="datepicker"
                                label={t('valid_to')}
                                value={form.accommodation_valid_to}
                                onChange={(e, { value }) => { setForm(prev => ({ ...prev, accommodation_valid_to: value })) }}
                            />
                        </Form.Group>
                    }
                </>
            )}

            
            <Divider />
            <Header
                as="h5"
                style={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    opacity: '0.8',
                    color: 'var(--primary)',
                }}
                onClick={() => setShowRegisterInfo(!showRegisterInfo)}
            >
                <Icon
                    name={`chevron-${showRegisterInfo ? 'down' : 'forward'}-outline`}
                    style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                />
                <span>{t('registers')}</span>
            </Header>

            {showRegisterInfo && (
                <>
                    <Divider/>
                    <SuperField
                        as="checkbox"
                        label={t('has_register_of_penalties_from_his_country')}
                        checked={form.register_of_penalties}
                        onChange={() => setForm(prev => ({ ...prev, register_of_penalties: !form.register_of_penalties }))}
                    />
                    {form.register_of_penalties &&
                        <Form.Group widths="equal">
                            <SuperField
                                search
                                as="datepicker"
                                label={t('valid_from')}
                                value={form.register_of_penalties_valid_from}
                                onChange={(e, { value }) => { setForm(prev => ({ ...prev, register_of_penalties_valid_from: value })) }}
                            />
                            <SuperField
                                search
                                as="datepicker"
                                label={t('valid_to')}
                                value={form.register_of_penalties_valid_to}
                                onChange={(e, { value }) => { setForm(prev => ({ ...prev, register_of_penalties_valid_to: value })) }}
                            />
                        </Form.Group>
                    }

                    <Header as={'h5'} content={t('registers_in_other_country')} />
                    <Divider />
                    <div>
                        {register.map((item, index) => (
                            <div key={index}>
                                <Form.Group widths="equal">
                                    <SuperField
                                        as="choice"
                                        search
                                        label={t('country')}
                                        type="countries"
                                        value={item.country}
                                        onChange={(e, { value }) => setRegister(prev => prev.filter((itemRegister, idx) => {
                                            if (idx === index) {
                                                itemRegister.country = value
                                            }
                                            return itemRegister
                                        }))}
                                    />
                                    <SuperField
                                        search
                                        as="datepicker"
                                        label={t('valid_from')}
                                        value={item.valid_from}
                                        onChange={(e, { value }) => setRegister(prev => prev.filter((itemRegister, idx) => {
                                            if (idx === index) {
                                                itemRegister.valid_from = value
                                            }
                                            return itemRegister
                                        }))}
                                    />
                                    <SuperField
                                        search
                                        as="datepicker"
                                        label={t('valid_to')}
                                        value={item.valid_to}
                                        onChange={(e, { value }) => setRegister(prev => prev.filter((itemRegister, idx) => {
                                            if (idx === index) {
                                                itemRegister.valid_to = value
                                            }
                                            return itemRegister
                                        }))}
                                    />
                                    <Form.Field width={'1'} style={{ textAlign: 'right' }}>
                                        <Button type="button" icon='remove' style={{ cursor: "pointer", color: "var(--danger)", background: "none", paddingTop: "2.3rem" }} 
                                            onClick={ () => {
                                                setRegister(prev => prev.filter((item, idx) => idx !== index))
                                                setRemovedRegisters(prev => [...prev, item.id])
                                            }}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </div>
                        ))}
                        <Button type="button" primary size="small" content={t('add_register')} onClick={() => setRegister(prev => [...prev, initialRegister])} />
                    </div>
                </>
            )}

            <Divider />
            <Header
                as="h5"
                style={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    opacity: '0.8',
                    color: 'var(--primary)',
                }}
                onClick={() => setShowPromiseInfo(!showPromiseInfo)}
            >
                <Icon
                    name={`chevron-${showPromiseInfo ? 'down' : 'forward'}-outline`}
                    style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                />
                <span>{t('employment_details')}</span>
            </Header>

            {showPromiseInfo && (
            <>
                <Divider/>
                <Form.Group widths="equal">
                    <SuperField as="choice-select"
                        search
                        text="name"
                        label={t('employer')}
                        value={form?.employer}
                        endpoint={API.BUSINESS_DETAIL}
                        additionalFilters={"&is_employer=true&query={id, name}"}
                        onChange={(e, { value }) => setForm(prev => ({ ...prev, employer: value }))}
                        initialOptions={{
                            attribute: "name",
                            source: singleItem?.employer
                        }}
                    />
                    <SuperField as="choice-select"
                        search
                        text="name"
                        label={t('user_employer')}
                        value={form?.user_employer}
                        endpoint={API.BUSINESS_DETAIL}
                        additionalFilters={"&is_account=true&query={id, name}"}
                        onChange={(e, { value }) => setForm(prev => ({ ...prev, user_employer: value }))}
                        initialOptions={{
                            attribute: "name",
                            source: singleItem?.user_employer
                        }}
                    />
                </Form.Group>

                <Form.Group widths="equal">
                    <SuperField
                        as="choice-select"
                        search
                        text="title"
                        label={t('position')}
                        value={form.position}
                        endpoint={API.JOB_POSITIONS}
                        additionalFilters={'&query={id, title}&only_basic_info=true'}
                        onChange={(e, { value }) => setForm(prev => ({ ...prev, position: value }))}
                        initialOptions={{
                            attribute: "title",
                            source: singleItem?.position
                        }}
                    />
                </Form.Group>
                <SuperField
                    as='input'
                    label={t('id_vpm')}
                    value={form.id_vpm}
                    onChange={(e, { value }) => setForm(prev => ({ ...prev, id_vpm: value }))}
                />

                <Form.Group widths="equal">
                    <SuperField
                        as='input'
                        label={t('salary')}
                        value={form.salary}
                        onChange={(e, { value }) => setForm(prev => ({ ...prev, salary: value }))}
                    />
                    <SuperField
                        as='input'
                        label={t('fond')}
                        value={form.fond}
                        onChange={(e, { value }) => setForm(prev => ({ ...prev, fond: value }))}
                    />
                    <SuperField
                        as="choice-select"
                        type="fond_per_type"
                        label={t('fond_per_unit')}
                        value={form.fond_per_unit}
                        onChange={(e, { value }) => setForm(prev => ({ ...prev, fond_per_unit: value }))}
                        initialOptions={{
                            attribute: "fond_per_unit",
                            as_display: true,
                            source: singleItem
                        }}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <SuperField
                        search size="small"
                        as="datepicker"
                        label={t('employment_from')}
                        value={form.promise_from}
                        onChange={(e, { value }) => { setForm(prev => ({ ...prev, promise_from: value })) }}
                    />
                    <SuperField
                        search size="small"
                        as="datepicker"
                        label={t('employment_to')}
                        value={form.promise_to}
                        onChange={(e, { value }) => { setForm(prev => ({ ...prev, promise_to: value })) }}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <SuperField
                        search size="small"
                        as="datepicker"
                        label={t('document_valid_until')}
                        value={form.promise_valid_until}
                        onChange={(e, { value }) => { setForm(prev => ({ ...prev, promise_valid_until: value })) }}
                    />
                </Form.Group>

                <Divider/>
                <Header as="h3" content={t('workplace')} style={{ marginBottom: '0.5rem' }} />
                <Address isForm address={workplace} setAddress={setWorkplace} />
            </>
            )}

            <Divider/>

            <SuperField
                as="choice-select"
                search
                label={t('process_responsible_person')}
                endpoint={API.EMPLOYEES}
                additionalFilters={'&only_basic_info=true'}
                text="fullname_with_titles"
                value={form.responsible_person}
                onChange={(e, { value }) => { setForm(prev => ({ ...prev, responsible_person: value })) }}
                initialOptions={{
                    attribute: "fullname_with_titles",
                    source: singleItem?.responsible_person
               }}
            />
            <Form.Group widths="equal">
                <SuperField
                    search
                    as="datepicker"
                    label={t('process_valid_from')}
                    value={form.valid_from}
                    onChange={(e, { value }) => { setForm(prev => ({ ...prev, valid_from: value })) }}
                />
                <SuperField
                    search
                    as="datepicker"
                    label={t('process_valid_to')}
                    value={form.valid_to}
                    onChange={(e, { value }) => { setForm(prev => ({ ...prev, valid_to: value })) }}
                />
            </Form.Group>

            <SuperField
                as="checkbox"
                label={t('financial_security')}
                checked={form.financial_security}
                onChange={() => setForm(prev => ({ ...prev, financial_security: !form.financial_security }))}
            />
            <SuperField
                as="checkbox"
                label={t('short_position')}
                checked={form.short_position}
                onChange={() => setForm(prev => ({ ...prev, short_position: !form.short_position }))}
            />      
            <SuperField
                as="checkbox"
                label={t('significant_investor')}
                checked={form.significant_investor}
                onChange={() => setForm(prev => ({ ...prev, significant_investor: !form.significant_investor }))}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || (!singleItem && profiles.length === 0)}
                />
            </Form.Field>
        </Form>
    )
}

export default VisaAndResidenceForm