import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
// store
import { isEmpty } from "@helpers/validation"
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Message, Grid, Segment, Header, Divider } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import TemplateEditor from '@components/editors/template-editor/TemplateEditor';
import SuperField from '@components/forms/SuperField';
import Placeholders from '@components/editors/template-editor/Placeholders';

const DocumentForm = ({ document, onConfirm, onClose }) => {
    const { t } = useTranslation();

    const [documentID] = useState(document ? document.id : 0);

    // form formdata
    const [source, setSource] = useState(document ? document.source : "")
    const [view, setView] = useState(documentID === 0 ? 1 : 2)

    const [title, setTitle] = useState(document ? document.title : "");
    const [category, setCategory] = useState(document?.category ? document.category.id : "");
    const [content, setContent] = useState(document ? document.content : "");

    // form states
    const [isProcessing, setIsProcessing] = useState(false); // true, false
    const [errorList, setErrorList] = useState([]); // empty array

    const handleSubmit = async () => {
        setIsProcessing(true);
        setErrorList([]);
        let request = null;

        const data = {
            title: title,
            source: source,
            category: category,
            content: content
        }

        if (documentID === 0) {
            request = await requests.post(API.TEMPLATING, data)
            if (request.status === 201) {
                onConfirm(request.response);
                onClose()
            } else {
                const error_message = request.response.name;
                setErrorList([...errorList, error_message[0]]);
            }
        } else {
            request = await requests.put(API.TEMPLATING + documentID + "/", data)
            if (request.status === 200) {
                onConfirm(request.response)
                onClose()
            } else {
                const error_message = request.response.name;
                setErrorList([...errorList, error_message[0]]);
            }
        }

        setIsProcessing(false);
    }

    const SelectSource = () => {
        return (
            <>
                <Header content={t('select_source')} />
                <Divider />
                <Grid centered>
                    <Grid.Row>
                        <Grid.Column computer={5} tablet={16} mobile={16}>
                            <Segment
                                onClick={() => {
                                    setView(2)
                                    setSource('contracts.ContractInstance')
                                }}
                                style={{ cursor: "pointer", fontWeight: "bold" }}
                                className="segment-hover"
                                textAlign="center"
                                content={t('contract')}
                            />
                        </Grid.Column>
                        <Grid.Column computer={5} tablet={16} mobile={16}>
                            <Segment
                                onClick={() => {
                                    setView(2)
                                    setSource('contracts.ContractTermination')
                                }}
                                style={{ cursor: "pointer", fontWeight: "bold" }}
                                className="segment-hover"
                                textAlign="center"
                                content={t('termination')}
                            />
                        </Grid.Column>
                        <Grid.Column computer={5} tablet={16} mobile={16}>
                            <Segment
                                onClick={() => {
                                    setView(2)
                                    setSource('jobs.JobOffer')
                                }}
                                style={{ cursor: "pointer", fontWeight: "bold" }}
                                className="segment-hover"
                                textAlign="center"
                                content={t('job_offer')}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        );
    }

    return (
        <>
            {view === 1 && <SelectSource />}
            {view === 2 &&
                <Form onSubmit={handleSubmit}>
                    {errorList.length > 0 &&
                        (<Message negative>
                            {errorList.map((error, index) =>
                                <p key={index}> {error} </p>
                            )}
                        </Message>)
                    }
                    <Form.Group>
                        <Form.Field style={{ width: "100%" }}>
                            <SuperField as="input"
                                autoFocus
                                required
                                label={t('title')}
                                placeholder={t('title')}
                                value={title}
                                onChange={(e, { value }) => setTitle(value)}
                            />
                        </Form.Field>

                        <SuperField as="choice"
                            search
                            label={t('category')}
                            value={category}
                            endpoint={API.TEMPLATING_CATEGORY}
                            onChange={(e, { value }) => setCategory(value)}
                            text="title"
                        />
                    </Form.Group>

                    <Grid>
                        <Grid.Column width={13} style={{ padding: 0 }}>
                            <TemplateEditor value={content} onChange={setContent} />
                        </Grid.Column>
                        <Grid.Column>
                            <Placeholders model={source} />
                        </Grid.Column>
                    </Grid>

                    <Divider />
                    <Form.Field style={{ textAlign: "right", margin: " 1rem" }}>
                        <ModalCancel
                            onClose={onClose}
                            disabled={isProcessing}
                        />
                        <ModalSubmit
                            loading={isProcessing}
                            disabled={isProcessing || isEmpty(title) || isEmpty(content)}
                            text={t('save')}
                        />
                    </Form.Field>
                </Form >
            }
        </>
    )
}
export default DocumentForm;