import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route } from 'react-router-dom';
// store
import { icons } from '@icons';
import { routes } from '@routes';
// components
import CanView from '@components/perms/CanView';
import BreadcrumbNav from '@components/BreadcrumbNav';
import { Container, Ref } from 'semantic-ui-react';
// module specific components
import ForecastList from './views/ForecastList';

const Forecasts = () => {
    const { t } = useTranslation();
    const segmentRef = useRef()

    return (
        <CanView permissions={['forecasts.c_view_forecasts']} redirect>
            <BreadcrumbNav
                style={{ marginBottom: 0 }}
                items={[
                    { 'name': t("forecasts"), 'icon': icons.FORECASTS, 'href': '' }
                ]}
            />
            <Ref innerRef={segmentRef}>
                <Container fluid>
                    <Switch>
                        <Route exact path={routes.FORECASTS}>
                            <ForecastList segmentRef={segmentRef} />
                        </Route>
                    </Switch>
                </Container>
            </Ref>
        </CanView>
    );
};

export default Forecasts;