import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// component
import DocViewer, { DocViewerRenderers, PDFRenderer } from "@cyntler/react-doc-viewer/dist";
import { Grid, Icon, Divider } from 'semantic-ui-react';

const DocPreview = ({ uri, additionalActions, isPDF }) => {
    const { t } = useTranslation()
    const [url] = useState(uri)

    const splittedURI = url.split('.')
    const fileType = splittedURI[splittedURI.length - 1] || "pdf"

    return (
        <div key={uri}>
            <Grid>
                <Grid.Row verticalAlign="middle" columns={2} style={{ padding: 0 }}>
                    <Grid.Column style={{ textAlign: 'left', fontWeight: "bold" }}>{ t('document_preview') }</Grid.Column>
                    <Grid.Column style={{ textAlign: 'right' }}>
                        { additionalActions }
                        <a href={url} download style={{ marginLeft: "1rem" }}>
                            <Icon name="download" style={{ cursor: 'pointer' }}/>
                        </a>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider/>
            <DocViewer
                prefetchMethod="GET"
                pluginRenderers={isPDF === undefined ? DocViewerRenderers : [PDFRenderer]}
                documents={[{ uri: url, fileType: fileType.toLowerCase() }]}
                initialActiveDocument={{ uri: url, fileType: fileType.toLowerCase() }}
            />
        </div>
    );
};

export default DocPreview;