import React, { useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { icons } from '@store/icons'
import { useHasPermissions } from '@helpers/hooks';
// components
import Icon from '@components/Icon'
import ListView from '@components/ListView'
import CanView from '@components/perms/CanView'
import Attachments from '@components/Attachments'
import DatePresets from '@components/DatePresets'
import BreadcrumbNav from '@components/BreadcrumbNav'
import SuperField from '@components/forms/SuperField'
import LabelsList from '@components/lists/LabelsList'
import GenerateDocuments from '@components/GenerateDocuments'
import { Label, Popup, Form, Divider, Button, Container, Icon as SemanticIcon } from 'semantic-ui-react'
// UI related components
import B2BContractForm from './components/B2BContractForm'

const B2BContracts = () => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)
    const canManage = useHasPermissions(['contracts.c_manage_all_business_contracts', 'contracts.c_manage_user_business_contracts'])
    const [attachmentsOpen, setAttachmentsOpen] = useState(0)
    const [view, setView] = useState(1) // is_issued = true

    const renderColor = (status) => {
        if (status === 1) return "var(--success)"
        if (status === 2) return "var(--danger)"
        if (status === 3) return "var(--blue)"
        if (status === 4) return "var(--dark)"

        return "var(--dark)"
    }

    return (
        <CanView
            redirect
            permissions={[
                'contracts.c_view_all_business_contracts',
                'contracts.c_view_user_business_contracts',
            ]}
        >
            <BreadcrumbNav items={[{ name: t('b2b_contracts'), icon: icons.CONTRACTS, href: '' }]} mBottom="0rem" />

            <Container fluid>
                <Button.Group basic size="tiny" className='custom-filter-button-group'>
                    <Button active={view === 1} onClick={() => setView(1)}>
                        <Icon name={icons.B2B_CONTRACTS} style={{ marginRight: "0.5rem" }}/> 
                        <span style={{ position: "relative", top: "-0.1rem" }}>
                            { t('account_contracts') } 
                        </span>
                    </Button>
                    <Button active={view === 2} onClick={() => setView(2)}>
                        <Icon name={icons.B2B_CONTRACTS} style={{ marginRight: "0.5rem" }}/> 
                        <span style={{ position: "relative", top: "-0.1rem" }}>
                            { t('supplier_contracts') } 
                        </span>
                    </Button>
                </Button.Group>
                <Divider/>
            </Container>
            <ListView
                key={view}
                as="table"
                allowSearch
                allowSelection
                isSoftDeleteAllowed
                endpoint={API.B2B_CONTRACTS + "contracts/"}
                actionsCellWidth="2"
                bulkActions={(selected, setData, setTotal, filters, queryParams, setSelected) => [
                    {
                        as: 'modal',
                        name: t('generate_documents'),
                        icon: 'document-text-outline',
                        modal: <GenerateDocuments source="contracts.BusinessContract" selected={selected} />,
                    },
                ]}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        name: t('create_b2b2_contract'),
                        modal: <B2BContractForm view={view}/>,
                        permissions: [
                            'contracts.c_manage_all_business_contracts',
                            'contracts.c_manage_user_business_contracts',
                        ],
                    },
                    {
                        name: t('edit'),
                        type: 'edit',
                        as: 'modal',
                        icon: 'pencil-outline',
                        customIconColor: 'var(--dark)',
                        permissions: [
                            'contracts.c_manage_all_business_contracts',
                            'contracts.c_manage_user_business_contracts',
                        ],
                        modal: <B2BContractForm view={view}/>,
                    },
                    {
                        name: t('delete'),
                        type: 'delete',
                        as: 'delete',
                        text: t('delete_b2b_contract'),
                        permissions: [
                            'contracts.c_delete_all_business_contracts',
                        ],
                    },
                ]}
                listAdditionActions={() => [
                    {
                        as: 'filter',
                        index: 0,
                        name: t('active'),
                        filters: {
                            status: 1,
                        },
                    },
                    {
                        as: 'filter',
                        index: 1,
                        name: t('terminated'),
                        filters: {
                            status: 2,
                        },
                    },
                    {
                        as: 'filter',
                        index: 2,
                        name: t('draft'),
                        filters: {
                            status: 3,
                        },
                    },
                    {
                        as: 'filter',
                        index: 3,
                        name: t('archived'),
                        filters: {
                            status: 4,
                        },
                    },
                ]}
                initialFilters={{
                    is_issued: view === 1 ? true : false,
                    owner: '',
                    category: '',
                    type: '',
                    business_partner: '',
                    status: 1,
                    valid_from: '',
                    valid_to: '',
                    is_valid_to_none: '',
                    labels: []
                }}
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <Form.Group widths="equal">
                            <SuperField
                                as="datepicker"
                                size="small"
                                label={t('valid_from')}
                                value={filters.valid_from}
                                style={{ height: '2.75rem' }}
                                onChange={(e, { value }) => setFilters(prev => ({ ...prev, valid_from: value }))}
                            />
                            <SuperField
                                as="datepicker"
                                size="small"
                                label={t('valid_to')}
                                value={filters.valid_to}
                                style={{ height: '2.75rem' }}
                                onChange={(e, { value }) => setFilters(prev => ({ ...prev, valid_to: value }))}
                            />
                        </Form.Group>
                        <DatePresets
                            from={filters.valid_fro}
                            to={filters.valid_to}
                            onSelection={(firstMonthDay, lastMonthDay, year) => {
                                setFilters((prev) => ({
                                    ...prev,
                                    valid_from: firstMonthDay,
                                    valid_to: lastMonthDay,
                                }))
                            }}
                        />
                        <Divider/>

                        <Form.Group widths="equal">
                            <SuperField
                                as="choice-select"
                                search
                                label={view === 1 ? t('supplier') : t('account')}
                                value={filters.owner}
                                text={(item) => item?.name}
                                endpoint={API.BUSINESS_DETAIL}
                                additionalFilters="&query={id, name}&is_employer=true"
                                onChange={(e, { value }) => setFilters(prev => ({ ...prev, owner: value }))}
                            />
                            <SuperField
                                as="choice-select"
                                search
                                label={view === 1 ? t('account') : t('supplier')}
                                value={filters.business_partner}
                                endpoint={API.BUSINESS_DETAIL}
                                text={(item) => item?.name}
                                additionalFilters="&filter_by_entities=account,supplier"
                                onChange={(e, { value }) => setFilters(prev => ({ ...prev, business_partner: value }))}
                            />
                        </Form.Group>

                        <Form.Group widths="equal">
                            <SuperField as="choice-select"
                                search
                                text="title"
                                value={filters.type}
                                label={t('contract_type')}
                                onChange={(e, { value }) => setFilters(prev => ({ ...prev, type: value }))}
                                endpoint={API.B2B_CONTRACTS + 'types/'}
                            />
                            <SuperField as="choice-select"
                                search
                                text="title"
                                value={filters.category}
                                label={t('category')}
                                onChange={(e, { value }) => setFilters(prev => ({ ...prev, category: value }))}
                                endpoint={API.B2B_CONTRACTS + 'categories/'}
                            />
                        </Form.Group>

                        <SuperField as="choice-select"
                            search
                            multiple
                            text="name"
                            label={t('labels')}
                            value={filters.labels}
                            endpoint={API.B2B_CONTRACTS + "labels/"}
                            additionalFilters="&query={id, name}"
                            onChange={(e, { value }) => setFilters({ ...filters, labels: value })}
                        />

                        <SuperField
                            as="checkbox"
                            label={t('indefinite_period')}
                            checked={filters.is_valid_to_none === true}
                            onChange={() =>
                                setFilters((prev) => ({
                                    ...prev,
                                    is_valid_to_none: filters.is_valid_to_none === true ? '' : true,
                                }))
                            }
                        />
                    </>
                )}
                tableHeaders={[
                    { title: t('custom_id'), orderBy: 'custom_id' },
                    { title: t('title') },
                    { title: t('supplier') },
                    { title: t('account') },
                    { title: t('contract_type')},
                    { title: t('valid_from') },
                    { title: t('valid_to') },
                    { title: t('category') },
                    { title: t('labels') },
                    { title: t('documents') },
                    { title: t('status') },
                ]}
                renderCells={(contract, setData) => [
                    { content: contract.custom_id },
                    { content: contract.title },
                    { content: view === 1 ? contract.owner.name : contract.business_partner.name },
                    { content: view === 1 ? contract.business_partner.name : contract.owner.name },
                    { content: contract.type?.title || "" },
                    { content: moment(contract?.valid_from).format(dateFormat) },
                    {
                        content: contract?.valid_until
                            ? moment(contract?.valid_until).format(dateFormat)
                            : t('indefinite_period'),
                    },
                    { content: contract.category?.title || "" },
                    { customWidth: "200px", content: 
                        <LabelsList
                            key={contract.id}
                            rowID={contract.id}
                            labels={contract.labels}
                            endpoint={API.B2B_CONTRACTS + "contracts/"}
                            endpointLabels={API.B2B_CONTRACTS + "labels/"}
                            label_key={"labels"}
                        />
                    },
                    { 
                        content: (
                            <Label basic>
                                <Popup
                                    basic
                                    hoverable
                                    position='left center'
                                    open={attachmentsOpen === contract.id}
                                    style={{ zIndex: "1000", minWidth: "450px" }}
                                    onOpen={() => setAttachmentsOpen(contract.id)}
                                    trigger={
                                        <div style={{ cursor: "pointer" }}>
                                            <Icon name="reader-outline" style={{ marginRight: "0.5rem" }}/> 
                                            <span style={{ position: "relative", top: "-0.1rem" }}>{ contract.attachments.length }</span>
                                        </div>
                                    }
                                    content={
                                        <Attachments 
                                            record={contract}
                                            setData={setData}
                                            viewOnly={!canManage}
                                            moduleName={"business_contract"}
                                            closeView={() => setAttachmentsOpen(0)}
                                            sourceEndpoint={API.B2B_CONTRACTS + "contracts/"}
                                        />
                                    }
                                />
                            </Label>
                        ),
                    },
                    { content: 
                        <div style={{ display: 'flex', alignItem: "center" }}>
                            <SemanticIcon
                                name="circle"
                                style={{
                                    color: renderColor(contract.status),
                                    fontSize: "0.7rem",
                                    marginRight: '0.5rem',
                                }}
                            />
                            <span>
                                { contract.status_display } 
                            </span>
                        </div>
                    },
                ]}
            />
        </CanView>
    );
};

export default B2BContracts