import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import { Doughnut } from 'react-chartjs-2'
import AvatarIcon from '@components/AvatarIcon'
import { Header, Grid, Segment, Button } from 'semantic-ui-react'
import { FlexRow, FlexItem } from '@components/tables/FlexTable'
import EditTaskForm from './forms/EditTasksForm'
import CanView from '@components/perms/CanView'
import Action from '@components/general/Action'
import ChangeDeadlineForm from './forms/ChangeDeadlineForm'
import TaskRow from './TaskRow'
import moment from 'moment'

const Row = ({ data, originalResult, setOriginResult, terminate }) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [result, setResult] = useState([])
    const [loading, setLoading] = useState(false)
    // eslint-disable-next-line
    const [add, setAdd] = useState(false)

    const datagraph = {
        labels: [t('complete'), t('incomplete')],
        datasets: [
            {
                label: '...',
                data: [data.all_checked_items_count, data.all_items_count - data.all_checked_items_count],
                backgroundColor: ['#1EE3CF', '#c20134'],
                hoverBackgroundColor: ['#552fe4', '#c9c313'],
            },
        ],
    }

    useEffect(() => {
        async function fetchData() {
            if (open) {
                setLoading(true)
                const request = await requests.get(API.ONBOARDING_ITEMS + '?checklist_instance=' + data.id)
                if (request.status === 200) setResult(request.response)
                setLoading(false)
            }
        }

        fetchData()
        // eslint-disable-next-line
    }, [open])

    // eslint-disable-next-line
    const addTask = async (item) => {
        setResult([...result, item])

        setOriginResult((prev) => ({
            ...prev,
            data: {
                ...prev.data,
                results: prev.data.results.filter((item) => {
                    if (item.id === data.id) {
                        item.all_items_count = item.all_items_count + 1
                    }

                    return item
                }),
            },
        }))
    }

    const AddTaskForm = (isInstance) => {
        return (
            <>
                {!add ? (
                    <Button primary style={{ marginLeft: '1rem', marginTop: '1rem' }} onClick={() => setAdd(true)}>
                        <Icon name="add-outline" style={{ marginRight: '0.5rem' }} />
                        <span style={{ position: 'relative', top: '-0.1rem' }}>{t('add_new_task')}</span>
                    </Button>
                ) : (
                    <EditTaskForm
                        isInstance={isInstance}
                        checklistID={data.id}
                        onConfirm={(item) => addTask(item)}
                        onClose={() => setAdd(false)}
                    />
                )}
            </>
        )
    }

    return (
        <Segment style={{ padding: 0, margin: 0 }}>
            <FlexRow background={'var(--white)'}>
                <FlexItem basis="20%">
                    <AvatarIcon
                        size={40}
                        name={data?.employee?.fullname || 'problem'}
                        src={data?.employee?.profile_picture ? data?.employee?.profile_picture : ''}
                    />
                </FlexItem>
                <FlexItem basis="120%">
                    <Header style={{ marginBottom: 0 }} as="h3" content={data.employee?.fullname} />
                    <span> {data.employee?.position?.[0]?.name} </span>
                </FlexItem>
                <FlexItem textAlign="left">
                    <span>
                        <small>
                            {t('deadline')}
                            <CanView permissions={['onboarding.c_manage_all_checklist_instances']}>
                                <Action
                                    as="modal"
                                    type="icon"
                                    modalSize="tiny"
                                    header={t('change_deadline')}
                                    icon="pencil"
                                    size="tiny"
                                    iconColor="var(--dark)"
                                    tooltip={t('change_deadline')}
                                    modal={<ChangeDeadlineForm onboarding={data} setBoardings={setOriginResult} />}
                                />
                            </CanView>
                        </small>{' '}
                        <br />
                        <strong>{data?.deadline ? moment(data.deadline).format('DD.MM.YYYY') : '--'}</strong>
                    </span>
                </FlexItem>
                <FlexItem textAlign="left">
                    <span>
                        <small>{t('tasks')}</small> <br />
                        <strong>
                            {data.all_checked_items_count} / {data.all_items_count}
                        </strong>
                    </span>
                </FlexItem>
                <FlexItem textAlign="left">
                    <small>{t('status')}</small> <br />
                    <div
                        style={{
                            color:
                                data.status === 1
                                    ? 'var(--primary)'
                                    : data.status === 2
                                    ? 'var(--danger)'
                                    : 'var(--success)',
                            fontWeight: 'bolder',
                            fontSize: '1.2rem',
                        }}
                    >
                        {data.all_items_count
                            ? ((data.all_checked_items_count / data.all_items_count) * 100).toFixed(0) +
                              '% ' +
                              t('completed') +
                              ' '
                            : t('no_tasks')}
                    </div>
                </FlexItem>

                <FlexItem textAlign="center" basis="50%">
                    <CanView permissions={['onboarding.c_manage_all_checklist_instances']}>
                        {data?.status === 1 && (
                            <Button
                                style={{ background: 'var(--danger)', color: 'var(--white)' }}
                                content={t('terminate')}
                                size="small"
                                onClick={() => terminate(data.id)}
                            />
                        )}
                    </CanView>
                </FlexItem>
                <FlexItem textAlign="center" basis="5%">
                    <Icon
                        name={open ? 'chevron-up-outline' : 'chevron-down-outline'}
                        style={{ cursor: 'pointer', fontSize: '1.5rem' }}
                        onClick={() => setOpen(!open)}
                    />
                </FlexItem>
            </FlexRow>
            {open && (
                <Segment loading={loading} style={{ padding: 0, marginTop: 0, border: 'none' }}>
                    <Grid style={{ background: 'var(--white)', padding: '2rem' }}>
                        <Grid.Row>
                            {result.length > 0 ? (
                                <>
                                    <Grid.Column
                                        textAlign="left"
                                        verticalAlign="middle"
                                        computer={3}
                                        tablet={4}
                                        mobile={16}
                                    >
                                        <div>
                                            <Doughnut
                                                data={datagraph}
                                                width={400}
                                                options={{
                                                    cutoutPercentage: 65,
                                                    maintainAspectRatio: false,
                                                    legend: { display: false },
                                                    responsive: true,
                                                    tooltips: {
                                                        bodyFontColor: '#fff',
                                                        backgroundColor: '#000',
                                                    },
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                paddingLeft: '0.5rem',
                                                textAlign: 'center',
                                                width: '100%',
                                                fontWeight: 'bold',
                                                fontSize: '1.5rem',
                                                paddingTop: '1rem',
                                            }}
                                        >
                                            {((data.all_checked_items_count / data.all_items_count) * 100).toFixed(0)} %
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column computer={13} tablet={12} mobile={16}>
                                        <FlexRow padding="0" />
                                        {result.map((item, index) => (
                                            <TaskRow
                                                result={result}
                                                instanceID={data.id}
                                                isTerminated={data?.status === 2}
                                                setResult={setResult}
                                                originalResult={originalResult}
                                                setData={setOriginResult}
                                                key={item.id}
                                                task={item}
                                            />
                                        ))}
                                        <CanView permissions={['onboarding.c_view_all_checklist_instances']}>
                                            {data?.status !== 2 && <AddTaskForm isInstance />}
                                        </CanView>
                                    </Grid.Column>
                                </>
                            ) : (
                                <div style={{ margin: '0 auto' }}>
                                    <Header as="h3" textAlign="center">
                                        {' '}
                                        {t('no_task_assigned')}{' '}
                                    </Header>
                                    <CanView permissions={['onboarding.c_view_all_checklist_instances']}>
                                        {data?.status !== 2 && (
                                            <div style={{ textAlign: 'center', marginLeft: '-1rem' }}>
                                                <AddTaskForm isInstance />
                                            </div>
                                        )}
                                    </CanView>
                                </div>
                            )}
                        </Grid.Row>
                    </Grid>
                </Segment>
            )}
        </Segment>
    )
}

export default Row
