const modulePreferencesReducer = (preferences = {}, action) => {
    switch (action.type) {
        case "ADD_MODULE_PREFERENCES":
            return {
                ...preferences,
                [action.payload.moduleName]: action.payload.[action.payload.moduleName]
            };
        default:
            return preferences;
    }
};

export default modulePreferencesReducer;