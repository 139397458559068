import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
// components
import Icon from '@components/Icon';
import LabelsList from '@components/lists/LabelsList';
import { Container, Divider, Grid, Header, Progress } from 'semantic-ui-react';

const HiringRoomHeader= ({room}) => {
    const { t } = useTranslation();

    const getTargetPercent = (recruited, target) => {
        let x;
        x = recruited * 100 / target
        let result = Number((x).toFixed(1))
        if(isNaN(result)){
            result = 0
        }
        return result
    }

    return (
        <Container fluid>
            <Grid>
                <Grid.Row verticalAlign="middle" style={{ padding: 0 }} columns={3}>
                    <Grid.Column width={4}>
                        <Header as="h2" content={room.name} style={{ marginBottom: 0 }} />
                        <small style={{ color: "var(--variant1)" }}> {t('hiring_room')} </small>
                        <div style={{ paddingTop: "1rem" }}>
                            <Icon name="briefcase-outline" style={{ marginRight: "0.5rem"}}/>
                            <strong>{t('position') + ": "}</strong>
                            <span style={{ position: "relative"}}>
                                {room?.job_position?.title}
                            </span>
                        </div>
                        <div>
                            <Icon name="list-outline" style={{ marginRight: "0.5rem" }}/> 
                            <strong>{t('order_number') + ": "}</strong>
                            <span tyle={{ position: "relative"}}>
                                {room.hrorder?.id ? <> {room.hrorder?.order_number} </> : "--"}
                            </span>
                        </div>
                        {(room?.date_from || room?.date_to) &&
                            <div style={{ paddingTop: "1rem" }}>
                                <span style={{ position: "relative" }}>
                                    {room.date_from !== null ? moment(room.date_from).format('DD.MM.YYYY') : " "}
                                </span>
                                <span style={{ position: "relative" }}>
                                    {" - "}
                                    {room.date_to !== null ? moment(room.date_to).format('DD.MM.YYYY') : " "}
                                </span>
                            </div>
                        }
                    </Grid.Column>
                    <Grid.Column width={6}>
                    </Grid.Column>
                    <Grid.Column width={6} style={{ textAlign: "left" }}>
                        {room?.target &&
                            <>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span style={{ margin: 0 }}>{t('recruited')}</span>
                                    <span style={{ margin: 0 }}>{t('target')}</span>
                                </div>
                                
                                <Progress 
                                    size='small'
                                    value={room?.archived_recruitment_stages?.[room?.archived_recruitment_stages.length - 1].candidate_count} 
                                    total={room.target} 
                                    progress='ratio'
                                    style={{ marginBottom: 0, margin: 0 }}
                                />
                                <Header 
                                    as={"h5"} 
                                    content={getTargetPercent(room?.archived_recruitment_stages?.[room?.archived_recruitment_stages.length - 1].candidate_count, room.target) + '%'} 
                                    style={{ textAlign: "right", marginTop: "1rem" }}
                                />
                            </>
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <LabelsList
                            key={room.labels}
                            rowID={room.id}
                            label_key="labels"
                            labels={room.labels}
                            endpoint={API.HIRING_ROOM}
                            endpointLabels={API.CANDIDATES_LABELS}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>    
            <Divider />         
        </Container>
    );
};

export default HiringRoomHeader;