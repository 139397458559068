import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
// store
import { thousandsSeparators } from '@helpers/functions';
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import {
    PieChart,
    Pie,
    Sector, 
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    Line,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";
import Icon from '@components/Icon';
import Action from '@components/general/Action';
import SpinnerSegment from '@components/SpinnerSegment';
import { Container, Table, Grid, Button, Sidebar, Menu, Ref, Segment } from 'semantic-ui-react';
// specific components
import OverviewFilterForm from '../components/forms/OverviewFilterForm';

const Overview = ({ connection, user }) => {
    const { t } = useTranslation()
    const target = useRef()
    const journal = user.journal

    const monthsList = [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december'),
    ];

    const firstDayInYear = moment().format('YYYY') + "-01-01"
    const lastDayInYear = moment().format('YYYY') + "-12-31"
    
    const [isIssued, setIsIssued] = useState(true)
    const [loading, setLoading] = useState(false)
    const [barChartData, setBarChartData] = useState([])
    const [pieChartData, setPieChartData] = useState([])
    const [totals, setTotals] = useState([])
    const [visible, setVisible] = useState(false)
    const [selectedMonth, setSelectedMonth] = useState(null)
    const [activeIndex, setActiveIndex] = useState(0)

    const initialFilters = {
        date_from: firstDayInYear,
        date_to: lastDayInYear,
        currency: "EUR",
        count_with_tax: false,
        name: "",
        registration_id: "",
        tax_id: "",
        vat_id: "",
    }

    const [filters, setFilters] = useState(initialFilters)

    const calculateDiff = (a, b) => {
        const diff = parseFloat(parseFloat(a) - parseFloat(b)).toFixed(2)
        return diff
    }

    const createDateRange = () => {
        // determinate year
        let year = ""
        const yearFrom = moment(filters.date_from).format("YYYY")
        const yearTo = moment(filters.date_to).format("YYYY")

        if (yearFrom === yearTo) {
            year = yearFrom
        } else {
            year = `${yearFrom}/${yearTo}`
        }

        // determinate months
        let months = ""
        let monthFrom = moment(filters.date_from).format("M")
        let monthTo = moment(filters.date_to).format("M")

        if (monthFrom === monthTo) {
            months = monthsList[parseInt(monthFrom) - 1]
        } else {
            months = `${monthsList[parseInt(monthFrom) - 1]} - ${monthsList[parseInt(monthTo) - 1]}`
        }


        return `${months} ${year}`
    }

    const calcPercent = (value, total)=> {
        let percentage = 0
        if (value > 0) {
            percentage = parseFloat((value/total) * 100).toFixed(2)
        } else {
            percentage = 0
        }
        return percentage
    }

    async function fetchInvoiceStatistics() {
        setLoading(true)
        let params = ""
        params += `&account_ids=${journal.id}`
        params += `&date_from=${filters.date_from}&date_to=${filters.date_to}`
        if (filters.count_with_tax === false) {
            params += `&count_with_tax=false`
        }

        if (filters.name !== "") params += `&name=${filters.name}`
        if (filters.tax_id !== "") params += `&tax_id=${filters.tax_id}`
        if (filters.vat_id !== "") params += `&vat_id=${filters.vat_id}`
        if (filters.registration_id !== "") params += `&registration_id=${filters.registration_id}`
        const request = await requests.get(API.INVOICES + `graph_statistics_new/?connection=${connection.connection_id}${params}&convert_to_currency=${filters.currency}`)

        if (request.status === 200){
            let calculations = {
                issued_all: 0,
                issued_all_count: 0,
                issued_draft: 0,
                issued_draft_count: 0,
                issued_outstanding: 0,
                issued_outstanding_count: 0,
                issued_past_due: 0,
                issued_past_due_count: 0,
                issued_paid: 0,
                issued_paid_count: 0,
                
                recieved_all: 0,
                recieved_all_count: 0,
                recieved_draft: 0,
                recieved_draft_count: 0,
                recieved_outstanding: 0,
                recieved_outstanding_count: 0,
                recieved_past_due: 0,
                recieved_past_due_count: 0,
                recieved_paid: 0,
                recieved_paid_count: 0,
            }
            const monthsData = []
            const months = request.response
            // create each month array
            for (let i = 0; i < months.length; i++) {
                let data = {} 
                
                if ( Array.isArray(months[i].stats) ) {
                    data = months[i].stats.find(item => item.currency === filters.currency)
                }

                if ( months[i].stats instanceof Object ){
                    data = months[i].stats
                }
                if (data) {
                    let splitDate = months[i].date.split('-')
                    monthsData.push({
                        id: `${splitDate[1]}/${splitDate[0]}`,
                        name: monthsList?.[parseInt(splitDate[1]) - 1] || months[i].date,
                        year: splitDate[0],
                        issued_all: data.all.issued,
                        issued_all_count: data.all.issued_count,
                        issued_draft: data.draft.issued,
                        issued_draft_count: data.draft.issued_count,
                        issued_outstanding: data.outstanding.issued,
                        issued_outstanding_count: data.outstanding.issued_count,
                        issued_past_due: data.past_due.issued,
                        issued_past_due_count: data.past_due.issued_count,
                        issued_paid: data.paid.issued,
                        issued_paid_count: data.paid.issued_count,
                        recieved_all: data.all.recieved,
                        recieved_all_count: data.all.recieved_count,
                        recieved_draft: data.draft.recieved,
                        recieved_draft_count: data.draft.recieved_count,
                        recieved_outstanding: data.outstanding.recieved,
                        recieved_outstanding_count: data.outstanding.recieved_count,
                        recieved_past_due: data.past_due.recieved,
                        recieved_past_due_count: data.past_due.recieved_count,
                        recieved_paid: data.paid.recieved,
                        recieved_paid_count: data.paid.recieved_count,
                    })

                    // issued
                    calculations.issued_all = calculations.issued_all + data.all.issued
                    calculations.issued_all_count = calculations.issued_all_count + data.all.issued_count
                    calculations.issued_draft = calculations.issued_draft + data.draft.issued
                    calculations.issued_draft_count = calculations.issued_draft_count + data.draft.issued_count
                    calculations.issued_outstanding = calculations.issued_outstanding + data.outstanding.issued
                    calculations.issued_outstanding_count = calculations.issued_outstanding_count + data.outstanding.issued_count
                    calculations.issued_past_due = calculations.issued_past_due + data.past_due.issued
                    calculations.issued_past_due_count = calculations.issued_past_due_count + data.past_due.issued_count
                    calculations.issued_paid = calculations.issued_paid + data.paid.issued
                    calculations.issued_paid_count = calculations.issued_paid_count + data.paid.issued_count

                    // recieved
                    calculations.recieved_all = calculations.recieved_all + data.all.recieved
                    calculations.recieved_all_count = calculations.recieved_all_count + data.all.recieved_count
                    calculations.recieved_draft = calculations.recieved_draft + data.draft.recieved
                    calculations.recieved_draft_count = calculations.recieved_draft_count + data.draft.recieved_count
                    calculations.recieved_outstanding = calculations.recieved_outstanding + data.outstanding.recieved
                    calculations.recieved_outstanding_count = calculations.recieved_outstanding_count + data.outstanding.recieved_count
                    calculations.recieved_past_due = calculations.recieved_past_due + data.past_due.recieved
                    calculations.recieved_past_due_count = calculations.recieved_past_due_count + data.past_due.recieved_count
                    calculations.recieved_paid = calculations.recieved_paid + data.paid.recieved
                    calculations.recieved_paid_count = calculations.recieved_paid_count + data.paid.recieved_count
                }
            }

            // difference
            calculations.difference_all = calculateDiff(calculations.issued_all, calculations.recieved_all)
            calculations.difference_draft = calculateDiff(calculations.issued_draft, calculations.recieved_draft)
            calculations.difference_outstanding = calculateDiff(calculations.issued_outstanding, calculations.recieved_outstanding)
            calculations.difference_past_due = calculateDiff(calculations.issued_past_due, calculations.recieved_past_due)
            calculations.difference_paid = calculateDiff(calculations.issued_paid, calculations.recieved_paid)

            setTotals(calculations)
            setBarChartData(monthsData)

        }
        setLoading(false)
    }

    useEffect(() => {
        fetchInvoiceStatistics()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let pieData = [
            { id: "draft", name: t('draft'), value: isIssued ? totals.issued_draft : totals.recieved_draft, fill: "#31C6E8" },
            { id: "outstanding", name: t('outstanding'), value: isIssued ? totals.issued_outstanding : totals.recieved_outstanding, fill: "#EEE722" },
            { id: "past_due", name: t('past_due'), value: isIssued ? totals.issued_past_due : totals.recieved_past_due, fill: "#FF4C78" },
            { id: "paid", name: t('paid'), value: isIssued ? totals.issued_paid : totals.recieved_paid, fill: "#9bf754" },
        ]
        let index = pieData.findIndex(item => parseFloat(item.value) > 0)
        setActiveIndex( index !== -1 ? index : 0)

        setPieChartData(pieData)
        // eslint-disable-next-line
    }, [isIssued, totals])

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props
        const sin = Math.sin(-RADIAN * midAngle)
        const cos = Math.cos(-RADIAN * midAngle)
        const sx = cx + (outerRadius + 10) * cos
        const sy = cy + (outerRadius + 10) * sin
        const mx = cx + (outerRadius + 30) * cos
        const my = cy + (outerRadius + 30) * sin
        const ex = mx + (cos >= 0 ? 1 : -1) * 22
        const ey = my
        const textAnchor = cos >= 0 ? 'start' : 'end'
    
        return (
            <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${thousandsSeparators(value || 0)} ${filters.currency}` }</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(${t("rate_value")} ${(percent * 100).toFixed(2)}%)`}
            </text>
            </g>
        );
    }

    const onPieEnter = (_, index) => {
        setActiveIndex(index)
    }

    const calculateMonthTotals = (monthData) => {
        return {
            issued_all: monthData.issued_all,
            issued_all_count: monthData.issued_all_count,
            issued_draft: monthData.issued_draft,
            issued_draft_count: monthData.issued_draft_count,
            issued_outstanding: monthData.issued_outstanding,
            issued_outstanding_count: monthData.issued_outstanding_count,
            issued_past_due: monthData.issued_past_due,
            issued_past_due_count: monthData.issued_past_due_count,
            issued_paid: monthData.issued_paid,
            issued_paid_count: monthData.issued_paid_count,
            
            recieved_all: monthData.recieved_all,
            recieved_all_count: monthData.recieved_all_count,
            recieved_draft: monthData.recieved_draft,
            recieved_draft_count: monthData.recieved_draft_count,
            recieved_outstanding: monthData.recieved_outstanding,
            recieved_outstanding_count: monthData.recieved_outstanding_count,
            recieved_past_due: monthData.recieved_past_due,
            recieved_past_due_count: monthData.recieved_past_due_count,
            recieved_paid: monthData.recieved_paid,
            recieved_paid_count: monthData.recieved_paid_count,
            difference_all: calculateDiff(monthData.issued_all, monthData.recieved_all),
            difference_draft: calculateDiff(monthData.issued_draft, monthData.recieved_draft),
            difference_outstanding: calculateDiff(monthData.issued_outstanding, monthData.recieved_outstanding),
            difference_past_due: calculateDiff(monthData.issued_past_due, monthData.recieved_past_due),
            difference_paid: calculateDiff(monthData.issued_paid, monthData.recieved_paid),
        }
    }

    const onColumnClick = (_, index) => {
        const selected = _?.activeLabel
        if (selected === undefined) return;

        const monthData = barChartData.find(item => item.id === selected) 
        if (monthData){
            setTotals(calculateMonthTotals(monthData))
            setSelectedMonth(selected)
        }
    }

    const restoreOriginalRange = () => {
        let calculations = {
            issued_all: 0,
            issued_all_count: 0,
            issued_draft: 0,
            issued_draft_count: 0,
            issued_outstanding: 0,
            issued_outstanding_count: 0,
            issued_past_due: 0,
            issued_past_due_count: 0,
            issued_paid: 0,
            issued_paid_count: 0,
            
            recieved_all: 0,
            recieved_all_count: 0,
            recieved_draft: 0,
            recieved_draft_count: 0,
            recieved_outstanding: 0,
            recieved_outstanding_count: 0,
            recieved_past_due: 0,
            recieved_past_due_count: 0,
            recieved_paid: 0,
            recieved_paid_count: 0,

            difference_all: 0,
            difference_draft: 0,
            difference_outstanding: 0,
            difference_past_due: 0,
            difference_paid: 0,
        }
        for (let i = 0; i < barChartData.length; i++) {
            let monthTotals = calculateMonthTotals(barChartData[i])

            calculations.issued_all += monthTotals.issued_all
            calculations.issued_all_count += monthTotals.issued_all_count
            calculations.issued_draft += monthTotals.issued_draft
            calculations.issued_draft_count += monthTotals.issued_draft_count
            calculations.issued_outstanding += monthTotals.issued_outstanding
            calculations.issued_outstanding_count += monthTotals.issued_outstanding_count
            calculations.issued_past_due += monthTotals.issued_past_due
            calculations.issued_past_due_count += monthTotals.issued_past_due_count
            calculations.issued_paid += monthTotals.issued_paid
            calculations.issued_paid_count += monthTotals.issued_paid_count
            
            calculations.recieved_all += monthTotals.recieved_all
            calculations.recieved_all_count += monthTotals.recieved_all_count
            calculations.recieved_draft += monthTotals.recieved_draft
            calculations.recieved_draft_count += monthTotals.recieved_draft_count
            calculations.recieved_outstanding += monthTotals.recieved_outstanding
            calculations.recieved_outstanding_count += monthTotals.recieved_outstanding_count
            calculations.recieved_past_due += monthTotals.recieved_past_due
            calculations.recieved_past_due_count += monthTotals.recieved_past_due_count
            calculations.recieved_paid += monthTotals.recieved_paid
            calculations.recieved_paid_count += monthTotals.recieved_paid_count
        }

        calculations.difference_all = calculateDiff(calculations.issued_all, calculations.recieved_all)
        calculations.difference_draft = calculateDiff(calculations.issued_draft, calculations.recieved_draft)
        calculations.difference_outstanding = calculateDiff(calculations.issued_outstanding, calculations.recieved_outstanding)
        calculations.difference_past_due = calculateDiff(calculations.issued_past_due, calculations.recieved_past_due)
        calculations.difference_paid = calculateDiff(calculations.issued_paid, calculations.recieved_paid)

        setTotals(calculations)
        setSelectedMonth(null)
    }

    const generateMonthDisplay = () => {
        let month = barChartData.find(item => item.id === selectedMonth)
        return `${month?.name} ${month?.year}`
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ padding: "1rem", background: "var(--white)", border: "0.5px solid var(--dark)", textAlign: "left" }}>
                    <p style={{ fontWeight: "bold", paddingBottom: 0 }}>{payload[0].payload.name} {payload[0].payload.year}</p>
                    { payload.map((item, idx) => (
                        <span key={idx}>
                            <p style={{ display: "flex", flexDirection: "row", alignItems: "left", justifyContent: "left center", textAlign: "left" }}>
                                <div style={{ width: "10px", height: "10px", background: item?.stroke || item?.fill, marginRight: "0.25rem" }}></div>
                                <div style={{ position: "relative", top: "-0.35rem" }}>
                                    <span>{item.name}</span>: {" "}
                                    <span>
                                        <strong>{item.value} { filters.currency }</strong> ({calcPercent(item.value, isIssued ? item.payload.issued_all : item.payload.recieved_all)}%)
                                    </span>
                                </div>
                            </p>
                        </span>
                    ))}
                </div>
            );
        }
      
        return null
    }

    const CustomLegend = ({ payload }) => {
        return (
            <div style={{ textAlign: "center", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                { payload.map((item, index) => (
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "left center", marginRight: "0.5rem" }}>
                        <div style={{ width: "10px", height: "10px", background: item.color, marginRight: "0.25rem" }}></div>
                        <div style={{ position: "relative", top: "-0.35rem" }}>
                            { item.value }
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    const renderCellContent = (sum, count, percent) => {
        return (
            <>
                { thousandsSeparators(parseFloat(sum).toFixed(2)) } { filters.currency } { count !== undefined ? " / " + count : "" } { percent !== undefined && <span style={{ opacity: 0.8, paddingLeft: "0.3rem" }}>({ percent }%)</span> }
            </>
        )
    }

    return (
        <Container fluid>
            <Ref innerRef={target}>
                <Sidebar.Pushable
                    as={Segment}
                    style={{ border: 'none', background: 'transparent', boxShadow: 'none', padding: 0, margin: 0 }}
                    className='fixed-sidebar'
                >
                    <Sidebar
                        direction="right"
                        style={{ width: '40rem', textAlign: 'left' }}
                        as={Menu}
                        animation="overlay"
                        icon="labeled"
                        vertical
                        visible={visible}
                        onHide={() => setVisible(false)}
                        target={target}
                    >
                        <OverviewFilterForm
                            filters={filters}
                            setFilters={setFilters}
                            setVisible={setVisible}
                            initialFilters={initialFilters}
                            fetchData={fetchInvoiceStatistics}

                        />
                    </Sidebar>
                        <SpinnerSegment loading={loading}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", margin: "1rem", marginTop: 0 }}>
                                <div>
                                    <Button.Group basic size="tiny">
                                        <Button active={isIssued} onClick={() => setIsIssued(true)}>
                                            <Icon name="stats-chart-outline" style={{ marginRight: "0.5rem" }}/> { t('send_invoices') }
                                        </Button>
                                        <Button active={!isIssued} onClick={() => setIsIssued(false)}>
                                            <Icon name="stats-chart-outline" style={{ marginRight: "0.5rem" }}/>  { t('recieved_invoices') }
                                        </Button>
                                    </Button.Group>
                                    <span><Icon name="information-circle" style={{ fontSize: "1.2rem", marginLeft: "1rem", marginRight: "0.3rem", position: "relative", top: "0.2rem" }}/> { filters.count_with_tax ? t('viewed_sum_count_with_tax') : t('viewed_sum_count_without_tax') }</span>
                                </div>
                                
                                <div>
                                    <span style={{ position: "relative", top: "-0.3rem", marginRight: "1rem" }}>
                                        { t('selected_range') }: <strong>{ selectedMonth !== null ? generateMonthDisplay() : createDateRange() }</strong>
                                        {
                                            selectedMonth !== null &&
                                            <Icon 
                                                onClick={() => restoreOriginalRange()}
                                                name="close-outline"
                                                style={{ 
                                                    marginLeft: "0.5rem",
                                                    cursor: "pointer",
                                                    fontWeight: "bold",
                                                    color: "var(--danger)",
                                                    fontSize: "1.2rem",
                                                    position: "relative",
                                                    top: "0.2rem"
                                                }}
                                            />
                                        }
                                    </span>
                                    <Action
                                        as="custom"
                                        type="icon"
                                        tooltip={t('filter')}
                                        iconColor="black"
                                        size="huge"
                                        icon="funnel-outline"
                                        paddingLeft="1rem"
                                        onClick={() => setVisible(!visible)}
                                    />
                                </div>
                            </div>
                            <Grid stackable>
                                <Grid.Row columns="2" verticalAlign='middle' style={{ padding: 0 }}>
                                    <Grid.Column>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <ComposedChart
                                                key={isIssued ? 1 : 2 }
                                                data={barChartData}
                                                onClick={onColumnClick}
                                                margin={{
                                                    top: 10,
                                                    right: 0,
                                                    left: 0,
                                                    bottom: 10
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="1 1" />
                                                <XAxis dataKey="id"/>
                                                <YAxis />
                                                <Tooltip content={<CustomTooltip/>}/>
                                                <Legend content={<CustomLegend/>} height={36}/>
                                                <Bar dataKey={isIssued ? "issued_draft" : "recieved_draft"} name={t('draft')} stackId="issued" fill={"#31C6E8"} />
                                                <Bar dataKey={isIssued ? "issued_paid" : "recieved_paid"} name={t('paid')} stackId="issued" fill={"#9bf754"} />
                                                <Bar dataKey={isIssued ? "issued_outstanding" : "recieved_outstanding"} name={t('outstanding')} stackId="issued" fill={"#EEE722"} />
                                                <Bar dataKey={isIssued ? "issued_past_due" : "recieved_past_due"} name={t('past_due')} stackId="issued" fill={"#FF4C78"} />
                                                <Line type="monotone" dataKey={isIssued ? "issued_all" : "recieved_all" } name={t('total')} stroke="var(--primary)" />
                                            </ComposedChart>
                                        </ResponsiveContainer>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <ResponsiveContainer width="100%" height={350}>
                                            <PieChart>
                                                <Pie
                                                    activeIndex={activeIndex}
                                                    activeShape={renderActiveShape}
                                                    data={pieChartData}
                                                    cx="50%"
                                                    cy="50%"
                                                    innerRadius={60}
                                                    outerRadius={100}
                                                    fill="#8884d8"
                                                    dataKey="value"
                                                    onMouseEnter={onPieEnter}
                                                />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{ selectedMonth !== null ? generateMonthDisplay() : createDateRange() }</Table.HeaderCell>
                                        <Table.HeaderCell>
                                            { t('paid_sum') } / { t('invoice_count') }
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            { t('outstanding_sum') } / { t('invoice_count') }
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            { t('past_due_sum') } / { t('invoice_count') }
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            { t('draft_sum') } / { t('invoice_count') }
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            { t('total_sum') } / { t('invoice_count') }
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell style={{ fontWeight: "bold" }}>{ t('send_invoices') }</Table.Cell>
                                        <Table.Cell>
                                            { renderCellContent(totals.issued_paid, totals.issued_paid_count, calcPercent(totals.issued_paid, totals.issued_all)) }
                                        </Table.Cell>
                                        <Table.Cell>
                                            { renderCellContent(totals.issued_outstanding, totals.issued_outstanding_count, calcPercent(totals.issued_outstanding, totals.issued_all)) }
                                        </Table.Cell>
                                        <Table.Cell>
                                            { renderCellContent(totals.issued_past_due, totals.issued_past_due_count, calcPercent(totals.issued_past_due, totals.issued_all)) }
                                        </Table.Cell>
                                        <Table.Cell>
                                            { renderCellContent(totals.issued_draft, totals.issued_draft_count, calcPercent(totals.issued_draft, totals.issued_all)) }
                                        </Table.Cell>
                                        <Table.Cell style={{ fontWeight: "bold" }}>
                                            { renderCellContent(totals.issued_all, totals.issued_all_count, undefined) }
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell style={{ fontWeight: "bold" }}>{ t('recieved_invoices') }</Table.Cell>
                                        <Table.Cell>
                                            { renderCellContent(totals.recieved_paid, totals.recieved_paid_count, calcPercent(totals.recieved_paid, totals.recieved_all)) }
                                        </Table.Cell>
                                        <Table.Cell>
                                            { renderCellContent(totals.recieved_outstanding, totals.recieved_outstanding_count, calcPercent(totals.recieved_outstanding, totals.recieved_all)) }
                                        </Table.Cell>
                                        <Table.Cell>
                                            { renderCellContent(totals.recieved_past_due, totals.recieved_past_due_count, calcPercent(totals.recieved_past_due, totals.recieved_all)) }
                                        </Table.Cell>
                                        <Table.Cell>
                                            { renderCellContent(totals.recieved_draft, totals.recieved_draft_count, calcPercent(totals.recieved_draft, totals.recieved_all)) }
                                        </Table.Cell>
                                        <Table.Cell style={{ fontWeight: "bold" }}>
                                            { renderCellContent(totals.recieved_all, totals.recieved_all_count, undefined) }
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row style={{ fontWeight: "bold" }}>
                                        <Table.Cell></Table.Cell>
                                        <Table.Cell style={{ color: parseFloat(totals.difference_paid) >= 0 ? "var(--success)" : "var(--danger)" }}>
                                            { thousandsSeparators(parseFloat(totals?.difference_paid) || 0) } { filters.currency } 
                                            <Icon name={`trending-${parseFloat(totals.difference_paid) >= 0 ? "up" : "down"}`} style={{  marginLeft: "0.5rem", position: "relative", top: "0.2rem" }}/>
                                        </Table.Cell>
                                        <Table.Cell style={{ color: parseFloat(totals.difference_outstanding) >= 0 ? "var(--success)" : "var(--danger)" }}>
                                            { thousandsSeparators(parseFloat(totals?.difference_outstanding) || 0) } { filters.currency }
                                            <Icon name={`trending-${parseFloat(totals.difference_outstanding) >= 0 ? "up" : "down"}`} style={{  marginLeft: "0.5rem", position: "relative", top: "0.2rem" }}/>
                                        </Table.Cell>
                                        <Table.Cell style={{ color: parseFloat(totals.difference_past_due) >= 0 ? "var(--success)" : "var(--danger)" }}>
                                            { thousandsSeparators(parseFloat(totals?.difference_past_due) || 0 ) } { filters.currency }
                                            <Icon name={`trending-${parseFloat(totals.difference_past_due) >= 0 ? "up" : "down"}`} style={{  marginLeft: "0.5rem", position: "relative", top: "0.2rem" }}/>
                                        </Table.Cell>
                                        <Table.Cell style={{ color: parseFloat(totals.difference_draft) >= 0 ? "var(--success)" : "var(--danger)" }}>
                                            { thousandsSeparators(parseFloat(totals?.difference_draft) || 0) } { filters.currency }
                                            <Icon name={`trending-${parseFloat(totals.difference_draft) >= 0 ? "up" : "down"}`} style={{  marginLeft: "0.5rem", position: "relative", top: "0.2rem" }}/>
                                        </Table.Cell>
                                        <Table.Cell style={{ color: parseFloat(totals.difference_all) >= 0 ? "var(--success)" : "var(--danger)" }}>
                                            { thousandsSeparators(parseFloat(totals?.difference_all) || 0) } { filters.currency }
                                            <Icon name={`trending-${parseFloat(totals.difference_all) >= 0 ? "up" : "down"}`} style={{  marginLeft: "0.5rem", position: "relative", top: "0.2rem" }}/>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </SpinnerSegment>
                </Sidebar.Pushable>
            </Ref>
        </Container>
    );
};

export default Overview;