import React, { useState } from 'react';
// import { useTranslation } from "react-i18next";
// components
import Icon from '@components/Icon';


const PdfUpload = ({ handleUpload, ...rest }) => {
   // const { t } = useTranslation();

    // states
    const [processing, setProcessing] = useState(false)

    const handleChange = async function loadFile(event) {
        setProcessing(true)
        if (event.target.files.length > 0) {
            await handleUpload(event.target.files[0]);
        }
        setProcessing(false)
    };

    const hiddenFileInput = React.useRef(null);
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    return (
        <>
            <Icon name="cloud-upload-outline"
                style={{ cursor: "pointer" }}
                className={processing && "disabled"}
                onClick={handleClick}
            /> 
            <input
                type="file"
                id="cv"
                name="cv"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
            />
        </>
    );
};

export default PdfUpload;