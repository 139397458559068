import React from 'react'
import '../../store/languages'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
// store
import { getValue } from '@helpers/functions'
import { routes } from '@routes'
// components
import Icon from '@components/Icon'
import { Form, Popup } from 'semantic-ui-react'
import { DateInput, MonthInput, YearInput, DateTimeInput, TimeInput } from 'semantic-ui-calendar-react'
// eslint-disable-next-line
import ChoiceField from '@components/forms/common/ChoiceField'
import ColorPickerField from '@components/forms/settings/ColorPickerField'
import { InputNumber } from 'antd'
import SliderField from './SliderField'
import ChoiceField2 from './common/ChoiceField2'

const formatDate = (date, format) => {
    format = format || 'YYYY-MM-DD'
    const momentDate = moment(date)
    if (momentDate.isValid()) {
        return momentDate.format(format)
    }

    return date
}

/*
 ** Allowed props
 A) Any props that is bound to Semantic UI Components, ChoiceField Custom component or DateInput Component
 B) props `as` that is used to define render type, currently supported:
    - "input" (Form.Input)
    - "textarea" (Form.TextArea)
    - "checkbox" (Form.Checkbox)
    - "radio" (Form.Radio)
    - "select" (Form.Select)
    - "dropdown" (Form.Dropdown)
    - "choice" (ChoiceField)
    - "datepicker" (DateInput)
    - "yearpicker" (YearInput)
    - "monthpicker" (MonthInput)
    - "timepicker" (TimeInput)
    - "datetimepicker" (DateTimeInput)

 C) `fieldError` is object that will be used for error handling based on API response:
    {
       key: "someKeyForField", // field name. For example: "firstname"
       errors: [] // list of errors from backend API

    } 

 D) `label` string name for label 
 E) `help` - string that will represent help text (circle info icon with popup)
*/
const SuperField = ({ as, fieldError, label, help, clearable, settings, exclude, ...rest }) => {
    const className = 'superfield'
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)
    const language = useSelector((state) => state.language)

    const handleInputChange = (e, data) => {
        if (data.value === '') {
            data.value = ''
        } else {
            if (moment(data.value, dateFormat, true).isValid()) {
                data.value = moment(data.value, dateFormat).format('YYYY-MM-DD')
            }
        }

        rest.onChange(e, data)
    }

    const getErrors = (fieldError) => {
        if (fieldError) {
            const key = fieldError?.key
            const values = getValue(fieldError?.errors, fieldError?.key)

            const errors = values ? values[key] : null

            if (Array.isArray(errors) && errors?.length > 1) {
                // its an arraylist
                return errors[0] // grab first one
            } else {
                return errors
            }
        }

        return rest?.error || null
    }

    const RequiredMark = () => {
        return <>{rest.required && <sup style={{ color: 'var(--danger)' }}>*</sup>}</>
    }

    const Label = () => {
        return (
            <>
                {help ? (
                    <label style={{ marginBottom: '0' }}>
                        <span style={{ fontWeight: 'bold', fontSize: '.92857143em' }}> {label} </span>
                        <span style={{ marginLeft: '0.5rem' }}>
                            <Popup
                                basic
                                mouseEnterDelay={300}
                                mouseLeaveDelay={300}
                                content={help}
                                trigger={<Icon style={{ cursor: 'pointer' }} name="information-circle-outline" />}
                            />
                        </span>
                    </label>
                ) : (
                    <span style={{ fontWeight: 'bold', fontSize: '.92857143em' }}>
                        {' '}
                        {label} <RequiredMark />
                    </span>
                )}
            </>
        )
    }

    const LabelAsLink = () => {
        return (
            <span>
                {settings ? (
                    <div style={{ display: 'inline-block', width: '100%', marginBottom: settings ? 0 : '0.3rem' }}>
                        {help ? (
                            <Label />
                        ) : (
                            <span style={{ fontWeight: 'bold', fontSize: '.92857143em' }}>
                                {' '}
                                {label} <RequiredMark />
                            </span>
                        )}
                        <Link to={routes.SETTINGS + settings} style={{ float: 'right' }}>
                            <span style={{ fontSize: '.92857143em' }}>{t('manage_in_settings')}</span>
                        </Link>
                    </div>
                ) : (
                    <span style={{ fontWeight: 'bold', fontSize: '.92857143em' }}>
                        {' '}
                        {label} <RequiredMark />
                    </span>
                )}
            </span>
        )
    }

    const determinateLocalization = (language) => {
        if (language === 'sk') return 'sk'
        if (language === 'ua') return 'uk'
        if (language === 'ru') return 'ru'
        if (language === 'cz') return 'cs'

        return 'en'
    }

    const render = () => {
        switch (as) {
            case 'input':
                return (
                    <Form.Input
                        label={help !== undefined ? Label : label}
                        error={getErrors(fieldError)}
                        className={className}
                        {...rest}
                    />
                )
            case 'textarea':
                return (
                    <Form.TextArea
                        label={help !== undefined ? Label : label}
                        error={getErrors(fieldError)}
                        className={className}
                        {...rest}
                    />
                )
            case 'checkbox':
                return (
                    <Form.Checkbox
                        label={help !== undefined ? Label : label}
                        error={getErrors(fieldError)}
                        className={className}
                        {...rest}
                    />
                )
            case 'radio':
                return (
                    <Form.Radio
                        label={help !== undefined ? Label : label}
                        error={getErrors(fieldError)}
                        className={className}
                        {...rest}
                    />
                )
            case 'select':
                return (
                    <Form.Select
                        label={help !== undefined ? Label : label}
                        error={getErrors(fieldError)}
                        className={className}
                        {...rest}
                    />
                )
            case 'dropdown':
                return (
                    <Form.Dropdown
                        label={help !== undefined ? Label : label}
                        error={getErrors(fieldError)}
                        className={className}
                        {...rest}
                    />
                )
            case 'choice':
                return (
                    <ChoiceField
                        label={settings ? <LabelAsLink /> : <Label />}
                        error={getErrors(fieldError)}
                        className={className}
                        clearable={clearable === undefined ? true : clearable}
                        exclude={exclude || []}
                        {...rest}
                    />
                )
            case 'choice-select':
                    return (
                        <ChoiceField2
                            label={settings ? <LabelAsLink /> : <Label />}
                            error={getErrors(fieldError)}
                            className={className}
                            clearable={clearable === undefined ? true : clearable}
                            exclude={exclude || []}
                            {...rest}
                        />
                    )
            case 'datepicker':
                return (
                    <Form.Field>
                        <DateInput
                            {...rest}
                            label={help !== undefined ? Label : label}
                            error={
                                !moment(formatDate(rest.value, dateFormat), dateFormat, true).isValid() &&
                                rest.value !== '' &&
                                rest.value !== null &&
                                rest.value !== undefined
                                    ? t('invalid_date_format')
                                    : getErrors(fieldError)
                            }
                            value={
                                moment(rest.value, 'YYYY-MM-DD', true).isValid()
                                    ? formatDate(rest.value, dateFormat)
                                    : rest.value
                            }
                            dateFormat={dateFormat}
                            onChange={handleInputChange}
                            className={className}
                            iconPosition="left"
                            popupPosition="bottom left"
                            hideMobileKeyboard
                            localization={determinateLocalization(language)}
                        />
                        <div style={{ opacity: '0.7' }}>
                            <small>
                                <span>
                                    {t('expected_format')}: <strong>{dateFormat}</strong>
                                </span>
                            </small>
                        </div>
                    </Form.Field>
                )
            case 'yearpicker':
                return (
                    <YearInput
                        {...rest}
                        label={help !== undefined ? Label : label}
                        error={getErrors(fieldError)}
                        className={className}
                        iconPosition="left"
                        popupPosition="bottom left"
                        hideMobileKeyboard
                        localization={determinateLocalization(language)}
                    />
                )
            case 'timepicker':
                return (
                    <TimeInput
                        {...rest}
                        label={help !== undefined ? Label : label}
                        error={getErrors(fieldError)}
                        className={className}
                        iconPosition="left"
                        popupPosition="bottom left"
                        hideMobileKeyboard
                        localization={determinateLocalization(language)}
                    />
                )
            case 'monthpicker':
                return (
                    <MonthInput
                        {...rest}
                        label={help !== undefined ? Label : label}
                        error={getErrors(fieldError)}
                        className={className}
                        iconPosition="left"
                        popupPosition="bottom left"
                        hideMobileKeyboard
                        localization={determinateLocalization(language)}
                    />
                )
            case 'datetimepicker':
                return (
                    <DateTimeInput
                        label={help !== undefined ? Label : label}
                        name="dateTime"
                        error={getErrors(fieldError)}
                        className={className}
                        dateFormat="YYYY-MM-DD"
                        iconPosition="left"
                        popupPosition="bottom left"
                        hideMobileKeyboard
                        localization={determinateLocalization(language)}
                        {...rest}
                    />
                )
            case 'numberpicker':
                return (
                    <InputNumber
                        label={help !== undefined ? Label : label}
                        name="dateTime"
                        error={getErrors(fieldError)}
                        className={className}
                        {...rest}
                    />
                )
            case 'colorpicker':
                return (
                    <ColorPickerField
                        label={help !== undefined ? Label : label}
                        error={getErrors(fieldError)}
                        className={className}
                        {...rest}
                    />
                )
            case 'slider':
                return (
                    <SliderField
                        label={help !== undefined ? Label : label}
                        error={getErrors(fieldError)}
                        className={className}
                        {...rest}
                    />
                )

            // TODO: add timepicker
            default:
                return <></>
        }
    }

    return <>{render()}</>
}

export default SuperField
