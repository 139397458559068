import React, { /*useEffect,*/ useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
// store
import { authService } from '@store/services/ServiceAuth'
// import { API } from '@store/config';
// import { requests } from '@helpers/requests';
import { isEmpty } from '@store/services/helpers/validation'
import { routes } from '@store/routes/index'

import { Button, Form, Grid, Header, Icon, Modal, Segment, Container, Image } from 'semantic-ui-react'

// component
function LoginForm(props) {
    const { t, i18n } = useTranslation()
    let history = useHistory()
    let dispatch = useDispatch()
    const logoFromRedux = useSelector((state) => state.logo)

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const next = queryParams.get('next')

    // eslint-disable-next-line
    const [logo, setLogo] = useState(logoFromRedux)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errorOpen, setErrorOpen] = useState(false)

    // form state
    const [isProcessing, setIsProcessing] = useState(false)
    const [errorMessage] = useState(t('login_error'))

    // useEffect(() => {
    //     async function fetchLogo() {
    //         if (!logoFromRedux) {
    //             const request = await requests.get(API.PUBLIC_LOGO, false);
    //             if (request.status === 200) {
    //                 setLogo(request.response.logo)
    //             }
    //         }
    //     }

    //     fetchLogo()
    //     // eslint-disable-next-line
    // }, [])

    const handleLogin = async (event) => {
        event.preventDefault()
        setIsProcessing(true)

        // validate form fields
        let formError = false

        if (!formError) {
            const result = await authService.login(username, password, dispatch, i18n)

            if (result.status !== 200) {
                setErrorOpen(true)
            } else {
                if (result?.role !== 5) {
                    if (next) {
                        let nextURI = routes.DASHBOARD + next.replace('/dashboard/', '')
                        history.push(nextURI)
                    } else {
                        history.push(routes.DASHBOARD)
                    }
                } else {
                    if (result?.can_access_warehouse === true) {
                        history.push(routes.WAREHOUSE_TRACKER)
                    } else {
                        history.push(routes.ATTENDANCE_TRACKER)
                    }
                }
                return
            }
        }
        setIsProcessing(false)
    }

    // template
    return (
        <Container style={{ zIndex: '2' }}>
            <Grid centered>
                <Grid.Column style={{ maxWidth: 450, padding: 0 }}>
                    <Form onSubmit={(e) => handleLogin(e)}>
                        <Segment style={{ borderRadius: 'var(--border-radius)' }}>
                            <Link to={routes.HOME}>
                                <Image
                                    centered
                                    size="small"
                                    src={logo}
                                    onError={(e) => {
                                        e.currentTarget.src = '/images/logo.svg'
                                    }}
                                />
                            </Link>
                            <h1 style={{ textAlign: 'center' }}>{t('login')}</h1>
                            <Form.Group>
                                <Form.Input
                                    icon="user"
                                    iconPosition="left"
                                    width={16}
                                    size="large"
                                    required
                                    autoFocus
                                    fluid
                                    label={t('username')}
                                    placeholder={t('username')}
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    error={!isEmpty(username) ? false : false}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Input
                                    icon="lock"
                                    iconPosition="left"
                                    width={16}
                                    size="large"
                                    required
                                    fluid
                                    type="password"
                                    label={t('password')}
                                    placeholder={t('password')}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Form.Group>

                            <Header as="h5">
                                <Link to={routes.PASSWORD_RESET}>{t('forgot_password_msg')}</Link>
                            </Header>

                            <div style={{ width: '100%' }}>
                                <Form.Button
                                    fluid
                                    primary
                                    size="huge"
                                    type="submit"
                                    loading={isProcessing}
                                    disabled={isProcessing || isEmpty(username) || isEmpty(password)}
                                    style={{ margin: '0.5rem auto' }}
                                >
                                    {t('login_btn')}
                                </Form.Button>
                            </div>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>

            <Modal open={errorOpen} onClose={() => setErrorOpen(false)} size="mini">
                <Header icon="warning circle" content="Error occured" />
                <Modal.Content>
                    <h3>{errorMessage}</h3>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="red" onClick={() => setErrorOpen(false)} inverted>
                        <Icon name="checkmark" /> {t('ok')}
                    </Button>
                </Modal.Actions>
            </Modal>
        </Container>
    )
}

export default LoginForm
