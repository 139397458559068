import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Button, Divider } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperField from '@components/forms/SuperField';

const EmployeeBoarding = ({ setResult, result, onClose, setListView }) => {
    const { t } = useTranslation();

    const [view, setView] = useState(1);
    const [template, setTemplate] = useState("")
    const [employee, setEmployee] = useState("")
    const [deadline, setDeadline] = useState("")
    const [optionsLoading, setOptionsLoading] = useState(false)
    const [options, setOptions] = useState([])
    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState(null);

    const handleSubmit = async () => {
        setIsProcessing(true);

        const request = await requests.post(API.ONBOARDING_CHECKLIST_CONFIG + template + '/instantiate/', { employee: employee, deadline: deadline })
        if (request.status === 201) {
            setResult(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    count: prev.data.count + 1,
                    results: [request.response, ...prev.data.results]
                }
            }))
            onClose()
        } else {
            setErrors(request.response)
        }
        setIsProcessing(false);
    }

    useEffect(() => {
        async function fetchData() {
            setOptionsLoading(true)
            const request = await requests.get(API.ONBOARDING_CHECKLIST_CONFIG + '?type=' + view)
            if( request.status === 200 ){
                setOptions(request.response.map(item => ({
                    key: item.id, text: item.title, value: item.id
                })))
            }

            setOptionsLoading(false)
        }

        fetchData()
    }, [view])

    return (
        <Form onSubmit={handleSubmit}>
            <Button.Group fluid basic size='small' style={{ borderRadius: "0" }}>
                <Button type='button' active={view === 1} onClick={() => { setView(1); setListView(1)}}>{'On-boarding'}</Button>
                <Button type='button' active={view === 2} onClick={() => { setView(2); setListView(2)}}>{'Off-boarding'}</Button>
            </Button.Group>
            <Divider/>

            <SuperField as="choice"
                search
                required
                value={template}
                label={t('choose_template')}
                onChange={(e, { value }) => setTemplate(value)}
                customOptions={options}
                loading={optionsLoading}
                disabled={optionsLoading}
            />

            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    required
                    value={employee}
                    error={errors === null ? false : errors?.non_field_errors?.[0]}
                    endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                    text="fullname"
                    label={t('employee')}
                    onChange={(e, { value }) => setEmployee(value)}
                />

                <SuperField as="datepicker"
                    required
                    label={t('deadline')}
                    value={deadline}
                    onChange={(e, { value }) => setDeadline(value)}
                />
            </Form.Group>

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit
                    text={t('start')}
                    loading={isProcessing}
                    disabled={isProcessing || template === "" || employee === "" || deadline === ""}
                />
            </Form.Field>
        </Form>
    );
};

export default EmployeeBoarding;