import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Header, Button, Divider, List } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'

const DocumentFilterForm = ({ setVisible, fetchTemplates }) => {
    const { t } = useTranslation();

    const initState = {
        // category: "",
        source: ""
    }

    const [sources, setSources] = useState([])
    const modelTerms = {
        "ContractInstance": t('contract'),
        "ContractTermination": t("termination"),
        "JobOffer": t("job_offer")
    }

    useEffect(() => {
        async function fetchModels() {
            const request = await requests.get(API.TEMPLATING_MODELS)

            if (request.status === 200) {
                const tmpSources = []
                for (let i = 0; i < request.response.length; i++) {
                    tmpSources.push({
                        id: i + 1,
                        value: request.response[i].app + "." + request.response[i].model,
                        text: modelTerms[request.response[i].model]
                    })
                }
                setSources(tmpSources)
            }
        }

        fetchModels()
        // eslint-disable-next-line
    }, [])

    const [filters, setFilters] = useState(initState)

    const onFilter = async (filters) => {
        setVisible(false)
        let filterBaseParams = ""

        // if (filters.category !== "") filterBaseParams += "&category=" + filters.category
        if (filters.source !== "") filterBaseParams += "&source=" + filters.source

        await fetchTemplates(filterBaseParams)
    }

    const resetFilters = async () => {
        setFilters(initState)
        setVisible(false)
        await onFilter(initState)
    }

    return (
        <Form onSubmit={() => onFilter(filters)} style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "left" }}>
            <List divided verticalAlign='middle'>
                <List.Item>
                    <List.Content>
                        <Header as="h2" content={t('filters')} style={{ marginTop: "1rem", paddingLeft: "1rem", textAlign: "left" }} />
                    </List.Content>
                </List.Item>
            </List>

            <Divider />

            {/* <SuperField as="choice"
                search
                label={t('category')}
                value={filters.category}
                endpoint={API.TEMPLATING_CATEGORY}
                onChange={(e, { value }) => setFilters({
                    ...filters,
                    category: value
                })}
                text="title"
            /> */}

            <SuperField as="choice"
                search
                label={t('data_source')}
                value={filters.source}
                customOptions={sources}
                onChange={(e, { value }) =>
                    setFilters({
                        ...filters,
                        source: value
                    })
                }
            />

            <Divider />
            <Form.Field>
                <Button.Group>
                    <Button size="small" primary> {t('apply')} </Button>
                    <Button type="button" onClick={resetFilters} size="small"> {t('reset')} </Button>
                </Button.Group>
            </Form.Field>
        </Form>
    );
};

export default DocumentFilterForm;